.card {
  padding: 1.25rem 1.25rem 2.5rem;
  background: $beige;
  color: $gray-dark;
  font-family: $sans-serif;

  p {
    margin: 0;
  }
  .module--beige &,
  .module--gray-light &,
  .module--gray-dark & {
    background: $white;
  }
  a,
  p strong {
    font-size: 1.15rem;
  }
}

.card--alt {
  background: $white;
  color: $gray-dark;
  font-family: $sans-serif;
  padding: 1.25rem 0;
}

.card__header {
  margin-bottom: 1.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 0.25rem solid $gray-dark;
}

.card__meta {
  font-weight: 600;
  font-size: .83rem;
  text-transform: uppercase;
}
