.hero-container {
  @include zero;
  position: relative;
  padding-top: .1rem;

  .flickity-enabled:focus {
    [data-whatinput='keyboard'] & {
      @include focus;
      outline-offset: 0;
      z-index: 3;
    }
  }
}

.hero-slider {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
  }

  .flickity-page-dots {
    top: 0;
    right: 0;
    width: 100%;
    margin-top: calc(46.0417% - 2rem);

    @include mq(64.0625em) {
      top: 50%;
      right: 4rem;
      left: auto;
      width: auto;
      margin: 0;
      transform: translateY(-50%);
    }

    @include mq(90em) {
      right: 5rem;
    }

    .dot {
      color: $white;
      filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="0" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
      filter: drop-shadow(0 .1rem .1rem $black);
      filter: progid:DXImageTransform.Microsoft.Shadow(color=#000, strength=0);

      @media screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
        background: rgba($black, .54);
      }

      @include mq(64.0625em) {
        display: block;
        margin: 0.5rem 0;
      }
    }
  }
}

.hero-slider-alt {
  position: relative;
  .hero__image {
    @include mq($lg) {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .hero__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: static;

    text-shadow: none;
    background-color: $blue;
    @include mq($lg) {
      background-color: transparent;
      min-height: 577px;
    }
  }

  .hero__gradient {
    background: linear-gradient(89deg, #000 6.18%, rgba(0, 0, 0, 0.00) 61.39%);
    height: 100%;
  }
  .flickity-prev-next-button {
    border-radius: 50%;
    width: 2.563rem;
    height: 2.563rem;
    padding:0;
    top: 20%;
    background-color: $purple;
    border: none;
    @include mq($lg) {
      border: 1px solid $white;
      background-color: transparent;
      top: 50%;
    }
    &.next {
      right: 1rem;
    }
    &.previous {
      left: 1rem;
    }
    &:hover,
    &:focus {
      background-color: $purple;
      border: none;
    }
  }

  .hero__button {
    background-color: $purple;
    text-shadow: none;
    border: none;
    color: $white;
    &:hover,
    &:focus {
      background-color: $white;
      color: $black;
    }
  }
  .hero__heading {
    font-family: $serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    color: $white;
    @include mq($lg) {
      font-size: 44px;
      line-height: 48px;
      text-shadow: .1rem .1rem .1rem $black;
    }
  }
  .hero__text {
    color: $white;
    font-family: $sans-serif;
    font-size: 19.7px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}

.hero {
  @include zero;
  position: relative;
  width: 100%;
  list-style: none;
  display: block;
}

.hero__image {
  position: relative;
  color: $white;

  img {
    width: 100%;
  }

  .hero--tall & {
    height: inherit;
    img {
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover';
    }
  }
}

.hero__gradient {
  @include mq($lg) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(transparent, $black-fade);
  }
}

.hero__gradient-alt {
  height: 30%;
}

.hero__gradient-alt-2 {
  height: 20%;
}

.hero__wrap {
  background-color: $beige;
  position: relative;

  @include mq(48em) {
    background-color: transparent;

  }
  @include mq($lg) {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .hero--form & {
    background-color: $beige;

    @include mq($lg) {
      background-color: transparent;
    }
  }
}

.hero__content {
  @include zero;
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: $beige;
  display: block;

  @include mq(48em) {
    margin: 0 -2rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @include mq(64.0625em) {
    position: absolute;
    bottom: 2rem;
    width: calc(66.6667% - 2rem);
    margin: 0;
    padding: 0;
    transition: opacity $transition-easing $transition-speed * 2;
    background: transparent;
    color: $white;
    opacity: 0;
    text-shadow: .1rem .1rem .1rem $black;
    left: 3rem;

    .is-selected & {
      transition-delay: $transition-speed;
      opacity: 1;
    }
  }

  @include mq(90em) {
    left: 2rem;
    bottom: 3rem;
    width: calc(50% - 3rem);
  }
}

a.hero__content {
  display: block;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.hero__content--alt {
  opacity: 1;
}

.hero__title {
  @extend .h1;
  position: absolute;
  bottom: 1rem;
  margin: 0;
  color: $white;
  text-shadow: .1rem .1rem .1rem $black;

  @include mq(48em) {
    bottom: 2rem;
  }

  @include mq(90em) {
    bottom: 3rem;
  }

  a {
    color: inherit;
  }
}

.hero__title--sized {
  @extend .hero__title;

  @include mq($max: 1024px) {
    position: static;
    color: $black-light;
    text-shadow: none;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

.hero__title--form {
  @extend .hero__title;

  @include mq($max: 1024px) {
    position: static;
    color: $black-light;
    text-shadow: none;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  @include mq($lg) {
    width: calc(100% - 648px);
  }
}

.hero__heading {
  display: block;

  @include spacing;
  font-family: $sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.25;

  @include mq(64.0625em) {
    font-size: 2.75rem;
    color: $white;
  }
}

.hero__text {
  display: block;
  @include spacing;
  font-size: 1.25rem;

  @include mq(64.0625em) {
    color: $white;
  }
}

.hero__button {
  @include mq(64.0625em) {
    color: $white;
  }
}

.hero--tall {
  @include mq($lg) {
    height: 650px;
  }
}
.hero--video {
  @include mq($lg) {
    height: 650px;
  }

  video {
    display: none;

    @include mq($lg) {
      display: block;
    }
  }

  img {
    display: block;

    @include mq($lg) {
      display: none;
    }
  }
}

.hero__video {
  display: none;

  width: 100%;
  height: 100%;
  background-color: $black;
  overflow: hidden;
  z-index: -1;

  @include mq($lg) {
    display: block;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover';

    &::-webkit-media-controls {
      display: none !important;
    }

    &::-webkit-media-controls-start-playback-button {
      display: none !important;
      appearance: none;
    }
  }
}

.hero__form {
  border-top: 17px solid $teal;
  background-color: $white;
  padding: 2rem;
  overflow: auto;

  @include mq($lg) {
    width: 535px;
    position: absolute;
    right: 3rem;
    bottom: 2rem;
  }

  .form_page_1 {
    @include mq(737px) {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1rem;
    }

    .form_question {
      display: block !important;
      float: none !important;
      @include mq(737px) {
        margin-left: 1rem !important;
        width: calc(50% - 1rem) !important;
      }

      .form_label {
        padding: 0 !important;
        display: block !important;
        width: auto !important;
      }
    }
    .form_question.form_h2{
      @include mq(737px) {
        width: 100%;
      }
    }
    .form_responses {
      display: block !important;
      padding: 0 !important;
    }
  }

  .h5 {
    margin-top: 0;
  }

  .form_action button {
    @extend .button;
  }
}

.hero__control {
  @include fill($svgstore--play-alt, $white);

  display: none;
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 3rem;
  height: 3rem;
  background-color: $black;
  border-radius: 50%;
  color: $black;
  background-size: 3rem;

  @include mq($lg) {
    display: block;
  }
}

.hero__control--playing {
  @include fill($svgstore--pause, $black);

  background-color: $white;
  background-size: 3rem;
}


.hero__banner__text {
  @include zero;
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: $beige;
  p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8;
    font-family: $sans-serif;
    font-weight: 500;
  }

  @include mq(48em) {
    display: block;
    position: absolute;
    bottom: 2rem;
    margin: 0;
    padding: 0 2rem 0 0;
    background: none;
    color: $white;
    text-shadow: .1rem .1rem .1rem $black;
  }

  @include mq(64.0625em) {
    max-width: 50%;
  }

  @include mq(90em) {
    padding-right: 3rem;
  }
}
.hero-carousel-status {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  color: $white;
  font-family: $sans-serif;
}
