/*! Flickity v1.2.1
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.flickity-prev-next-button:hover {
  background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

.flickity-prev-next-button:active {
  filter: alpha(opacity=60);
  /* IE8 */
  opacity: 0.6; }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button:disabled {
  filter: alpha(opacity=30);
  /* IE8 */
  opacity: 0.3;
  cursor: auto; }

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-prev-next-button .arrow {
  fill: #333; }

/* color & size if no SVG - IE8 and Android 2.3 */
.flickity-prev-next-button.no-svg {
  color: #333;
  font-size: 26px; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  filter: alpha(opacity=25);
  /* IE8 */
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  filter: alpha(opacity=100);
  /* IE8 */
  opacity: 1; }

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(60px); }
  100% {
    opacity: 1;
    transform: none; } }

@keyframes slideLeft {
  from,
  to {
    left: 0;
    width: 0; }
  to {
    left: 0;
    width: 100%; } }

/*! sanitize.css v3.3.0 | CC0 1.0 Public Domain | github.com/10up/sanitize.css */
/*
 * Normalization
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

audio:not([controls]) {
  display: none; }

b,
strong {
  font-weight: bolder; }

button {
  -webkit-appearance: button;
  overflow: visible; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

button,
select {
  text-transform: none; }

details {
  display: block; }

hr {
  overflow: visible; }

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%; }

input {
  -webkit-border-radius: 0; }
  input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button; }
  input[type="number"] {
    width: auto; }
  input[type="search"] {
    -webkit-appearance: textfield; }
    input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none; }

main {
  display: block; }

pre {
  overflow: auto; }

progress {
  display: inline-block; }

summary {
  display: block; }

svg:not(:root) {
  overflow: hidden; }

template {
  display: none; }

textarea {
  overflow: auto; }

[hidden] {
  display: none; }

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit; }

* {
  font-size: inherit;
  line-height: inherit; }

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit; }

/*
 * Opinionated defaults
 */
* {
  margin: 0;
  padding: 0; }

*,
::before,
::after {
  border-style: solid;
  border-width: 0; }

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  touch-action: manipulation; }

select {
  -moz-appearance: none;
  -webkit-appearance: none; }
  select::-ms-expand {
    display: none; }
  select::-ms-value {
    color: currentColor; }

svg {
  fill: currentColor; }

[aria-busy="true"] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-disabled] {
  cursor: default; }

[hidden][aria-hidden="false"] {
  clip: rect(0 0 0 0);
  display: inherit;
  position: absolute; }
  [hidden][aria-hidden="false"]:focus {
    clip: auto; }

/*
 * Configurable defaults
 */
* {
  background-repeat: no-repeat; }

:root {
  background-color: #ffffff;
  box-sizing: border-box;
  color: #000000;
  cursor: default;
  font: 66.66667% sans-serif; }

a {
  text-decoration: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit; }

button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: 1.5em; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace; }

nav ol,
nav ul {
  list-style: none; }

small {
  font-size: 75%; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

textarea {
  resize: vertical; }

::-moz-selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none; }

/** TODO
Remove the flickity dot custom css for the main image slider _image.js
**/
:root {
  font-size: 100%;
  overflow-x: hidden;
  word-wrap: break-word; }

*,
::before,
::after {
  scroll-margin-top: 3rem; }
  @media (min-width: 64.0625em) {
    *,
    ::before,
    ::after {
      scroll-margin-top: 8rem; } }

body {
  overflow-x: hidden; }

[data-whatinput='keyboard'] :focus {
  outline: 0.125rem solid #4d90fe;
  outline-offset: -0.125rem; }

[data-whatinput='mouse'] :focus,
[data-whatinput='touch'] :focus {
  outline: 0; }

:target::before {
  content: "";
  display: block;
  height: 90px;
  /* fixed header height*/
  margin: -90px 0 0;
  /* negative fixed header height */ }

input[type='radio'] {
  border: 0.0625rem solid #58595b;
  border-radius: 0.5rem;
  background-color: #fff; }
  input[type='radio']:checked {
    background: rgba(0, 0, 0, 0.75); }

input[type="search"] {
  appearance: none; }

button {
  cursor: pointer; }

img {
  display: block;
  max-width: 100%;
  height: auto;
  position: relative; }
  img:before {
    content: ' '; }
  img:after {
    content: attr(alt);
    color: #fff;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000; }

svg:not(:root) {
  display: inherit; }

::-webkit-media-controls {
  display: none !important; }

.svgstore {
  display: block;
  position: relative;
  pointer-events: none; }
  .svgstore svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.svgstore--alert {
  padding-bottom: 100%; }

.svgstore--angle-left {
  padding-bottom: 100%; }

.svgstore--angle-right {
  padding-bottom: 100%; }

.svgstore--arrow {
  padding-bottom: 71.23288%; }

.svgstore--calendar {
  padding-bottom: 106.25%; }

.svgstore--check {
  padding-bottom: 100%; }

.svgstore--checkmark {
  padding-bottom: 86.66667%; }

.svgstore--chevron-down-alt {
  padding-bottom: 100%; }

.svgstore--chevron-down {
  padding-bottom: 100%; }

.svgstore--chevron-up {
  padding-bottom: 100%; }

.svgstore--close {
  padding-bottom: 100%; }

.svgstore--comment {
  padding-bottom: 100%; }

.svgstore--facebook-alt {
  padding-bottom: 99.41463%; }

.svgstore--facebook {
  padding-bottom: 100%; }

.svgstore--heart {
  padding-bottom: 100%; }

.svgstore--instagram {
  padding-bottom: 100%; }

.svgstore--linkedin {
  padding-bottom: 100%; }

.svgstore--location {
  padding-bottom: 128.57143%; }

.svgstore--logo-admissions {
  padding-bottom: 14.0665%; }

.svgstore--logo-online {
  padding-bottom: 47.54902%; }

.svgstore--logo {
  padding-bottom: 33.98876%; }

.svgstore--menu {
  padding-bottom: 100%; }

.svgstore--minus {
  padding-bottom: 100%; }

.svgstore--pause {
  padding-bottom: 100%; }

.svgstore--play-alt {
  padding-bottom: 100%; }

.svgstore--play {
  padding-bottom: 100%; }

.svgstore--plus {
  padding-bottom: 100%; }

.svgstore--search {
  padding-bottom: 100%; }

.svgstore--tiktok {
  padding-bottom: 100%; }

.svgstore--twitter {
  padding-bottom: 100%; }

.svgstore--youtube {
  padding-bottom: 100%; }

body {
  font-family: "merriweather", serif;
  font-size: 1.0625rem;
  line-height: 1.7059; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.hero__title,
.hero__title--sized,
.hero__title--form,
.h2,
.feature-panel__title,
.h3,
.h4,
.faculty__listing__name,
.h5,
.number-panel__text,
.h6 {
  color: #2f2f2f;
  font-family: "Hind", sans-serif; }
  .online h1, .online
  h2, .online
  h3, .online
  h4, .online
  h5, .online
  h6, .online .h1, .online .hero__title, .online .hero__title--sized, .online .hero__title--form, .online .h2, .online .feature-panel__title, .online
  .h3, .online .h4, .online .faculty__listing__name, .online .h5, .online .number-panel__text, .online
  .h6 {
    font-family: "merriweather", serif; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a,
  .h1 a,
  .hero__title a,
  .hero__title--sized a,
  .hero__title--form a,
  .h2 a,
  .feature-panel__title a,
  .h3 a,
  .h4 a,
  .faculty__listing__name a,
  .h5 a,
  .number-panel__text a,
  .h6 a {
    display: inline-block; }
  .module--blue h1, .module--blue
  h2, .module--blue
  h3, .module--blue
  h4, .module--blue
  h5, .module--blue
  h6, .module--blue .h1, .module--blue .hero__title, .module--blue .hero__title--sized, .module--blue .hero__title--form, .module--blue .h2, .module--blue .feature-panel__title, .module--blue
  .h3, .module--blue .h4, .module--blue .faculty__listing__name, .module--blue .h5, .module--blue .number-panel__text, .module--blue
  .h6 {
    color: #fff; }

h1,
.h1,
.hero__title,
.hero__title--sized,
.hero__title--form {
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.0952; }
  @media (min-width: 32em) {
    h1,
    .h1,
    .hero__title,
    .hero__title--sized,
    .hero__title--form {
      font-size: 2.625rem; } }

h2,
.h2,
.feature-panel__title {
  margin-top: 5rem;
  margin-bottom: 1.25rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125; }
  .online h2, .online .h2, .online .feature-panel__title {
    margin-top: 0; }

h3,
.h3 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.2857; }

h4,
.h4,
.faculty__listing__name {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.2308; }

h5,
.h5,
.number-panel__text {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.3636; }

h6,
.h6 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3333; }

.heading--light {
  font-weight: 500; }
  .heading--light a {
    font-weight: 500; }

strong {
  font-weight: 600; }

p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }
  .main p a:not([class]) {
    border-bottom: 1px solid #003478;
    padding-bottom: .125em; }
    .main p a:not([class]):hover {
      border-color: #0b7886;
      text-decoration: none;
      color: #0b7886; }

.p--small, .caption, .caption--alt, .video-caption, .accordion__content, .faculty__listing p:not([class]), .tabs__content__item {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: .995rem;
  line-height: 1.714; }

.p--small *:last-child, .caption *:last-child, .caption--alt *:last-child, .video-caption *:last-child, .accordion__content *:last-child, .faculty__listing p:not([class]) *:last-child, .tabs__content__item *:last-child {
  margin-bottom: 0; }

.p--large {
  font-family: "Hind", sans-serif;
  font-size: 1.23rem;
  line-height: 1.8333;
  font-weight: 500; }

.p--micro {
  font-size: .88rem !important; }

.p--sans-serif, .caption, .caption--alt, .video-caption {
  font-family: "Hind", sans-serif; }

.p--xlarge {
  font-size: 1.75rem;
  font-weight: 300; }

a {
  color: #003478;
  font-weight: 600; }
  a:hover {
    text-decoration: underline; }

blockquote {
  clear: both;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #f5f4f0; }
  blockquote > :first-child {
    margin-top: 0; }
  blockquote > :last-child {
    margin-bottom: 0; }

hr {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-top: 0.0625rem solid #58595b; }
  hr + .block {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }

ul,
ol {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-left: 1.25rem; }
  ul > :first-child,
  ol > :first-child {
    margin-top: 0; }
  ul > :last-child,
  ol > :last-child {
    margin-bottom: 0; }

li {
  margin-bottom: 0.75rem; }

li:not([class]) a:not([class]) {
  font-weight: 600;
  border-bottom: 1px solid #003478;
  padding-bottom: .125em; }
  li:not([class]) a:not([class]):hover {
    border-color: #0b7886;
    text-decoration: none;
    color: #0b7886; }

.video-caption {
  position: relative; }
  @media (min-width: 64.0625em) {
    .video-caption {
      position: absolute; } }

.caption--alt {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  line-height: 1.8; }
  .caption--alt p {
    margin-top: 0;
    margin-bottom: 0; }
  .main .caption--alt a:not([class]) {
    border-bottom: 1px solid #003478;
    padding-bottom: .125em; }
    .main .caption--alt a:not([class]):hover {
      border-color: #0b7886;
      text-decoration: none;
      color: #0b7886; }

.caption--border-purple {
  border-bottom: 5px solid #8c2287;
  padding-bottom: 1rem; }

.inline-image img {
  display: inline-block; }

.inline-image a {
  padding: 0.25rem;
  display: inline-block; }

.large--serif {
  font-size: 1.375rem;
  font-weight: lighter;
  line-height: 1.7333;
  font-family: "merriweather", serif; }

@media (min-width: 64em) {
  .two__column__list ul,
  .two__column__list ol {
    column-count: 2;
    column-gap: 3rem; } }

.image__bulleted__list li {
  list-style-type: none;
  margin-bottom: .5rem;
  background: url("data:image/svg+xml,%3Csvg width='21px' height='21px' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Enoun_Check_2422614%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='BrandeisAdmissions-Tertiary-mobile' transform='translate(-20.000000, -3772.000000)'%3E%3Cg id='Group-21' transform='translate(20.000000, 3713.000000)'%3E%3Cg id='noun_Check_2422614' transform='translate(0.000000, 59.000000)'%3E%3Crect id='Rectangle' fill='%230B7886' x='0' y='0' width='21' height='21'%3E%3C/rect%3E%3Cpolygon id='Path' fill='%23FFFFFF' fill-rule='nonzero' points='8.8993001 17 3 10.5481497 5.21837799 8.38440253 8.79151616 12.2921894 15.6852801 4 18 6.05295084'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
  margin-left: -1rem;
  padding: 0 0 .25rem 2rem;
  background-position: left 2px; }

.callout {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #f5f4f0;
  border-bottom: 5px solid #8c2287; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }

.routing__link {
  display: inline-block;
  position: relative; }
  .routing__link img {
    min-height: 10rem;
    width: 100%; }
  .routing__link:focus {
    outline-offset: 0; }

.routing__link__icon {
  position: absolute;
  bottom: 3rem;
  left: 1rem;
  display: block;
  width: 3.4375rem;
  height: 3.4375rem;
  background-color: #fff;
  border-radius: 50%;
  color: #000; }

.routing__text {
  position: absolute;
  bottom: -1rem;
  margin: 0 1rem 0 0;
  display: inline-block;
  font-family: "Hind", sans-serif !important;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3333;
  background-color: #003478;
  color: #fff;
  padding: 0.5rem 1rem; }
  @media (min-width: 48em) {
    .routing__text {
      padding: 0.5rem 1rem 0.5rem 0;
      color: #003478;
      background-color: #fff; }
      .module--gray-light .routing__text {
        background-color: #f5f5f5; } }
  a:hover .routing__text {
    padding: 0.5rem 1rem;
    background-color: #003478;
    color: #fff; }

@media (min-width: 64em) {
  .two__column__list {
    column-count: 2;
    column-gap: 3rem; } }

.wrap {
  max-width: 90rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem; }
  .wrap::before, .wrap::after {
    display: table;
    width: 0;
    content: ''; }
  .wrap::after {
    clear: both; }
  @media (min-width: 48em) {
    .wrap {
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 64.0625em) {
    .wrap {
      padding-right: 3rem;
      padding-left: 3rem; } }

.wrap--full {
  max-width: 100%; }

.wrap--wide {
  max-width: 96rem; }

.wrap--narrow {
  max-width: 70rem; }

.wrap--slim {
  max-width: 58rem; }

.wrap--relative {
  position: relative; }

.main,
.sidebar {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem; }
  .main > :first-child,
  .sidebar > :first-child {
    margin-top: 0; }
  .main > :last-child,
  .sidebar > :last-child {
    margin-bottom: 0; }

.main {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  @media (min-width: 64.0625em) {
    .main {
      margin-top: 3.25rem;
      margin-bottom: 3.25rem;
      width: calc(100% - 22rem);
      float: right; } }
  @media (min-width: 64.0625em) {
    .main {
      width: calc(100% - 24rem); } }

@media (min-width: 64.0625em) {
  .main--center {
    margin-right: auto;
    margin-left: auto;
    float: none; } }

.sidebar {
  font-size: 0.9375rem; }
  @media (min-width: 64.0625em) {
    .sidebar {
      width: 18rem;
      float: left; } }

@media (min-width: 64.0625em) {
  .main + .sidebar + .main {
    margin-top: 0; } }

.align-left,
.align-right {
  margin-bottom: 1.25rem; }
  @media (min-width: 48em) {
    .align-left,
    .align-right {
      max-width: 33.3333%; } }

@media (min-width: 48em) {
  .align-left {
    margin-right: 1.25rem;
    float: left; } }

@media (min-width: 48em) {
  .align-right {
    margin-left: 1.25rem;
    float: right; } }

@media (min-width: 48em) {
  .align-small {
    max-width: 25%; } }

@media (min-width: 48em) {
  .align-zero {
    margin-bottom: 0; } }

.align-zero-top {
  margin-top: 0; }

.full-width {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-blue {
  color: #003478; }

.text-purple {
  color: #8c2287; }

.hide {
  position: absolute;
  left: -999rem; }

.triangle-tab {
  position: relative; }
  .triangle-tab::before {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1.25rem;
    border-top: 1.25rem solid #003478;
    border-right: 1.25rem solid transparent;
    border-left: 1.25rem solid transparent;
    content: ''; }

.indent {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 1.25rem;
  border-left: 0.5rem solid #003478; }
  .indent > :first-child {
    margin-top: 0; }
  .indent > :last-child {
    margin-bottom: 0; }

.skip {
  position: absolute;
  left: -999rem; }
  .skip:focus {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 5; }

.skip-carousel {
  position: absolute;
  left: -999rem; }
  .skip-carousel:focus {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    text-align: center; }

.zero > :first-child {
  margin-top: 0; }

.zero > :last-child {
  margin-bottom: 0; }

.fadeUp {
  opacity: 0; }

.fadeUp--active {
  animation: fadeUp 800ms cubic-bezier(0.645, 0.045, 0.355, 1) forwards; }

.fact__module > :first-child {
  margin-top: 0; }

.fact__module > :last-child {
  margin-bottom: 0; }

.fact__module--large {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem; }

.accordion-group {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .accordion-group > :first-child {
    margin-top: 0; }
  .accordion-group > :last-child {
    margin-bottom: 0; }
  @media (min-width: 64.0625em) {
    .tab-accordions .accordion-group {
      display: none; } }
  .module--margin .accordion-group {
    margin-top: 0;
    margin-bottom: 0; }

.accordion {
  position: relative;
  overflow: hidden;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.accordion__heading__toggle {
  font-size: 1.0625rem;
  margin: 0;
  line-height: 1.7059; }

.accordion__toggle,
.accordion__content {
  padding: 1.25rem;
  background: #f5f4f0; }
  .tab-accordions .accordion__toggle, .tab-accordions
  .accordion__content {
    background: #003478;
    color: #fff; }
  .tab-accordions .accordion--active .accordion__toggle, .tab-accordions .accordion--active
  .accordion__content {
    background: #f5f4f0;
    color: #000; }

.accordion__toggle {
  display: block;
  color: #000;
  font-family: "Hind", sans-serif; }
  .tab-accordions .accordion__toggle {
    text-align: center; }
  .accordion__toggle:hover {
    text-decoration: none; }

.accordion__icon {
  width: 1.8125rem;
  margin-left: 1.25rem;
  padding: 0.125rem;
  float: right; }

.accordion__icon--inactive {
  display: block; }
  .accordion--active .accordion__icon--inactive {
    display: none; }

.accordion__icon--active {
  display: none; }
  .accordion--active .accordion__icon--active {
    display: block; }

.accordion__content {
  position: absolute;
  left: -999rem;
  padding: 1.25rem 1.25rem;
  top: 0;
  margin-top: 0.125rem;
  visibility: hidden;
  margin-bottom: 0; }
  .accordion__content > :first-child {
    margin-top: 0; }
  .accordion__content > :last-child {
    margin-bottom: 0; }
  .accordion--active .accordion__content {
    position: static;
    left: auto;
    visibility: visible; }
  .accordion__content a:not([class]) {
    font-weight: 600;
    border-bottom: 1px solid #003478;
    padding-bottom: .125em; }
    .accordion__content a:not([class]):hover {
      border-color: #0b7886;
      text-decoration: none;
      color: #0b7886; }

.accordion__heading {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.3333; }

.accordion__expand {
  text-align: right;
  display: block;
  font-family: "Hind", sans-serif;
  font-weight: 600; }

.notification {
  background: #2f2f2f; }
  .notification .wrap {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    background: #2f2f2f;
    color: #fff; }

.notification__container {
  position: relative;
  padding: 0 2.5rem; }

.notification__text {
  font-family: "Hind", sans-serif;
  font-size: 1.375rem;
  font-weight: 500; }
  .notification__text a {
    color: #fff;
    text-decoration: underline; }

.notification__label {
  font-family: "Hind", sans-serif !important;
  font-size: 2.25rem !important;
  font-weight: 500;
  line-height: 1.812;
  margin: 0;
  color: #fffb00;
  text-transform: uppercase; }

.notification__close {
  position: absolute;
  top: 0.5rem;
  right: 0;
  width: 1.25rem;
  color: #fff; }

.banner {
  position: relative;
  margin: auto; }

.banner__image {
  width: 100%;
  min-height: 1rem; }
  @media (min-width: 48em) {
    .banner__image {
      min-height: 11rem; } }

.banner__gradient {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  content: ''; }
  @media (min-width: 48em) {
    .banner__gradient {
      display: block; } }

.banner__text {
  margin: 0 -1rem;
  padding: 1.5rem 1rem;
  background: #f5f4f0; }
  .banner__text > :first-child {
    margin-top: 0; }
  .banner__text > :last-child {
    margin-bottom: 0; }
  .banner__text p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8;
    font-family: "Hind", sans-serif;
    font-weight: 500; }
  @media (min-width: 48em) {
    .banner__text {
      display: block;
      position: absolute;
      bottom: 2rem;
      margin: 0;
      padding: 0 2rem 0 0;
      background: none;
      color: #fff;
      text-shadow: 0.1rem 0.1rem 0.1rem #000; } }
  @media (min-width: 64.0625em) {
    .banner__text {
      max-width: 50%; } }
  @media (min-width: 90em) {
    .banner__text {
      padding-right: 3rem; } }

.block {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  .block::before, .block::after {
    display: table;
    width: 0;
    content: ''; }
  .block::after {
    clear: both; }

.block--small {
  margin-bottom: 2rem; }

.block__text {
  overflow: hidden; }
  .block__text > :first-child {
    margin-top: 0; }
  .block__text > :last-child {
    margin-bottom: 0; }

.block__text--alt p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.8; }

.breadcrumb-hide {
  display: none; }
  @media (min-width: 64.0625em) {
    .breadcrumb-hide {
      display: block; } }

.breadcrumbs-title {
  margin-bottom: 0.25rem; }
  .breadcrumbs-title a {
    color: inherit; }

.breadcrumbs {
  display: none; }
  @media (min-width: 64.0625em) {
    .breadcrumbs {
      display: block;
      font-family: "Hind", sans-serif;
      font-size: 0.75rem;
      font-weight: 400;
      margin: 2.5rem 0; }
      .block .breadcrumbs {
        margin-top: 0.5rem; } }

.breadcrumbs__item {
  color: #003478 !important;
  font-weight: 400;
  font-size: .82rem; }

.breadcrumbs__item--active {
  font-weight: 600;
  color: #000 !important; }

.button-group {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .button-group > :first-child {
    margin-top: 0; }
  .button-group > :last-child {
    margin-bottom: 0; }

.button-group--alt {
  margin-left: -1rem;
  padding-left: 0;
  padding-top: .96em;
  list-style-type: none; }
  .button-group--alt > * {
    margin-top: 1rem;
    margin-left: 1rem; }
  @media (min-width: 64em) {
    .button-group--alt {
      display: inline-flex;
      margin-top: 0; }
      .button-group--alt li a {
        margin-bottom: auto !important; } }
  .button-group--alt li {
    display: inline; }
    .button-group--alt li a {
      margin-bottom: 1rem; }
  .button-group--alt li:first-child {
    margin-top: 1rem; }

.button-stack {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: 2rem; }

.button-stack__item {
  display: flex;
  flex-grow: 1;
  margin-bottom: 2rem !important;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 100%; }
  @media (min-width: 64em) {
    .button-stack--3 .button-stack__item {
      flex-grow: 0;
      width: calc(33% - 2rem); } }
  .button-stack--2 .button-stack__item {
    width: 100%; }
    @media (min-width: 64em) {
      .button-stack--2 .button-stack__item {
        flex-grow: 0;
        width: calc(50% - 2rem); } }
  .button-stack__item .button, .button-stack__item .form .form_action button, .form .form_action .button-stack__item button, .button-stack__item .module__container .form_action button, .module__container .form_action .button-stack__item button, .button-stack__item .hero__form .form_action button, .hero__form .form_action .button-stack__item button {
    flex-grow: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; }

.button, .form .form_action button, .module__container .form_action button, .hero__form .form_action button {
  display: inline-block;
  padding: 1rem 1.875rem .75rem;
  border: 0.0625rem solid;
  border-radius: 0.1875rem;
  color: #000;
  font-family: "Hind", sans-serif;
  font-size: .9rem;
  font-weight: 600;
  line-height: 1.1667;
  text-transform: uppercase;
  text-align: center; }
  .button:hover, .form .form_action button:hover, .module__container .form_action button:hover, .hero__form .form_action button:hover, .button:focus, .form .form_action button:focus, .module__container .form_action button:focus, .hero__form .form_action button:focus {
    text-decoration: none;
    background-color: #003478;
    color: #fff;
    border-color: #003478; }
  .button:focus, .form .form_action button:focus, .module__container .form_action button:focus, .hero__form .form_action button:focus {
    outline-offset: 0.125rem; }

.button--white {
  color: #fff; }
  .button--white:hover, .button--white:focus {
    background-color: #fff;
    color: #000;
    border-color: #fff; }

.button--solid-white {
  border-color: #fff;
  background-color: #fff; }
  .button--solid-white:hover, .button--solid-white:focus {
    border-color: #fff; }
  .online .button--solid-white {
    color: #fff;
    background-color: #8c2287;
    border: none; }
    .online .button--solid-white:hover, .online .button--solid-white:focus {
      background-color: #fff;
      color: #000; }

.button--purple {
  color: #fff;
  border: none;
  background-color: #8c2287; }
  .button--purple:hover, .button--purple:focus {
    background-color: #fff;
    color: #000; }

.button--blue {
  background: #003478;
  border-color: #003478;
  color: #fff; }
  .button--blue:hover, .button--blue:focus {
    background-color: #fff;
    color: #000;
    border-color: #000; }

.button--teal {
  border-color: transparent;
  background: #0b7886;
  color: #fff; }
  .button--teal:hover, .button--teal:focus {
    border-color: transparent;
    background: #0b7886; }

.button--teal-white {
  border-color: transparent;
  background: #0b7886;
  color: #fff; }
  .button--teal-white:hover, .button--teal-white:focus {
    border-color: transparent;
    background: #fff;
    color: #000; }

.button--overlay {
  background: #fff; }

.button--text {
  padding: 0;
  border: 0;
  color: #003478; }
  .button--text:hover, .button--text:focus {
    color: #003478;
    text-decoration: underline;
    background-color: transparent; }
  .module--gray-dark .button--text {
    color: #fff; }

.button--full {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  display: block;
  width: 100%;
  text-align: center; }

.callout-alt {
  position: relative;
  margin: 0;
  padding-block: 6rem; }

.callout__image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.callout__content {
  position: relative; }
  @media (min-width: 64.0625em) {
    .callout__content {
      width: 50%; } }
  .callout__content .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1em; }
    @media (min-width: 64.0625em) {
      .callout__content .button-group {
        flex-wrap: nowrap; } }
  .callout__content .button, .callout__content .form .form_action button, .form .form_action .callout__content button, .callout__content .module__container .form_action button, .module__container .form_action .callout__content button, .callout__content .hero__form .form_action button, .hero__form .form_action .callout__content button {
    width: 100%;
    gap: 1.5em; }
    @media (min-width: 64.0625em) {
      .callout__content .button, .callout__content .form .form_action button, .form .form_action .callout__content button, .callout__content .module__container .form_action button, .module__container .form_action .callout__content button, .callout__content .hero__form .form_action button, .hero__form .form_action .callout__content button {
        width: auto; } }

.callout__title {
  color: #fff;
  font-family: "merriweather", serif; }

.callout__para {
  color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 19.7px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; }

.card {
  padding: 1.25rem 1.25rem 2.5rem;
  background: #f5f4f0;
  color: #58595b;
  font-family: "Hind", sans-serif; }
  .card p {
    margin: 0; }
  .module--beige .card,
  .module--gray-light .card,
  .module--gray-dark .card {
    background: #fff; }
  .card a,
  .card p strong {
    font-size: 1.15rem; }

.card--alt {
  background: #fff;
  color: #58595b;
  font-family: "Hind", sans-serif;
  padding: 1.25rem 0; }

.card__header {
  margin-bottom: 1.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 0.25rem solid #58595b; }

.card__meta {
  font-weight: 600;
  font-size: .83rem;
  text-transform: uppercase; }

@media (min-width: 48em) {
  .carousel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -1rem; } }

.carousel::after {
  display: none;
  content: 'flickity'; }
  @media (min-width: 48em) {
    .carousel::after {
      content: ''; } }

.dropdown {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  position: relative;
  width: 20rem;
  max-width: 100%; }

.dropdown__toggle {
  display: block;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-transform: none;
  text-align: left; }
  .dropdown__toggle:hover, .dropdown__toggle:focus {
    background-color: #fff;
    color: #000;
    border-color: #000; }
  .dropdown--active .dropdown__toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: transparent;
    background: #f5f4f0;
    color: #000; }

.dropdown__icon {
  width: 1.25rem;
  margin-top: -0.1875rem;
  margin-left: 0.75rem;
  float: right; }

.dropdown__icon--inactive {
  display: block; }
  .dropdown--active .dropdown__icon--inactive {
    display: none; }

.dropdown__icon--active {
  display: none; }
  .dropdown--active .dropdown__icon--active {
    display: block; }

.dropdown__list {
  position: absolute;
  left: -999rem;
  width: 100%;
  margin-top: -0.0625rem;
  margin-bottom: 0;
  padding: 0 0.75rem 0.75rem;
  border: 0.0625rem solid #000;
  border-top: 0;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
  background: #f5f4f0;
  list-style: none;
  box-shadow: 0 0.375rem 0.375rem rgba(0, 0, 0, 0.125);
  visibility: hidden;
  z-index: 1; }
  .dropdown--active .dropdown__list {
    position: static;
    left: auto;
    position: absolute;
    visibility: visible; }

.dropdown__item {
  margin-bottom: 0.625rem; }

.dropdown__link {
  text-transform: none; }

.fact {
  display: flex;
  flex-direction: column;
  background: #003478;
  font-family: "Hind", sans-serif;
  text-align: center; }

.fact__title {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-content: center;
  padding: 2.5rem 1rem;
  background: #003478;
  color: #fff; }
  @media (min-width: 90em) {
    .fact__title {
      padding: 1rem; } }

.fact__number {
  width: 100%;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1; }
  @media (min-width: 90em) {
    .fact__number {
      font-size: 7.5rem; } }

.fact__unit {
  width: 100%;
  line-height: 1; }

.fact__description {
  padding: 2.5rem 1rem 1rem;
  background: #fff; }
  .fact__description > :first-child {
    margin-top: 0; }
  .fact__description > :last-child {
    margin-bottom: 0; }
  .fact__description p {
    font-family: "Hind", sans-serif;
    font-weight: 500;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8; }

.faculty__listing__heading__first {
  margin-top: 0; }

.faculty__listing {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 0.0625rem solid #58595b;
  padding: 1.25rem 0; }
  .faculty__listing > :first-child {
    margin-top: 0; }
  .faculty__listing > :last-child {
    margin-bottom: 0; }

.faculty__listing__image {
  width: 75px;
  height: 75px;
  float: right;
  margin: 0 0 1rem 1rem; }
  @media (min-width: 48em) {
    .faculty__listing__image {
      width: 145px;
      height: 146px;
      margin: 0 0 1rem 1rem; } }
  @media (min-width: 64em) {
    .faculty__listing__image {
      width: 170px;
      height: 170px; } }

.faculty__listing__name {
  font-family: "merriweather", serif;
  margin-bottom: 0;
  margin-top: 0; }
  .faculty__listing__name a {
    font-weight: normal; }
    .faculty__listing__name a:hover {
      color: #0b7886; }

a.faculty__listing__name {
  color: #003478; }
  a.faculty__listing__name:hover {
    color: #0b7886; }

.faculty__listing__title,
.faculty__listing__contact,
.faculty__listing__hours {
  margin-top: 0.25rem;
  margin-bottom: 0rem;
  font-size: 0.9375rem;
  line-height: 1.8; }

.faculty__listing__contact,
.faculty__listing__hours {
  font-family: "Hind", sans-serif; }
  .faculty__listing__contact span:not(:last-child):after,
  .faculty__listing__hours span:not(:last-child):after {
    content: "\a";
    white-space: pre; }
    @media (min-width: 48em) {
      .faculty__listing__contact span:not(:last-child):after,
      .faculty__listing__hours span:not(:last-child):after {
        content: ' |'; } }

.faculty__listing__contact {
  margin-bottom: 0.5rem; }

.faculty__listing__email {
  font-weight: 500; }

.feature-box {
  margin: 2rem 0;
  border: 0.0625rem solid #2f2f2f; }
  @media (min-width: 48em) {
    .feature-box {
      display: flex;
      align-items: center; } }

.feature-box__media {
  width: 100%; }
  @media (min-width: 48em) {
    .feature-box__media {
      width: 49%;
      align-self: stretch; }
      .feature-box--swap .feature-box__media {
        order: 1; } }
  .feature-box__media img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover'; }

.feature-box__content {
  padding: 3rem; }
  .feature-box__content > :first-child {
    margin-top: 0; }
  .feature-box__content > :last-child {
    margin-bottom: 0; }
  @media (min-width: 48em) {
    .feature-box__content {
      width: 52%; } }

.feature-panel {
  margin-top: 5rem;
  color: #fff; }
  @media (min-width: 64.0625em) {
    .feature-panel .wrap {
      display: flex; } }

.feature-panel--blue,
.feature-panel-bg--blue {
  background-color: #003478; }

.feature-panel-bg-mobile--blue {
  background-color: #003478; }
  @media (min-width: 64.0625em) {
    .feature-panel-bg-mobile--blue {
      background-color: transparent; } }

@media (min-width: 64.0625em) {
  .feature-panel__media {
    width: 60%;
    flex-shrink: 0;
    order: 1; } }

.feature-panel__media img {
  width: 100%; }

.feature-panel-bg-mobile--blue .feature-panel__media {
  background-color: #003478; }

.feature-panel__media__inner {
  position: relative; }

.feature-panel__title {
  color: #fff; }

.feature-panel__media {
  position: relative;
  transform: translateY(-2rem); }
  @media (min-width: 64.0625em) {
    .feature-panel__media {
      transform: none;
      margin-top: -2rem;
      margin-bottom: 2rem; } }

.feature-panel__caption {
  margin: 0;
  font-size: 0.98rem; }
  .feature-panel--caption-abs .feature-panel__caption {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #000;
    color: #fff;
    padding: 0.75rem 1.25rem; }
    .feature-panel--caption-abs .feature-panel__caption p {
      margin: 0; }

.feature-panel__content {
  padding: 0 2rem 2rem 0; }
  @media (min-width: 64.0625em) {
    .feature-panel__content {
      display: flex;
      align-items: center;
      padding: 0 12rem 2rem 0;
      margin-right: -8rem;
      padding-top: 2rem;
      padding-left: 2rem; }
      .feature-panel-bg--blue .feature-panel__content {
        padding-left: 0; } }

.feature-panel__inner > :first-child {
  margin-top: 0; }

.feature-panel__inner > :last-child {
  margin-bottom: 0; }

.feature-panel__inner hr {
  border-color: #fff;
  margin: 0; }

.feature-panel__link {
  display: block;
  width: 4.6875rem;
  height: 4.6875rem;
  background-color: #fff;
  border-radius: 50%;
  color: #000;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  z-index: 1; }
  .feature-panel__link:focus {
    outline-offset: 0.5rem;
    z-index: 1; }

.feature-panel__list {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0; }
  .feature-panel__list .svgstore {
    width: 1rem;
    display: inline-block;
    padding-bottom: 4%;
    margin-right: 0.5rem; }

.feature {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .feature::before, .feature::after {
    display: table;
    width: 0;
    content: ''; }
  .feature::after {
    clear: both; }
  @media (min-width: 48em) {
    .feature {
      display: flex; } }

@media (min-width: 48em) {
  .feature--full .feature__media, .feature--full
  .feature__text {
    width: calc(50% - 2.5rem); } }

.feature__media {
  margin-bottom: 1.25rem; }
  .feature__media > :first-child {
    margin-top: 0; }
  .feature__media > :last-child {
    margin-bottom: 0; }
  @media (min-width: 48em) {
    .feature__media {
      width: calc(40% - 1.25rem);
      margin-bottom: 0;
      float: left; } }

.feature__text > :first-child {
  margin-top: 0; }

.feature__text > :last-child {
  margin-bottom: 0; }

@media (min-width: 48em) {
  .feature__text {
    align-self: center;
    width: calc(60% - 1.25rem);
    margin-left: auto; } }

.feature__text--alt p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.8; }

.feed-group {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  .feed-group > :first-child {
    margin-top: 0; }
  .feed-group > :last-child {
    margin-bottom: 0; }

.feed-group-no-heading {
  margin-top: 3rem; }

.feed-title {
  margin-bottom: 2.5rem;
  padding-top: 1.25rem;
  border-top: 0.25rem solid #58595b; }

.feed {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding-top: 2.5rem;
  border-top: 0.0625rem solid #58595b; }
  .feed::before, .feed::after {
    display: table;
    width: 0;
    content: ''; }
  .feed::after {
    clear: both; }
  .feed:first-child,
  .feed-title + .feed {
    padding-top: 0;
    border-top: 0; }

.feed__text {
  overflow: hidden;
  font-size: 0.9375rem; }
  .feed__text > :first-child {
    margin-top: 0; }
  .feed__text > :last-child {
    margin-bottom: 0; }

.feed__text--alt p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.8; }

.feed__image {
  position: relative; }
  .feed__image a {
    display: block; }
    [data-whatinput='keyboard'] .feed__image a:focus {
      outline-offset: 0; }

[data-whatinput='keyboard'] .flickity-enabled:focus {
  outline: 0.125rem solid #4d90fe;
  outline-offset: -0.125rem; }

.flickity-page-dots {
  top: 1rem;
  right: 1rem;
  bottom: auto;
  width: auto;
  font-size: 0; }
  @media (min-width: 48em) {
    .flickity-page-dots {
      top: 2.5rem;
      right: 2.5rem; } }
  .flickity-page-dots .dot {
    width: 1rem;
    height: 1rem;
    margin: 0 0.25rem;
    border: 0.0625rem solid;
    background: rgba(255, 255, 255, 0.001);
    color: #003478;
    opacity: 1; }
    .flickity-page-dots .dot:first-child {
      margin-left: 0; }
    .flickity-page-dots .dot:last-child {
      margin-right: 0; }
    .flickity-page-dots .dot.is-selected {
      background: currentColor; }

.flickity-prev-next-button {
  width: 3.75rem;
  height: 3.75rem;
  padding: 0.5rem;
  border-radius: 0;
  background: rgba(255, 255, 255, 0.001); }
  .flickity-prev-next-button svg {
    position: static;
    width: auto;
    height: auto; }
  .flickity-prev-next-button:focus {
    outline: 0.125rem solid #4d90fe;
    outline-offset: -0.125rem;
    box-shadow: none; }
  .flickity-prev-next-button:active {
    opacity: 1; }
  .flickity-prev-next-button:hover, .flickity-prev-next-button:focus {
    background: rgba(255, 255, 255, 0.001); }
  .flickity-prev-next-button:disabled {
    opacity: 0; }
  .flickity-prev-next-button.previous {
    left: 0; }
  .flickity-prev-next-button.next {
    right: 0; }
  .flickity-prev-next-button .arrow {
    fill: rgba(255, 255, 255, 0.75); }

.floatnav {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding: 0 1rem;
  list-style: none;
  z-index: 3; }
  @media (min-width: 48em) {
    .floatnav {
      position: absolute;
      top: 2rem;
      right: 0;
      margin: 0;
      padding: 0; } }
  @media (min-width: 90em) {
    .floatnav {
      top: 3rem; } }

@media (min-width: 48em) {
  .floatnav--module {
    margin-top: 5rem;
    margin-bottom: 5rem;
    position: static;
    padding: 0 1rem; } }

@media (min-width: 64.0625em) {
  .floatnav--module {
    text-align: center; } }

@media (min-width: 90em) {
  .floatnav--module {
    position: absolute;
    top: 5rem;
    right: 0;
    margin: 0;
    padding: 0; } }

@media (min-width: 48em) {
  .floatnav__item {
    margin: 0.25rem 0; } }

@media (min-width: 48em) {
  .floatnav--module .floatnav__item {
    margin: 0.75rem 0; } }

@media (min-width: 64.0625em) {
  .floatnav--module .floatnav__item {
    display: inline-block;
    margin: 0 0.625rem; } }

@media (min-width: 90em) {
  .floatnav--module .floatnav__item {
    display: block;
    margin: 0.25rem 0; } }

.floatnav__link {
  width: 100%;
  text-align: center; }
  @media (min-width: 48em) {
    .floatnav__link {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-color: transparent;
      background: #fff;
      text-align: left; } }
  .floatnav--module .floatnav__link {
    border-color: transparent;
    background: #fff; }
    @media (min-width: 48em) {
      .floatnav--module .floatnav__link {
        border-radius: 0.1875rem;
        text-align: center; } }
    @media (min-width: 90em) {
      .floatnav--module .floatnav__link {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        text-align: left; } }

.footer {
  background: #58595b;
  color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5; }
  .footer p {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .footer a:not([class]) {
    color: inherit;
    font-weight: 400; }

.footer__top {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.footer__container > :first-child {
  margin-top: 0; }

.footer__container > :last-child {
  margin-bottom: 0; }

.footer__bottom .footer__container {
  border-top: 0.125rem solid #797a7c;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.footer__heading {
  margin-top: 2.5rem !important;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.125; }
  @media (min-width: 64.0625em) {
    .footer__heading {
      margin-top: 0 !important; } }

.footer__hr {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  border-top: 0.25rem solid #797a7c; }
  .footer__heading + .footer__hr {
    margin-top: -0.75rem; }

.footer__nav {
  margin: 0 0 2rem 0;
  padding: 0;
  list-style: none; }
  .footer__nav > :first-child {
    margin-top: 0; }
  .footer__nav > :last-child {
    margin-bottom: 0; }

.footer__nav__item {
  padding: 1rem 0;
  border-bottom: 0.0625rem solid #797a7c;
  margin: 0; }

.footer__nav__link {
  color: #fff;
  font-weight: 400;
  font-size: .995rem; }

.footer__social {
  margin: 2rem 0;
  padding: 0;
  list-style: none; }
  .footer__social::before, .footer__social::after {
    display: table;
    width: 0;
    content: ''; }
  .footer__social::after {
    clear: both; }
  .footer__hr + .footer__social {
    margin-top: 0; }

.footer__social__item {
  margin: 0 1.25rem 0 0;
  float: left; }

.footer__social__link {
  color: #fff;
  display: block;
  width: 2.1875rem; }
  .footer__social__link:focus {
    outline-offset: .125rem; }

.footer__logo {
  color: #fff;
  display: block;
  max-width: 10rem;
  margin-bottom: 2rem; }

.grid__item.footer__grid__first {
  width: 100%; }
  @media (min-width: 64.0625em) {
    .grid__item.footer__grid__first {
      width: calc(50% - 6rem);
      border-right: 0.0625rem solid #797a7c;
      margin-right: 4.5rem; } }

.form {
  font-family: "Hind", sans-serif;
  font-size: 0.9375rem;
  font-weight: 500; }
  .form > :first-child {
    margin-top: 0; }
  .form > :last-child {
    margin-bottom: 0; }
  .form .form_action button {
    margin-bottom: 0; }

.form__group--narrow {
  max-width: 40.625rem; }

.form__input, .form__textarea,
.form_text input,
.form_address textarea,
.form_responses input[type="text"] {
  display: block;
  width: 100%;
  height: 3rem;
  margin-top: 0.5rem;
  padding: 0.75rem;
  border: 0.0625rem solid #2f2f2f;
  background: #fff;
  line-height: normal;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 0.1875rem; }
  .form__input::placeholder, .form__textarea::placeholder,
  .form_text input::placeholder,
  .form_address textarea::placeholder,
  .form_responses input[type="text"]::placeholder {
    color: inherit; }
  .form__input:focus, .form__textarea:focus,
  .form_text input:focus,
  .form_address textarea:focus,
  .form_responses input[type="text"]:focus {
    box-shadow: none; }

.form__textarea {
  height: auto;
  min-height: 5rem; }

.form__req {
  color: #FF0200; }

.form__label,
.form_label {
  font-weight: 600; }

.form_responses {
  margin-bottom: 1rem; }

.form_multiselect .form_responses {
  font-size: .8rem;
  margin-top: 0.5rem;
  border: 0.0625rem solid #2f2f2f;
  background: #fff;
  line-height: normal;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 0.1875rem; }
  .form_multiselect .form_responses::placeholder {
    color: inherit; }
  .form_multiselect .form_responses:focus {
    box-shadow: none; }

.form__select {
  position: relative;
  margin-top: 0.5rem;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0.1875rem; }
  .form__select select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0; }
  .form__select option {
    font-weight: 600; }

.form_select select,
.form_address select {
  font-family: "Hind", sans-serif;
  font-weight: 600;
  display: block;
  width: 100%;
  height: 3rem;
  margin-top: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 3rem;
  border: 0.0625rem solid #003478;
  color: #000;
  line-height: normal;
  font-size: 0.9375rem;
  border-radius: 0.1875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23003478%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cpath%20d%3D%22M10%200C4.5%200%200%204.5%200%2010s4.5%2010%2010%2010%2010-4.5%2010-10S15.5%200%2010%200zm0%2014.5L4.5%208l1.3-1.5%204.2%205%204.2-5L15.5%208%2010%2014.5z%22%2F%3E%3C%2Fsvg%3E);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) 50%;
  background-size: 1.25rem 1.25rem;
  appearance: none; }

.form_select option,
.form_address option {
  font-weight: 600; }

.form__control {
  font-family: "Hind", sans-serif;
  font-weight: 600;
  display: block;
  width: 100%;
  height: 3rem;
  margin-top: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 3rem;
  border: 0.0625rem solid #003478;
  color: #003478;
  line-height: normal;
  font-size: 0.9375rem;
  border-radius: 0.1875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23003478%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cpath%20d%3D%22M10%200C4.5%200%200%204.5%200%2010s4.5%2010%2010%2010%2010-4.5%2010-10S15.5%200%2010%200zm0%2014.5L4.5%208l1.3-1.5%204.2%205%204.2-5L15.5%208%2010%2014.5z%22%2F%3E%3C%2Fsvg%3E);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) 50%;
  background-size: 1.25rem 1.25rem;
  appearance: none; }

.form__select__text {
  width: 100%;
  height: 3rem;
  padding: 1rem 3rem 1rem 0.75rem;
  border: 0.0625rem solid;
  border-radius: 0.1875rem;
  color: #000;
  font-family: "Hind", sans-serif;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal; }

.form__select__text--focus {
  outline: 0.125rem solid #4d90fe;
  outline-offset: -0.125rem; }

.form__select__arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  padding: 1rem; }

.form__radio input[type="radio"],
.form_radio input[type="radio"] {
  position: absolute;
  border: 0;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px); }

.form__radio label,
.form_radio label {
  position: relative;
  display: inline-block;
  margin-left: 2px;
  padding-left: calc(1.25rem + 10px);
  height: 1.25rem; }

.form__radio input[type="radio"] + label:before,
.form_radio input[type="radio"] + label:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.form__radio input[type="radio"] + label:before,
.form_radio input[type="radio"] + label:before {
  content: '\a0';
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.5rem;
  height: 1.25rem;
  width: 1.25rem;
  border: 1px solid #000;
  border-radius: 50%;
  background: #fff; }
  .module--beige .form__radio input[type="radio"] + label:before, .module--beige
  .form_radio input[type="radio"] + label:before {
    background: #f5f4f0; }
  .module--gray-light .form__radio input[type="radio"] + label:before, .module--gray-light
  .form_radio input[type="radio"] + label:before {
    background: #f5f5f5; }

.form__radio input[type="radio"]:checked + label::before,
.form_radio input[type="radio"]:checked + label::before {
  background: #0b7886;
  background-size: 150%;
  box-shadow: inset 0 0 0 0.125rem #fff; }
  .module--beige .form__radio input[type="radio"]:checked + label::before, .module--beige
  .form_radio input[type="radio"]:checked + label::before {
    box-shadow: inset 0 0 0 0.125rem #f5f4f0; }

[data-whatinput='keyboard'] .form__radio input[type="radio"]:focus + label::before, [data-whatinput='keyboard']
.form_radio input[type="radio"]:focus + label::before {
  outline: 0.125rem solid #4d90fe;
  outline-offset: -0.125rem; }

.form__checkbox,
.form_checkbox {
  margin: 0.5rem 0; }
  .form__checkbox label,
  .form_checkbox label {
    position: relative;
    display: inline-block;
    margin-left: 2px;
    padding-left: calc(1.25rem + 10px); }
  .form__checkbox input[type="checkbox"],
  .form_checkbox input[type="checkbox"] {
    position: absolute;
    border: 0;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0px, 0px, 0px, 0px); }
  .form__checkbox input[type="checkbox"] + label:before,
  .form__checkbox input[type="checkbox"] + label:after,
  .form_checkbox input[type="checkbox"] + label:before,
  .form_checkbox input[type="checkbox"] + label:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    overflow: hidden;
    margin: 0;
    padding: 0; }
  .form__checkbox input[type="checkbox"] + label:before,
  .form_checkbox input[type="checkbox"] + label:before {
    content: '\a0';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid #2f2f2f;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    background-color: #fff; }
    .module--beige .form__checkbox input[type="checkbox"] + label:before, .module--beige
    .form_checkbox input[type="checkbox"] + label:before {
      background-color: #f5f4f0; }
  .form__checkbox input[type="checkbox"]:checked + label::before,
  .form_checkbox input[type="checkbox"]:checked + label::before {
    background: #0b7886;
    border-color: #0b7886; }
  .form__checkbox input[type="checkbox"]:checked + label::after,
  .form_checkbox input[type="checkbox"]:checked + label::after {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2015%2013%22%3E%3Cpath%20d%3D%22M5.9%2013L0%206.5l2.2-2.1%203.6%203.9L12.7%200%2015%202.1%22%2F%3E%3C%2Fsvg%3E);
    background-size: 15px 13px;
    background-position: center center;
    background-repeat: no-repeat; }
  [data-whatinput='keyboard'] .form__checkbox input[type="checkbox"]:focus + label::before, [data-whatinput='keyboard']
  .form_checkbox input[type="checkbox"]:focus + label::before {
    outline: 0.125rem solid #4d90fe;
    outline-offset: -0.125rem; }

.form_question {
  margin-top: 2rem; }
  .hero .form_question {
    margin-top: 0.5rem; }
  .form_question legend {
    position: absolute;
    left: -999rem; }

.form_birthdate select {
  font-family: "Hind", sans-serif;
  font-weight: 600;
  height: 3rem;
  margin-top: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 3rem;
  border: 0.0625rem solid #003478;
  color: #000;
  line-height: normal;
  font-size: 0.9375rem;
  border-radius: 0.1875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23003478%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cpath%20d%3D%22M10%200C4.5%200%200%204.5%200%2010s4.5%2010%2010%2010%2010-4.5%2010-10S15.5%200%2010%200zm0%2014.5L4.5%208l1.3-1.5%204.2%205%204.2-5L15.5%208%2010%2014.5z%22%2F%3E%3C%2Fsvg%3E);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) 50%;
  background-size: 1.25rem 1.25rem;
  appearance: none; }
  .hero .form_birthdate select {
    width: 100%;
    margin-left: 0 !important; }

.form_birthdate option {
  font-weight: 600; }

@media (min-width: 737px) {
  .module__container .form_page_1 {
    margin-left: -1rem; } }

.module__container .form_page_1 .form_question {
  display: block !important;
  float: none !important; }
  @media (min-width: 737px) {
    .module__container .form_page_1 .form_question {
      margin-left: 1rem !important;
      width: calc(50% - 1rem) !important; } }
  .module__container .form_page_1 .form_question .form_label {
    padding: 0 !important;
    display: block !important;
    width: auto !important; }

@media (min-width: 737px) {
  .module__container .form_page_1 .form_question.form_h2 {
    width: 100%; } }

.module__container .form_page_1 .form_responses {
  display: block !important;
  padding: 0 !important; }

.grid {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.grid__container {
  display: flex;
  flex-wrap: wrap;
  margin: -0.625rem; }
  .grid__container::before, .grid__container::after {
    display: table;
    width: 0;
    content: ''; }
  .grid__container::after {
    clear: both; }
  .grid__container.two-up-grid {
    font-size: .9375rem;
    line-height: 1.714; }
    .grid__container.two-up-grid a:not([class]) {
      font-weight: 600;
      border-bottom: 1px solid #003478;
      padding-bottom: .125em; }
      .grid__container.two-up-grid a:not([class]):hover {
        border-color: #0b7886;
        text-decoration: none;
        color: #0b7886; }
    .grid__container.two-up-grid h1 a, .grid__container.two-up-grid h2 a, .grid__container.two-up-grid h3 a, .grid__container.two-up-grid h4 a, .grid__container.two-up-grid h5 a, .grid__container.two-up-grid h6 a {
      color: #003478;
      border-bottom: none !important; }
      .grid__container.two-up-grid h1 a:hover, .grid__container.two-up-grid h2 a:hover, .grid__container.two-up-grid h3 a:hover, .grid__container.two-up-grid h4 a:hover, .grid__container.two-up-grid h5 a:hover, .grid__container.two-up-grid h6 a:hover {
        text-decoration: underline !important;
        color: #003478 !important; }

.grid__container--center {
  justify-content: center; }

.grid__container--scroll::before {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20100%20100%22%3E%3Cpath%20d%3D%22M39.7%2012.1c.6%200%201.2.2%201.7.7L77%2048.4c.9.9.9%202.4%200%203.4L41.4%2087.4c-.9.9-2.4.9-3.4%200s-.9-2.4%200-3.4l33.9-33.9L38%2016.2c-.9-.9-.9-2.4%200-3.4.5-.4%201.1-.7%201.7-.7z%22%2F%3E%3C%2Fsvg%3E);
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  transform: translateY(-50%);
  background-color: #58595b;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2.5rem 2.5rem;
  content: '';
  z-index: 1;
  pointer-events: none; }
  @media (min-width: 48em) {
    .grid__container--scroll::before {
      display: none; } }

.grid__container--scroll::after {
  display: none;
  content: 'flickity'; }
  @media (min-width: 80em) {
    .grid__container--scroll::after {
      content: ''; } }

.grid__container--scroll.flickity-enabled {
  display: block; }

.grid__container--scroll .flickity-slider > * {
  width: 19.25rem; }
  @media (min-width: 48em) {
    .grid__container--scroll .flickity-slider > * {
      width: 22.625rem; } }

.grid__container--scroll--scrolled::before {
  display: none; }

.grid__container--middle {
  align-items: center; }

.grid--slider {
  margin-top: 2rem; }
  @media (min-width: 48em) {
    .grid--slider {
      margin-top: 0; } }
  .grid--slider .flickity-prev-next-button {
    top: auto;
    bottom: -3rem; }
    .grid--slider .flickity-prev-next-button .arrow {
      fill: #003478; }
  .grid--slider .flickity-page-dots {
    margin-top: 3rem;
    position: static; }
  .grid--slider.flickity-enabled {
    display: block; }
  .grid--slider::after {
    display: none;
    content: 'flickity'; }
    @media (min-width: 48em) {
      .grid--slider::after {
        content: ''; } }

.grid__container--scroll--alt .flickity-slider > * {
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 64em) {
    .grid__container--scroll--alt .flickity-slider > * {
      width: 30.625rem; } }

.grid__container--space {
  margin: -1.25rem -0.625rem; }

.grid__container--space-alt {
  margin: -0.625rem -2rem; }

.grid__item {
  width: 100%;
  padding: 0.625rem;
  float: left; }
  .grid__item > :first-child {
    margin-top: 0; }
  .grid__item > :last-child {
    margin-bottom: 0; }

.grid__item--space {
  padding: 1.25rem 0.625rem; }

.grid__item--space-alt {
  padding: 0.625rem 2rem; }

.grid__item--flex {
  display: flex; }
  .grid__item--flex > * {
    width: 100%; }

.grid__item--center {
  align-self: center; }

@media (min-width: 32em) {
  .grid__item--4 {
    width: 50%; } }

@media (min-width: 64.0625em) {
  .grid__item--4 {
    width: 25%; } }

@media (max-width: 48em) {
  .grid--slider .grid__item--4 {
    width: 90%; } }

@media (min-width: 32em) {
  .grid__item--3 {
    width: 50%; } }

@media (min-width: 64.0625em) {
  .grid__item--3 {
    width: 33.3333%; } }

@media (min-width: 64.0625em) {
  .grid__item--3--narrow {
    width: 50%; } }

@media (min-width: 90em) {
  .grid__item--3--narrow {
    width: 33.3333%; } }

@media (min-width: 32em) {
  .grid__item--3--wide {
    width: 100%; } }

@media (min-width: 48em) {
  .grid__item--3--wide {
    width: 50%; } }

@media (min-width: 80em) {
  .grid__item--3--wide {
    width: 33.3333%; } }

@media (min-width: 32em) {
  .grid__item--2 {
    width: 50%; } }

.grid__item--2-alt {
  width: 100%; }
  @media (min-width: 48em) {
    .grid__item--2-alt {
      width: 50%; } }

@media (min-width: 48em) {
  .grid__item--66 {
    width: 66.666%; } }

@media (min-width: 48em) {
  .grid__item--33 {
    width: 33.333%; } }

.header-container {
  position: relative;
  z-index: 4; }

.header {
  padding: 1rem 0;
  background: #fff;
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.125); }
  @media (min-width: 48em) {
    .header {
      padding: 1.5rem 0; } }
  @media (min-width: 64.0625em) {
    .nav-permanent .header {
      padding: 0; } }

@media (min-width: 64.0625em) {
  .header--nav {
    padding-top: 3rem;
    padding-bottom: 3rem; } }

@media (min-width: 72em) {
  .header--nav {
    padding-top: 4rem;
    padding-bottom: 4rem; } }

@media (min-width: 76em) {
  .header--nav {
    padding-top: 5rem;
    padding-bottom: 5rem; } }

.header--nav .wrap {
  display: flex;
  justify-content: space-between; }

.header__logo {
  width: 20rem;
  max-width: calc(100% - 6.5rem);
  float: left;
  color: inherit;
  overflow: hidden;
  color: #003478; }
  @media (min-width: 64.0625em) {
    .header--nav .header__logo {
      margin-left: 0;
      margin-right: auto; } }
  @media (min-width: 64.0625em) {
    .nav-permanent .header__logo {
      display: none; } }

.header__toggle {
  width: 2.5rem;
  margin-left: 0.5rem;
  padding: 0.625rem;
  float: right;
  border-radius: 0.1875rem;
  background: #003478;
  color: #fff; }
  .header__toggle:focus {
    background: #003478; }
  @media (min-width: 64.0625em) {
    .header--nav .header__toggle {
      display: none; } }

.header__toggle--inactive {
  display: block; }

.header__toggle--active {
  display: none; }

.header__toggle--search {
  margin-left: auto; }
  .search-active .header__toggle--search .header__toggle--inactive {
    display: none; }
  .search-active .header__toggle--search .header__toggle--active {
    display: block; }

.menu-active .header__toggle--menu .header__toggle--inactive {
  display: none; }

.menu-active .header__toggle--menu .header__toggle--active {
  display: block; }

.hero-container {
  position: relative;
  padding-top: .1rem; }
  .hero-container > :first-child {
    margin-top: 0; }
  .hero-container > :last-child {
    margin-bottom: 0; }
  [data-whatinput='keyboard'] .hero-container .flickity-enabled:focus {
    outline: 0.125rem solid #4d90fe;
    outline-offset: -0.125rem;
    outline-offset: 0;
    z-index: 3; }

.hero-slider {
  margin: 0;
  padding: 0;
  list-style: none; }
  .hero-slider li {
    margin: 0; }
  .hero-slider .flickity-page-dots {
    top: 0;
    right: 0;
    width: 100%;
    margin-top: calc(46.0417% - 2rem); }
    @media (min-width: 64.0625em) {
      .hero-slider .flickity-page-dots {
        top: 50%;
        right: 4rem;
        left: auto;
        width: auto;
        margin: 0;
        transform: translateY(-50%); } }
    @media (min-width: 90em) {
      .hero-slider .flickity-page-dots {
        right: 5rem; } }
    .hero-slider .flickity-page-dots .dot {
      color: #fff;
      filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="0" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
      filter: drop-shadow(0 0.1rem 0.1rem #000);
      filter: progid:DXImageTransform.Microsoft.Shadow(color=#000, strength=0); }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .hero-slider .flickity-page-dots .dot {
          background: rgba(0, 0, 0, 0.54); } }
      @media (min-width: 64.0625em) {
        .hero-slider .flickity-page-dots .dot {
          display: block;
          margin: 0.5rem 0; } }

.hero-slider-alt {
  position: relative; }
  @media (min-width: 64.0625em) {
    .hero-slider-alt .hero__image {
      position: absolute;
      width: 100%;
      height: 100%; } }
  .hero-slider-alt .hero__image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .hero-slider-alt .hero__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: static;
    text-shadow: none;
    background-color: #003478; }
    @media (min-width: 64.0625em) {
      .hero-slider-alt .hero__content {
        background-color: transparent;
        min-height: 577px; } }
  .hero-slider-alt .hero__gradient {
    background: linear-gradient(89deg, #000 6.18%, rgba(0, 0, 0, 0) 61.39%);
    height: 100%; }
  .hero-slider-alt .flickity-prev-next-button {
    border-radius: 50%;
    width: 2.563rem;
    height: 2.563rem;
    padding: 0;
    top: 20%;
    background-color: #8c2287;
    border: none; }
    @media (min-width: 64.0625em) {
      .hero-slider-alt .flickity-prev-next-button {
        border: 1px solid #fff;
        background-color: transparent;
        top: 50%; } }
    .hero-slider-alt .flickity-prev-next-button.next {
      right: 1rem; }
    .hero-slider-alt .flickity-prev-next-button.previous {
      left: 1rem; }
    .hero-slider-alt .flickity-prev-next-button:hover, .hero-slider-alt .flickity-prev-next-button:focus {
      background-color: #8c2287;
      border: none; }
  .hero-slider-alt .hero__button {
    background-color: #8c2287;
    text-shadow: none;
    border: none;
    color: #fff; }
    .hero-slider-alt .hero__button:hover, .hero-slider-alt .hero__button:focus {
      background-color: #fff;
      color: #000; }
  .hero-slider-alt .hero__heading {
    font-family: "merriweather", serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    color: #fff; }
    @media (min-width: 64.0625em) {
      .hero-slider-alt .hero__heading {
        font-size: 44px;
        line-height: 48px;
        text-shadow: 0.1rem 0.1rem 0.1rem #000; } }
  .hero-slider-alt .hero__text {
    color: #fff;
    font-family: "Hind", sans-serif;
    font-size: 19.7px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; }

.hero {
  position: relative;
  width: 100%;
  list-style: none;
  display: block; }
  .hero > :first-child {
    margin-top: 0; }
  .hero > :last-child {
    margin-bottom: 0; }

.hero__image {
  position: relative;
  color: #fff; }
  .hero__image img {
    width: 100%; }
  .hero--tall .hero__image {
    height: inherit; }
    .hero--tall .hero__image img {
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover'; }

@media (min-width: 64.0625em) {
  .hero__gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.75)); } }

.hero__gradient-alt {
  height: 30%; }

.hero__gradient-alt-2 {
  height: 20%; }

.hero__wrap {
  background-color: #f5f4f0;
  position: relative; }
  @media (min-width: 48em) {
    .hero__wrap {
      background-color: transparent; } }
  @media (min-width: 64.0625em) {
    .hero__wrap {
      padding-left: 8rem;
      padding-right: 8rem; } }
  .hero--form .hero__wrap {
    background-color: #f5f4f0; }
    @media (min-width: 64.0625em) {
      .hero--form .hero__wrap {
        background-color: transparent; } }

.hero__content {
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: #f5f4f0;
  display: block; }
  .hero__content > :first-child {
    margin-top: 0; }
  .hero__content > :last-child {
    margin-bottom: 0; }
  @media (min-width: 48em) {
    .hero__content {
      margin: 0 -2rem;
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 64.0625em) {
    .hero__content {
      position: absolute;
      bottom: 2rem;
      width: calc(66.6667% - 2rem);
      margin: 0;
      padding: 0;
      transition: opacity ease 600ms;
      background: transparent;
      color: #fff;
      opacity: 0;
      text-shadow: 0.1rem 0.1rem 0.1rem #000;
      left: 3rem; }
      .is-selected .hero__content {
        transition-delay: 300ms;
        opacity: 1; } }
  @media (min-width: 90em) {
    .hero__content {
      left: 2rem;
      bottom: 3rem;
      width: calc(50% - 3rem); } }

a.hero__content {
  display: block; }
  a.hero__content:hover, a.hero__content:focus {
    text-decoration: none; }

.hero__content--alt {
  opacity: 1; }

.hero__title, .hero__title--sized, .hero__title--form {
  position: absolute;
  bottom: 1rem;
  margin: 0;
  color: #fff;
  text-shadow: 0.1rem 0.1rem 0.1rem #000; }
  @media (min-width: 48em) {
    .hero__title, .hero__title--sized, .hero__title--form {
      bottom: 2rem; } }
  @media (min-width: 90em) {
    .hero__title, .hero__title--sized, .hero__title--form {
      bottom: 3rem; } }
  .hero__title a, .hero__title--sized a, .hero__title--form a {
    color: inherit; }

@media (max-width: 1024px) {
  .hero__title--sized {
    position: static;
    color: #2f2f2f;
    text-shadow: none;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; } }

@media (max-width: 1024px) {
  .hero__title--form {
    position: static;
    color: #2f2f2f;
    text-shadow: none;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; } }

@media (min-width: 64.0625em) {
  .hero__title--form {
    width: calc(100% - 648px); } }

.hero__heading {
  display: block;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-family: "Hind", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.25; }
  @media (min-width: 64.0625em) {
    .hero__heading {
      font-size: 2.75rem;
      color: #fff; } }

.hero__text {
  display: block;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-size: 1.25rem; }
  @media (min-width: 64.0625em) {
    .hero__text {
      color: #fff; } }

@media (min-width: 64.0625em) {
  .hero__button {
    color: #fff; } }

@media (min-width: 64.0625em) {
  .hero--tall {
    height: 650px; } }

@media (min-width: 64.0625em) {
  .hero--video {
    height: 650px; } }

.hero--video video {
  display: none; }
  @media (min-width: 64.0625em) {
    .hero--video video {
      display: block; } }

.hero--video img {
  display: block; }
  @media (min-width: 64.0625em) {
    .hero--video img {
      display: none; } }

.hero__video {
  display: none;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
  z-index: -1; }
  @media (min-width: 64.0625em) {
    .hero__video {
      display: block; } }
  .hero__video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover'; }
    .hero__video video::-webkit-media-controls {
      display: none !important; }
    .hero__video video::-webkit-media-controls-start-playback-button {
      display: none !important;
      appearance: none; }

.hero__form {
  border-top: 17px solid #0b7886;
  background-color: #fff;
  padding: 2rem;
  overflow: auto; }
  @media (min-width: 64.0625em) {
    .hero__form {
      width: 535px;
      position: absolute;
      right: 3rem;
      bottom: 2rem; } }
  @media (min-width: 737px) {
    .hero__form .form_page_1 {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1rem; } }
  .hero__form .form_page_1 .form_question {
    display: block !important;
    float: none !important; }
    @media (min-width: 737px) {
      .hero__form .form_page_1 .form_question {
        margin-left: 1rem !important;
        width: calc(50% - 1rem) !important; } }
    .hero__form .form_page_1 .form_question .form_label {
      padding: 0 !important;
      display: block !important;
      width: auto !important; }
  @media (min-width: 737px) {
    .hero__form .form_page_1 .form_question.form_h2 {
      width: 100%; } }
  .hero__form .form_page_1 .form_responses {
    display: block !important;
    padding: 0 !important; }
  .hero__form .h5, .hero__form .number-panel__text {
    margin-top: 0; }

.hero__control {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20width%3D%2257%22%20height%3D%2257%22%20viewBox%3D%220%200%2057%2057%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20transform%3D%22translate%281%201%29%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Ccircle%20stroke%3D%22%23FFF%22%20stroke-width%3D%222%22%20fill%3D%22%232F2F2F%22%20cx%3D%2227.5%22%20cy%3D%2227.5%22%20r%3D%2227.5%22%2F%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M20%2039.89V15l21%2012.444%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E);
  display: none;
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 3rem;
  height: 3rem;
  background-color: #000;
  border-radius: 50%;
  color: #000;
  background-size: 3rem; }
  @media (min-width: 64.0625em) {
    .hero__control {
      display: block; } }

.hero__control--playing {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23000%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2057%2057%22%3E%3Cstyle%3E.st0%7Bfill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bfill%3A%232F2F2F%3Bstroke%3A%23FFFFFF%3Bstroke-width%3A2%3B%7D%20.st1%7Bfill%3A%23FFFFFF%3B%7D%3C%2Fstyle%3E%3Cg%20id%3D%22Homepage%22%3E%3Cg%20id%3D%22Brandeis-Advancement-Concept-1%22%3E%3Cg%20id%3D%22Group-30%22%3E%3Cg%20id%3D%22Catch-Up%22%3E%3Cg%20id%3D%22Group-34%22%20transform%3D%22translate%281%201%29%22%3E%3Ccircle%20id%3D%22Oval%22%20class%3D%22st0%22%20cx%3D%2227.5%22%20cy%3D%2227.5%22%20r%3D%2227.5%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3Cpath%20class%3D%22st1%22%20d%3D%22M18%2016h5.1v24.9H18zM33.9%2016.1H39V41h-5.1z%22%2F%3E%3C%2Fsvg%3E);
  background-color: #fff;
  background-size: 3rem; }

.hero__banner__text {
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: #f5f4f0; }
  .hero__banner__text > :first-child {
    margin-top: 0; }
  .hero__banner__text > :last-child {
    margin-bottom: 0; }
  .hero__banner__text p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8;
    font-family: "Hind", sans-serif;
    font-weight: 500; }
  @media (min-width: 48em) {
    .hero__banner__text {
      display: block;
      position: absolute;
      bottom: 2rem;
      margin: 0;
      padding: 0 2rem 0 0;
      background: none;
      color: #fff;
      text-shadow: 0.1rem 0.1rem 0.1rem #000; } }
  @media (min-width: 64.0625em) {
    .hero__banner__text {
      max-width: 50%; } }
  @media (min-width: 90em) {
    .hero__banner__text {
      padding-right: 3rem; } }

.hero-carousel-status {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  color: #fff;
  font-family: "Hind", sans-serif; }

.image-slider-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin: -0.375rem;
  padding: 0.375rem;
  overflow: hidden; }
  .image-slider-container:first-child, .image-slider-container:last-child {
    margin: -0.375rem !important; }

.image-slider {
  margin: 0;
  padding: 0; }
  .image-slider .flickity-viewport {
    overflow: visible; }
    @media (min-width: 64.0625em) {
      .image-slider .flickity-viewport {
        overflow: hidden; } }
  .image-slider .flickity-page-dots {
    top: 1rem;
    right: 1rem; }
    @media (min-width: 48em) {
      .image-slider .flickity-page-dots {
        top: 2.5rem;
        right: 2.5rem; } }
    @media (min-width: 64.0625em) {
      .image-slider .flickity-page-dots {
        display: none; } }
  .image-slider .flickity-prev-next-button {
    top: 0;
    transform: none;
    background-color: #003478;
    margin-top: calc(66.666% - 3.75rem); }
    .image-slider .flickity-prev-next-button .arrow {
      fill: #fff; }
    .image-slider .flickity-prev-next-button.previous {
      left: auto;
      right: 4rem; }
    .image-slider .flickity-prev-next-button:hover {
      background-color: #0b7886; }

@media (min-width: 64.0625em) {
  .image-slider--full .flickity-viewport {
    overflow: visible; } }

.image {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden; }
  .image:hover .image__overlay, .image:focus-within .image__overlay {
    transform: translateY(0); }
  .image:hover .image__social, .image:focus-within .image__social {
    opacity: 1; }
  .image:focus {
    outline-offset: 0; }
    .image:focus .image__overlay {
      transform: translateY(0); }
    .image:focus .image__social {
      opacity: 1; }
  .image-slider .image {
    margin: 0 0.625rem; }
    @media (min-width: 64.0625em) {
      .image-slider .image {
        width: 50%;
        margin: 0;
        box-shadow: none; } }
  .image-slider--full .image {
    margin: 0 0.625rem; }
    @media (min-width: 64.0625em) {
      .image-slider--full .image {
        width: 100%; } }

.image--social {
  position: relative;
  width: 14rem;
  max-height: 14rem;
  margin-top: 1rem;
  margin-left: 1rem; }
  @media (min-width: 48em) {
    .image--social {
      width: calc(50% - 1rem);
      max-height: 310px; } }
  @media (min-width: 64.0625em) {
    .image--social {
      width: calc(33.333% - 1rem);
      max-height: 330px; } }
  @media (min-width: 80em) {
    .image--social {
      width: calc(16.666% - 1rem);
      max-height: 13rem; } }

.image__item {
  min-height: 10rem; }
  .image--social .image__item {
    min-height: 14rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover'; }

.image__social {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 1rem;
  transition: opacity 300ms ease;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  opacity: 0; }
  .image--social.focus-within .image__social {
    opacity: 1; }

.image__date {
  width: 100%; }

.image__desc {
  width: 100%;
  margin: auto 0;
  max-height: 10rem;
  overflow: hidden;
  font-size: .83rem; }
  .image--social .image__desc {
    max-height: 7rem; }
    .image--social .image__desc p {
      margin-top: 0.5rem; }
  .image__desc a {
    color: #fff; }

.image__meta {
  width: 100%;
  margin-top: auto; }
  .image__meta::before, .image__meta::after {
    display: table;
    width: 0;
    content: ''; }
  .image__meta::after {
    clear: both; }

.image__meta__link {
  margin-right: 0.5rem;
  float: left;
  color: #fff;
  overflow: hidden; }

.image__meta__icon {
  width: 0.75rem;
  margin-top: 0.1875rem;
  margin-right: 0.25rem;
  float: left; }

.image__overlay {
  padding: 1.25rem;
  background: #003478;
  color: #fff; }
  .image__overlay > :first-child {
    margin-top: 0; }
  .image__overlay > :last-child {
    margin-bottom: 0; }
  .image--social.focus-within .image__overlay {
    transform: translateY(0); }
  @media (min-width: 64.0625em) {
    .image__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateY(100%) translateY(-4rem);
      transition: transform 300ms ease; } }

.image__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.75)); }
  .image-slider .image__gradient {
    display: none; }
    @media (min-width: 64.0625em) {
      .image-slider .image__gradient {
        display: block; } }
  @media (min-width: 64.0625em) {
    .image-slider--full .image__gradient {
      display: none; } }

.image__heading {
  color: #fff; }

.image__heading--link {
  font-weight: 400; }
  @media (max-width: 1024px) {
    .image-slider .image__heading--link {
      color: #003478; } }
  .image:hover .image__heading--link {
    text-decoration: underline; }

.image__text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem;
  color: #fff;
  text-shadow: 0.1rem 0.1rem 0.1rem #000; }
  .image__text > :first-child {
    margin-top: 0; }
  .image__text > :last-child {
    margin-bottom: 0; }
  .image-slider .image__text {
    position: static;
    padding: 1.25rem 0;
    background: #fff;
    color: #000;
    text-shadow: none; }
    @media (min-width: 64.0625em) {
      .image-slider .image__text {
        position: absolute;
        background: transparent;
        color: #fff;
        text-shadow: 0.1rem 0.1rem 0.1rem #000; } }
  @media (min-width: 64.0625em) {
    .image-slider--full .image__text {
      position: static;
      text-shadow: none;
      color: inherit;
      background: inherit; } }

.image__box {
  background: #fff;
  padding: 1.25rem; }

.image__shape {
  position: relative;
  padding-right: 2rem;
  padding-bottom: 2rem; }
  .image__shape::before {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    background-color: #003478; }

body .juicer-feed {
  font-family: "Hind", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  overflow: visible; }
  body .juicer-feed a {
    transition: none; }
  body .juicer-feed h1.referral {
    display: none; }
  body .juicer-feed.modern li.feed-item {
    border-width: 1px; }
  body .juicer-feed .j-poster h3 {
    color: #003478; }
  body .juicer-feed .j-date {
    font-size: inherit;
    font-weight: 600;
    color: #767676; }
  body .juicer-feed .j-message a {
    color: #003478;
    font-weight: 500 !important; }
    body .juicer-feed .j-message a:hover {
      color: #003478;
      text-decoration: underline !important; }
  body .juicer-feed .j-meta a {
    color: #767676; }
    body .juicer-feed .j-meta a:hover {
      color: #003478; }
  body .juicer-feed .j-paginate.juicer-button {
    display: inline-block;
    width: auto;
    margin-bottom: 0;
    padding: 1rem 1.875rem .75rem;
    border: 0.0625rem solid;
    border-radius: 0.1875rem;
    color: #000;
    font-family: "Hind", sans-serif;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.1667;
    text-transform: uppercase; }
    body .juicer-feed .j-paginate.juicer-button:hover {
      background: none;
      color: #000;
      text-decoration: none; }

.media {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .media > :first-child {
    margin-top: 0; }
  .media > :last-child {
    margin-bottom: 0; }
  .media img {
    width: 100%; }

@media (min-width: 48em) {
  .media--narrow {
    max-width: 66.6667%; } }

@media (min-width: 48em) {
  .media--left,
  .media--right {
    width: calc(50% - 1rem); } }

@media (min-width: 48em) {
  .media--left {
    margin-right: 2rem;
    float: left; } }

@media (min-width: 48em) {
  .media--right {
    margin-left: 2rem;
    float: right; } }

.media--tight {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem; }
  @media (min-width: 64.0625em) {
    .media--tight {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem; } }

.media__text {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }
  .media__text > :first-child {
    margin-top: 0; }
  .media__text > :last-child {
    margin-bottom: 0; }

.media__item {
  display: inline-block;
  position: relative; }
  [data-whatinput='keyboard'] .media__item:focus {
    outline: 0.125rem solid #4d90fe;
    outline-offset: 0; }
  .media--full .media__item {
    display: block; }

.media__overlay {
  position: relative; }

.media__overlay--alt {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem; }
  .media__overlay--alt > :first-child {
    margin-top: 0; }
  .media__overlay--alt > :last-child {
    margin-bottom: 0; }

.media__overlay__text {
  color: #fff;
  font-family: "Hind", sans-serif;
  font-weight: 600;
  text-align: center;
  text-shadow: 0.1rem 0.1rem 0.1rem #000; }

.media__icon {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
  width: 3.4375rem;
  color: #000; }
  .media--full .media__icon {
    display: block;
    position: static;
    margin: auto;
    color: #000; }
  .image-slider--full .media__icon {
    position: absolute; }
  .media__icon.video__captioned {
    bottom: 3.5rem; }
    @media (min-width: 48em) {
      .media__icon.video__captioned {
        bottom: 4.2rem; } }
    @media (min-width: 64.0625em) {
      .media__icon.video__captioned {
        bottom: 1.2rem; } }

.media__credit {
  margin-top: -0.75rem;
  font-size: .88rem; }

.media__embed {
  position: relative;
  padding-bottom: 56.25%; }
  .media__embed > * {
    position: absolute;
    width: 100%;
    height: 100%; }

.jw-skin-bekle.jwplayer:not(.jw-flag-touch):not(.jw-error):not(.jw-state-error):not(.jw-state-buffering) .jw-display-icon-container:focus {
  background-color: #139ed5 !important; }

.jw-skin-bekle:not(.jw-flag-touch) .jw-button-color:hover, .jw-skin-bekle .jw-button-color:focus {
  background-color: #139ed5 !important; }

.mega {
  position: absolute;
  height: var(--height, auto);
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background: #f5f4f0;
  color: #000;
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.125);
  visibility: hidden;
  transform: translateY(-0.25rem);
  opacity: 0;
  z-index: -1;
  transition: transform 300ms ease, visibility 300ms ease, opacity 300ms ease; }
  .nav__item--active .mega {
    transform: none;
    visibility: visible;
    opacity: 1; }

.mega__menu {
  width: calc(55% - 0.625rem);
  float: left;
  margin-left: 0;
  margin-right: auto; }

.mega__content {
  width: calc(45% - 0.625rem);
  float: right; }
  .mega__content > :first-child {
    margin-top: 0; }
  .mega__content > :last-child {
    margin-bottom: 0; }

.mega__heading {
  margin-bottom: 1.25rem;
  border-bottom: 0.25rem solid #003478;
  color: #003478;
  font-family: "Hind", sans-serif;
  font-size: 2rem;
  font-weight: 400; }

.mega__heading--small {
  padding-top: 0.875rem;
  padding-bottom: 0.375rem;
  font-size: 1.25rem; }

.mega__nav {
  width: 33.333%;
  margin: 0;
  padding: 0 1.25rem 0 0;
  float: left;
  font-family: "Hind", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  list-style: none; }

.mega__nav__item {
  margin-bottom: 1.25rem; }

.mega__nav__link {
  font-weight: 600;
  font-size: 1.1rem; }
  .mega__nav__item--current > .mega__nav__link {
    color: #000;
    text-decoration: none; }

.mega__close {
  position: absolute;
  cursor: pointer;
  top: 2rem;
  right: 2rem; }

.mega__close--icon {
  position: absolute;
  right: 0;
  width: 1.5rem; }

.menu-container {
  position: relative;
  max-width: 90rem;
  margin: auto; }

.menu {
  position: absolute;
  left: -999rem;
  width: 100%;
  padding: 2.5rem 1rem;
  background: #f5f4f0;
  font-family: "Hind", sans-serif;
  font-size: 1rem;
  line-height: 1.125;
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.125);
  visibility: hidden;
  z-index: 4; }
  .menu > :first-child {
    margin-top: 0; }
  .menu > :last-child {
    margin-bottom: 0; }
  @media (min-width: 48em) {
    .menu {
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 64.0625em) {
    .menu {
      width: 21.25rem;
      padding-right: 1rem;
      padding-left: 1rem; } }
  .menu-active .menu {
    position: static;
    left: auto;
    position: absolute;
    visibility: visible; }
    @media (min-width: 64.0625em) {
      .menu-active .menu {
        right: 2rem; } }
    @media (min-width: 90em) {
      .menu-active .menu {
        right: 3rem; } }

@media (min-width: 64.0625em) {
  .menu--small {
    display: none; } }

.menu--large {
  display: none; }
  @media (min-width: 64.0625em) {
    .menu--large {
      display: block; } }

.menu__list {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding: 0;
  list-style: none; }

.menu__list--primary {
  text-transform: uppercase; }

.menu__list--sub {
  position: absolute;
  left: -999rem;
  margin: 1.25rem 0 0;
  font-weight: 500;
  text-transform: none;
  visibility: hidden; }
  .menu__item--active > .menu__list--sub {
    position: static;
    left: auto;
    visibility: visible; }

.menu__item {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.menu__link {
  display: inline-block;
  max-width: calc(100% - 2rem);
  font-weight: 600; }
  .menu__item--current > .menu__link {
    color: inherit;
    font-weight: 600; }
    .menu__item--current > .menu__link:hover {
      text-decoration: none; }

.menu__link--null {
  color: #003478; }

.menu__link--sub {
  padding-left: 0.75rem;
  font-weight: 400; }

.menu__link--sub-sub {
  padding-left: 1.5rem;
  font-weight: 400; }

.menu__sub-toggle {
  width: 1.125rem;
  float: right; }

.menu__sub-toggle__icon--inactive {
  display: block; }
  .menu__item--active > .menu__sub-toggle > span > .menu__sub-toggle__icon--inactive {
    display: none; }

.menu__sub-toggle__icon--active {
  display: none; }
  .menu__item--active > .menu__sub-toggle > span > .menu__sub-toggle__icon--active {
    display: block; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.modal__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75); }

.modal__container {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.modal__status {
  text-align: center; }

.modal__item {
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto; }

.modal__video {
  position: relative;
  padding-bottom: 56.25%;
  background: #000; }

.modal__embed {
  position: absolute;
  width: 100%;
  height: 100%; }

.modal__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.modal__nav--previous {
  left: 0; }

.modal__nav--next {
  right: 0; }

.modal__close {
  position: absolute;
  top: 0;
  right: 0; }

.modal__caption {
  display: block;
  color: #fff;
  margin-top: 1rem;
  font-size: 1.25rem; }

.modal {
  z-index: 5; }

.modal__container {
  max-width: 90rem;
  margin: auto;
  padding: 3rem 1rem; }
  @media (min-width: 48em) {
    .modal__container {
      padding: 3rem 2rem; } }
  @media (min-width: 90em) {
    .modal__container {
      padding: 3rem; } }

.modal__overlay,
.modal__status,
.modal__controls,
.modal__close {
  transition: opacity 320ms ease;
  opacity: 0; }

.modal--active .modal__overlay,
.modal--active .modal__controls,
.modal--active .modal__close,
.modal--loading .modal__status,
.modal--loaded .modal__status {
  opacity: 1; }

.modal__item {
  transform: scale(0.75);
  transition: opacity 320ms ease, transform 320ms ease;
  opacity: 0; }

.modal--loaded .modal__item {
  transform: scale(1);
  opacity: 1; }

.modal__close {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M0%2022.9L22.9.1%2024%201.2%201.1%2024%22%2F%3E%3Cpath%20d%3D%22M0%201.1L1.1%200%2024%2022.8l-1.1%201.1%22%2F%3E%3C%2Fsvg%3E);
  width: 3rem;
  height: 3rem;
  background-position: center;
  background-size: 1rem 1rem;
  text-indent: -999rem;
  overflow: hidden; }

.modal__nav--icon {
  display: block;
  width: 3rem;
  color: #fff;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="0" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
  filter: drop-shadow(0 0.1rem 0.1rem #000);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#000,strength=0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .modal__nav--icon {
      background: rgba(0, 0, 0, 0.54); } }

.module {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  .module > :first-child {
    margin-top: 0; }
  .module > :last-child {
    margin-bottom: 0; }
  .module.triangle-tab {
    padding-top: 6.25rem; }

.module--margin {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.module--tight {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }
  @media (min-width: 64.0625em) {
    .module--tight {
      padding-top: 5rem;
      padding-bottom: 5rem; } }
  .module--tight.triangle-tab {
    padding-top: 3.75rem; }
    @media (min-width: 64.0625em) {
      .module--tight.triangle-tab {
        padding-top: 6.25rem; } }

.module--tight-alt {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }
  @media (min-width: 64.0625em) {
    .module--tight-alt {
      padding-top: 3.25rem;
      padding-bottom: 3.25rem; } }

.module--beige {
  background: #f5f4f0; }

.module--gray-light {
  background: #f5f5f5; }

.module--gray-dark {
  background: #58595b;
  color: #fff; }
  .module--gray-dark .module__title {
    color: #fff; }

.module--blue {
  background: #003478;
  color: #fff; }
  .module--blue .module__title {
    color: #fff; }

.module--teal {
  background: #0b7886;
  color: #fff; }
  .module--teal .module__title {
    color: #fff; }

.module--shadow {
  position: relative;
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.125);
  z-index: 1; }

.module--relative {
  position: relative; }

.module__container > :first-child {
  margin-top: 0; }

.module__container > :last-child {
  margin-bottom: 0; }

.module__container p:nth-of-type(3).p--sans-serif, .module__container p.caption:nth-of-type(3), .module__container p.caption--alt:nth-of-type(3), .module__container p.video-caption:nth-of-type(3) {
  margin-top: -1rem; }

.module__header > :first-child {
  margin-top: 0; }

.module__header > :last-child {
  margin-bottom: 0; }

@media (min-width: 48em) {
  .module__header {
    display: flex;
    justify-content: space-between;
    align-items: center; } }

@media (min-width: 48em) {
  .module__header .button, .module__header .form .form_action button, .form .form_action .module__header button, .module__header .module__container .form_action button, .module__container .form_action .module__header button, .module__header .hero__form .form_action button, .hero__form .form_action .module__header button {
    margin-top: -1rem; } }

.module__split {
  margin: 1rem 0; }
  @media (min-width: 48em) {
    .module__split {
      column-count: 2;
      column-gap: 2rem; } }
  .module__split > :first-child {
    margin-top: 0; }

.module__container__link {
  display: block; }
  [data-whatinput='keyboard'] .module__container__link:focus {
    outline-offset: 0; }

.module__title {
  margin-bottom: 2.5rem;
  text-align: center; }

.module__title--spacing {
  margin-bottom: 2.5rem; }

.module__title--alt {
  font-weight: 500; }

.module__subtitle {
  margin-top: -2rem;
  margin-bottom: 2.5rem;
  font-size: 1.25rem;
  text-align: center; }

.module--overflow-hide {
  overflow: hidden; }

@media (min-width: 64.0625em) {
  .nav-permanent {
    margin-top: 3.75rem; } }

.nav-container {
  display: none; }
  @media (min-width: 64.0625em) {
    .nav-container {
      display: block;
      float: right; } }

.nav-placeholder {
  display: none;
  height: 2.5625rem; }
  .nav-fixed .nav-placeholder {
    display: block; }
  .nav-permanent .nav-placeholder {
    display: none; }

.nav-fixed .nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #003478;
  color: #fff; }

.nav-fixed .nav__wrap {
  max-width: 90rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0.625rem;
  padding-right: 2rem;
  padding-bottom: 0.5625rem;
  padding-left: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .nav-fixed .nav__wrap::before, .nav-fixed .nav__wrap::after {
    display: table;
    width: 0;
    content: ''; }
  .nav-fixed .nav__wrap::after {
    clear: both; }
  @media (min-width: 90em) {
    .nav-fixed .nav__wrap {
      padding-right: 3rem;
      padding-left: 3rem; } }

.nav__logo {
  display: none;
  width: 9rem;
  margin: 0.6875rem auto 0.6875rem 0;
  float: left;
  color: #fff; }
  @media (min-width: 73.5em) {
    .nav__logo {
      width: 10rem; } }
  .nav-fixed .nav__logo {
    display: block; }

.nav__group {
  float: right;
  margin-top: 0.75rem; }
  .nav-fixed .nav__group {
    margin-top: 0.25rem; }
    @media (min-width: 80em) {
      .nav-fixed .nav__group {
        margin-left: auto; } }

.nav__search-toggle {
  display: none;
  width: 0.9375rem;
  margin-left: 1rem;
  padding: 0.8125rem 0;
  float: right;
  color: inherit; }
  .nav__search-toggle:focus {
    background-color: transparent; }
  .nav-fixed .nav__search-toggle {
    display: block; }

.nav__search-toggle__inactive {
  display: block; }
  .nav-search-active .nav__search-toggle__inactive {
    display: none; }

.nav__search-toggle__active {
  display: none; }
  .nav-search-active .nav__search-toggle__active {
    display: block; }

.nav__list {
  margin: 0;
  padding: 0;
  float: right;
  list-style: none;
  display: flex;
  flex-wrap: nowrap; }
  .nav__list::before, .nav__list::after {
    display: table;
    width: 0;
    content: ''; }
  .nav__list::after {
    clear: both; }

.nav__item {
  margin: 0 0 0 1rem;
  float: left; }
  @media (min-width: 73.5em) {
    .nav__item {
      margin: 0 0 0 2rem; } }

.nav__item--active::before {
  height: 3rem;
  background: rgba(255, 255, 255, 0.001);
  content: ''; }
  @media (min-width: 72em) {
    .nav__item--active::before {
      height: 4rem; } }
  @media (min-width: 76em) {
    .nav__item--active::before {
      height: 5rem; } }
  .nav-fixed .nav__item--active::before {
    height: 0.5rem; }

.nav__link {
  display: block;
  position: relative;
  padding: 0.5625rem 0;
  color: inherit;
  font-family: "Hind", sans-serif;
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 1.95;
  text-transform: uppercase; }
  @media (min-width: 64.0625em) {
    .nav__link {
      white-space: nowrap; } }
  @media (min-width: 78em) {
    .nav__link {
      font-size: 0.975rem;
      line-height: 1.7; } }
  .nav__link::before {
    display: none;
    position: absolute;
    bottom: 0.125rem;
    left: 0;
    width: 0;
    height: 0.25rem;
    background: #003478;
    content: '';
    transition: all ease 350ms; }
    @media (min-width: 64.0625em) {
      .nav__link::before {
        display: block; } }
    .nav-fixed .nav__link::before {
      background: #fff; }
  .nav__link:hover {
    text-decoration: none; }
    .nav__link:hover::before {
      width: 100%;
      animation: slideLeft ease 350ms; }
  .nav-permanent .nav__link {
    text-transform: none;
    font-size: 0.8625rem;
    font-weight: 500; }
    @media (min-width: 72em) {
      .nav-permanent .nav__link {
        font-size: 0.925rem; } }
    @media (min-width: 76em) {
      .nav-permanent .nav__link {
        font-size: 0.9875rem; } }
    @media (min-width: 90em) {
      .nav-permanent .nav__link {
        font-size: 1.05rem;
        line-height: 1.4375; } }
  .nav__item--current > .nav__link::before {
    display: block; }

.nav__link--arrow {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .nav__link--arrow > span {
    position: absolute;
    color: #000;
    background-color: #fff;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    width: 1.5rem;
    outline: 0.125rem solid #4d90fe;
    outline-offset: -0.125rem; }
    .nav__link--arrow > span span {
      width: 1.15rem;
      left: 50%;
      transform: translateX(-50%); }

.nav__link--arrow:focus {
  outline: 0;
  display: block;
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
  height: 20px;
  line-height: 20px;
  margin-top: -20px;
  position: relative; }

.notice-group {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.notice {
  padding: 1.25rem; }
  .notice > :first-child {
    margin-top: 0; }
  .notice > :last-child {
    margin-bottom: 0; }
  .notice:nth-child(odd) {
    background: #f5f4f0; }

.notice__label {
  text-transform: uppercase;
  font-weight: 600; }

.number-panel__top {
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto; }
  .number-panel__top .h1, .number-panel__top .hero__title, .number-panel__top .hero__title--sized, .number-panel__top .hero__title--form {
    margin-top: 0; }

.number-panel__grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-top: -1rem; }

.number-panel__item {
  margin-left: 1rem;
  margin-top: 1rem;
  min-height: 18.25rem;
  width: calc(100% - 1rem); }
  .number-panel__item > :first-child {
    margin-top: 0; }
  .number-panel__item > :last-child {
    margin-bottom: 0; }
  @media (min-width: 48em) {
    .number-panel__item {
      width: calc(50% - 1rem); } }
  @media (min-width: 64.0625em) {
    .number-panel__item {
      width: calc(25% - 1rem); }
      .number-panel__grid--2 .number-panel__item {
        width: calc(50% - 1rem); } }
  @media (min-width: 1280px) {
    .number-panel__grid--2 .number-panel__item {
      width: calc(33% - 1rem); } }

.number-panel__image {
  display: none; }
  @media (min-width: 48em) {
    .number-panel__image {
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; } }
  .number-panel__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover'; }

.number-panel__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f4f0;
  border-bottom: 17px solid;
  padding: 2rem;
  position: relative; }
  .number-panel__content::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: height 300ms ease; }
  .number-panel__content .h1, .number-panel__content .hero__title, .number-panel__content .hero__title--sized, .number-panel__content .hero__title--form,
  .number-panel__content .h6 {
    position: relative;
    transition: color 300ms ease; }
  .number-panel__content .h1, .number-panel__content .hero__title, .number-panel__content .hero__title--sized, .number-panel__content .hero__title--form {
    margin-bottom: 1rem; }
  .number-panel__content .h6 {
    margin-top: 1rem; }
  .number-panel__content:hover::before {
    height: 100%; }
  .number-panel__content:hover .h1, .number-panel__content:hover .hero__title, .number-panel__content:hover .hero__title--sized, .number-panel__content:hover .hero__title--form, .number-panel__content:hover .h6 {
    color: #fff; }

.number-panel__purple {
  border-color: #8c2287; }
  .number-panel__purple::before {
    background-color: #8c2287; }
  .number-panel__purple .h1, .number-panel__purple .hero__title, .number-panel__purple .hero__title--sized, .number-panel__purple .hero__title--form {
    color: #8c2287; }

.number-panel__blue {
  border-color: #003478; }
  .number-panel__blue::before {
    background-color: #003478; }
  .number-panel__blue .h1, .number-panel__blue .hero__title, .number-panel__blue .hero__title--sized, .number-panel__blue .hero__title--form {
    color: #003478; }

.number-panel__teal {
  border-color: #0b7886; }
  .number-panel__teal::before {
    background-color: #0b7886; }
  .number-panel__teal .h1, .number-panel__teal .hero__title, .number-panel__teal .hero__title--sized, .number-panel__teal .hero__title--form {
    color: #0b7886; }

.number-panel__link {
  background-color: #003478;
  border-bottom: 0;
  color: #fff;
  text-align: center;
  text-decoration: underline; }
  .number-panel__link:focus {
    outline-offset: 0.125rem; }

.number-panel__icon {
  width: 50px;
  margin-left: auto;
  margin-right: auto;
  transition: transform 300ms ease; }
  a:hover .number-panel__icon {
    transform: translateX(1rem); }

.number-panel__text {
  color: #fff; }

.number-panel__no-animate .number-panel__content::before {
  content: none; }

.number-panel__no-animate .number-panel__content .h6 {
  color: #000; }

.number-panel__no-animate .number-panel__purple:hover .h1, .number-panel__no-animate .number-panel__purple:hover .hero__title, .number-panel__no-animate .number-panel__purple:hover .hero__title--sized, .number-panel__no-animate .number-panel__purple:hover .hero__title--form {
  color: #8c2287; }

.number-panel__no-animate .number-panel__teal:hover .h1, .number-panel__no-animate .number-panel__teal:hover .hero__title, .number-panel__no-animate .number-panel__teal:hover .hero__title--sized, .number-panel__no-animate .number-panel__teal:hover .hero__title--form {
  color: #0b7886; }

@media (min-width: 1280px) {
  .number-panel__image--tablet {
    display: none; } }

.overlay {
  position: relative; }

.overlay--alt {
  padding-top: 10rem;
  color: #fff; }
  @media (min-width: 48em) {
    .overlay--alt {
      padding-top: 5rem;
      padding-bottom: 5rem; } }
  .overlay--alt::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.68); }
  .overlay--alt .h1, .overlay--alt .hero__title, .overlay--alt .hero__title--sized, .overlay--alt .hero__title--form, .overlay--alt .h2, .overlay--alt .feature-panel__title, .overlay--alt p {
    color: #fff;
    text-shadow: 0.1rem 0.1rem 0.1rem #000; }
    .overlay--alt .h1.overlay__text--alt, .overlay--alt .overlay__text--alt.hero__title, .overlay--alt .overlay__text--alt.hero__title--sized, .overlay--alt .overlay__text--alt.hero__title--form, .overlay--alt .h2.overlay__text--alt, .overlay--alt .overlay__text--alt.feature-panel__title, .overlay--alt p.overlay__text--alt {
      margin-top: 10.5%; }
  .overlay--alt .button--white {
    text-shadow: 0.1rem 0.1rem 0.1rem #000; }
    .overlay--alt .button--white:hover, .overlay--alt .button--white:focus {
      text-shadow: none; }

.overlay__button {
  display: inline-block;
  padding: 1rem 1.875rem .75rem;
  border: 0.0625rem solid #fff;
  border-radius: 0.1875rem;
  color: #000;
  font-family: "Hind", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.1667;
  text-transform: uppercase;
  background-color: #fff;
  text-align: center; }
  .overlay__button:hover, .overlay__button:focus {
    background-color: #fff;
    color: #000;
    text-decoration: none;
    outline-offset: 0.125rem; }

@media (min-width: 48em) {
  .overlay-tall {
    height: 800px; }
    .overlay-tall .module, .overlay-tall .wrap {
      height: inherit; }
    .overlay-tall .module {
      padding: 0; } }

@media (min-width: 48em) {
  .overlay-push {
    padding-top: 10rem; } }

.overlay--dark::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.68); }

.overlay--dark .overlay__image {
  z-index: -1; }

.overlay--video {
  width: 100%;
  height: auto;
  overflow: hidden; }
  @media (min-width: 48em) {
    .overlay--video {
      height: 600px; } }

.overlay__image, .overlay__video, .overlay__video__media {
  width: 100%; }

.overlay--alt .overlay__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover'; }

.overlay__video {
  width: 100%;
  max-height: 768px; }

.overlay__wrap > :first-child {
  margin-top: 0; }

.overlay__wrap > :last-child {
  margin-bottom: 0; }

.overlay__container {
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: #f5f4f0;
  text-align: center; }
  .overlay__container > :first-child {
    margin-top: 0; }
  .overlay__container > :last-child {
    margin-bottom: 0; }
  @media (min-width: 48em) {
    .overlay__container {
      position: absolute;
      top: 50%;
      width: 100%;
      max-width: calc(100% - 4rem);
      margin: 0;
      padding: 0;
      transform: translateY(-50%);
      background: transparent;
      color: #fff; } }
  @media (min-width: 90em) {
    .overlay__container {
      width: 84rem;
      max-width: calc(100% - 6rem); } }

@media (min-width: 48em) {
  .overlay__title {
    color: #fff;
    text-shadow: 0.1rem 0.1rem 0.1rem #000; }
    .overlay--dark .overlay__title {
      text-shadow: none; } }

.overlay__text {
  font-family: "Hind", sans-serif;
  font-size: 1.25rem; }
  @media (min-width: 48em) {
    .overlay__text {
      font-size: 1.75rem;
      text-shadow: 0.1rem 0.1rem 0.1rem #000; }
      .overlay--dark .overlay__text {
        text-shadow: none; } }

.overlay__num {
  font-family: "Hind", sans-serif;
  font-weight: 600;
  font-size: 4.5rem;
  white-space: nowrap;
  border-bottom: 0.125rem solid #fff;
  display: inline-block;
  text-shadow: 0.1rem 0.1rem 0.1rem #000;
  box-shadow: 0 0.1rem 0 0 #000; }
  @media (min-width: 48em) {
    .overlay__num {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 0;
      border-right: 0.125rem solid #fff;
      box-shadow: 0.1rem 0 0 #000; } }
  @media (min-width: 64em) {
    .overlay__num {
      font-size: 7rem; } }
  @media (min-width: 80em) {
    .overlay__num {
      font-size: 11rem; } }
  .overlay__num span {
    display: block; }

.overlay__heading {
  position: absolute;
  top: -5rem; }
  @media (min-width: 48em) {
    .overlay__heading {
      position: static; } }

.overlay__link {
  display: block;
  width: 3.125rem;
  height: 3.125rem;
  background-color: #fff;
  border-radius: 50%;
  color: #000;
  margin-bottom: 2rem; }
  @media (min-width: 48em) {
    .overlay__link {
      width: 4.6875rem;
      height: 4.6875rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 0; } }

.overlay__content > :first-child {
  margin-top: 0; }

.overlay__content > :last-child {
  margin-bottom: 0; }

@media (min-width: 48em) {
  .overlay__content {
    position: absolute;
    bottom: 8rem;
    max-width: 40%; } }

.router-grid {
  margin: 0; }

.router-grid__title {
  color: #000 !important;
  font-family: "merriweather", serif;
  margin-bottom: 3rem;
  margin-top: 0; }
  @media (min-width: 64.0625em) {
    .router-grid__title {
      margin-left: 4rem;
      max-width: 690px; } }

.router-grid__items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; }

.router-grid__item {
  position: relative;
  display: flex;
  margin-bottom: 0;
  width: 100%; }
  @media (min-width: 48em) {
    .router-grid__item {
      width: 50%; } }
  @media (min-width: 73.5em) {
    .router-grid__item {
      width: 25%; } }

.router-grid__link {
  position: relative;
  width: 100%; }
  @media (min-width: 64.0625em) {
    .router-grid__link::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.2) 45.39%);
      opacity: 1;
      z-index: 1;
      transition: opacity 300ms ease; }
    .router-grid__link:hover::before, .router-grid__link:focus::before {
      opacity: 0; }
    .router-grid__link:hover .button, .router-grid__link:hover .form .form_action button, .form .form_action .router-grid__link:hover button, .router-grid__link:hover .module__container .form_action button, .module__container .form_action .router-grid__link:hover button, .router-grid__link:hover .hero__form .form_action button, .hero__form .form_action .router-grid__link:hover button, .router-grid__link:focus .button, .router-grid__link:focus .form .form_action button, .form .form_action .router-grid__link:focus button, .router-grid__link:focus .module__container .form_action button, .module__container .form_action .router-grid__link:focus button, .router-grid__link:focus .hero__form .form_action button, .hero__form .form_action .router-grid__link:focus button {
      background-color: transparent; }
    .router-grid__link:hover .router-grid__content::before, .router-grid__link:focus .router-grid__content::before {
      height: calc(var(--height) - 17px); }
    .router-grid__link:focus {
      outline-offset: 0.5rem;
      z-index: 1; } }
  .router-grid__link img {
    width: 100%;
    object-fit: cover; }

.router-grid__content {
  display: flex;
  padding: 28px 44px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2; }
  .router-grid__content::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    transition: height 300ms ease;
    z-index: -1; }
  .router-grid__content p {
    margin: 0;
    color: #fff; }
  .router-grid__content .button, .router-grid__content .form .form_action button, .form .form_action .router-grid__content button, .router-grid__content .module__container .form_action button, .module__container .form_action .router-grid__content button, .router-grid__content .hero__form .form_action button, .hero__form .form_action .router-grid__content button {
    text-decoration: underline;
    border: none;
    background: none;
    padding: 0; }
    @media (min-width: 64.0625em) {
      .router-grid__content .button, .router-grid__content .form .form_action button, .form .form_action .router-grid__content button, .router-grid__content .module__container .form_action button, .module__container .form_action .router-grid__content button, .router-grid__content .hero__form .form_action button, .hero__form .form_action .router-grid__content button {
        border: 2px solid;
        text-decoration: none;
        padding: 1rem 1.875rem .75rem; } }

.router-grid__headline {
  color: #fff;
  margin: 0; }

.router-grid__content--teal {
  background-color: #0b7886;
  border-bottom: 17px solid #0b7886; }
  @media (min-width: 64.0625em) {
    .router-grid__content--teal {
      background-color: transparent; }
      .router-grid__content--teal::before {
        background-color: #0b7886; } }

.router-grid__content--blue {
  background-color: #003478;
  border-bottom: 17px solid #003478; }
  @media (min-width: 64.0625em) {
    .router-grid__content--blue {
      background-color: transparent; }
      .router-grid__content--blue::before {
        background-color: #003478; } }

.router-grid__content--purple {
  background-color: #8c2287;
  border-bottom: 17px solid #8c2287; }
  @media (min-width: 64.0625em) {
    .router-grid__content--purple {
      background-color: transparent; }
      .router-grid__content--purple::before {
        background-color: #8c2287; } }

.router-grid__content--gray {
  background-color: #58595b;
  border-bottom: 17px solid #58595b; }
  @media (min-width: 64.0625em) {
    .router-grid__content--gray {
      background-color: transparent; }
      .router-grid__content--gray::before {
        background-color: #58595b; } }

.nav-fixed.nav-search-active .search--nav {
  position: static;
  left: auto;
  visibility: visible; }

.search {
  position: absolute;
  left: -999rem;
  padding: 1rem 0;
  background: #f5f4f0;
  color: #000;
  visibility: hidden; }
  @media (min-width: 48em) {
    .search {
      padding: 2rem 0; } }
  .search-active .search {
    position: static;
    left: auto;
    visibility: visible; }

.search-active .search--nav {
  position: absolute;
  left: -999rem;
  visibility: hidden; }

.search__form {
  position: relative; }
  .search__form::before, .search__form::after {
    display: table;
    width: 0;
    content: ''; }
  .search__form::after {
    clear: both; }
  .search__form legend {
    position: absolute;
    left: -999rem; }

.search__input {
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0.75rem 3rem 0.75rem 0.75rem;
  border: 0.0625rem solid #000;
  background: #fff;
  font-family: "Hind", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal; }

.search__button {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  padding: 0.6875rem;
  overflow: hidden; }

.search__option {
  margin-top: 1rem;
  margin-right: 2.5rem;
  float: left;
  line-height: 1; }

.search__radio {
  margin-right: 0.25rem;
  vertical-align: middle; }

.search__label {
  font-family: "Hind", sans-serif;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600; }

.secnav {
  background-color: #f5f4f0;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }
  @media (min-width: 64.0625em) {
    .secnav {
      padding-top: 5rem;
      padding-bottom: 5rem; } }

.secnav__container {
  max-width: 90rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem; }
  .secnav__container::before, .secnav__container::after {
    display: table;
    width: 0;
    content: ''; }
  .secnav__container::after {
    clear: both; }
  @media (min-width: 48em) {
    .secnav__container {
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 64.0625em) {
    .secnav__container {
      padding-right: 3rem;
      padding-left: 3rem; } }

.secnav__list {
  display: none;
  margin-top: -0.0625rem;
  padding: 0 1.5rem 1rem 1.5rem;
  border: 0.0625rem solid #000;
  border-radius: 0.1875rem; }
  @media (min-width: 64.0625em) {
    .secnav__list {
      display: block;
      border: 0;
      padding: 0; } }
  .secnav__list ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    @media (min-width: 64.0625em) {
      .secnav__list ul {
        margin: -1rem 0 0;
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.5rem; } }
    .secnav__list ul:last-child {
      margin-bottom: inherit; }
  @media (min-width: 64.0625em) {
    .secnav__list li {
      margin-bottom: 0;
      padding: 1rem 0;
      border-bottom: .0625rem solid;
      width: calc(25% - 1.5rem);
      margin-left: 1.5rem; } }
  .secnav__list a {
    border-bottom: 0 !important;
    font-weight: 600;
    font-family: "Hind", sans-serif;
    font-size: 0.9375rem;
    color: #000; }
    .secnav__list a:hover {
      color: #000 !important;
      text-decoration: underline !important; }
  .secnav--active .secnav__list {
    display: block; }

.secnav__toggle {
  padding: 1rem 1.5rem;
  width: 100%;
  text-align: left;
  font-family: "Hind", sans-serif;
  font-weight: 600;
  font-size: 0.9375rem; }
  .secnav__toggle:hover, .secnav__toggle:focus {
    border-color: inherit;
    background-color: transparent;
    color: #000; }
  @media (min-width: 64.0625em) {
    .secnav__toggle {
      display: none; } }
  .secnav--active .secnav__toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: transparent;
    background: #f5f4f0; }

.secnav__text {
  display: inline-block;
  width: calc(100% - 2rem); }

.secnav__icon {
  display: inline-block;
  width: 1rem;
  margin-left: 0.5rem;
  color: #000; }
  @media (min-width: 64.0625em) {
    .secnav__icon {
      display: none; } }
  .secnav--active .secnav__icon {
    transform: rotate(-180deg); }

@media (min-width: 64.0625em) {
  .splash {
    display: flex; } }

.splash__item {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  min-height: 300px;
  border-bottom: 17px solid;
  overflow: hidden; }
  .splash__item::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    z-index: 0; }
  @media (min-width: 64.0625em) {
    .splash__item {
      width: 33.333%;
      height: 700px; } }
  .splash__item:nth-child(1) {
    border-color: #0b7886; }
  .splash__item:nth-child(2) {
    border-color: #003478; }
  .splash__item:nth-child(3) {
    border-color: #8c2287; }
  .splash__item:hover img {
    transform: scale(1.1); }

.splash__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 300ms ease;
  z-index: -1;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover'; }

.splash__content {
  position: relative;
  padding: 2.8125rem; }
  .splash__content .button, .splash__content .form .form_action button, .form .form_action .splash__content button, .splash__content .module__container .form_action button, .module__container .form_action .splash__content button, .splash__content .hero__form .form_action button, .hero__form .form_action .splash__content button {
    margin-top: 2rem;
    text-shadow: 0.1rem 0.1rem 0.1rem #000;
    box-shadow: 0.375rem 0.375rem 0.375rem rgba(0, 0, 0, 0.125); }
  .splash__content .button:focus, .splash__content .form .form_action button:focus, .form .form_action .splash__content button:focus, .splash__content .module__container .form_action button:focus, .module__container .form_action .splash__content button:focus, .splash__content .hero__form .form_action button:focus, .hero__form .form_action .splash__content button:focus {
    text-shadow: none; }
  .splash__content .button:hover, .splash__content .form .form_action button:hover, .form .form_action .splash__content button:hover, .splash__content .module__container .form_action button:hover, .module__container .form_action .splash__content button:hover, .splash__content .hero__form .form_action button:hover, .hero__form .form_action .splash__content button:hover {
    margin-top: 2rem;
    text-shadow: none;
    box-shadow: 0.375rem 0.375rem 0.375rem rgba(0, 0, 0, 0.125); }

.splash__headline {
  margin: 0;
  color: #fff;
  text-shadow: 0.1rem 0.1rem 0.1rem #000; }

@media (min-width: 64.0625em) {
  .statement {
    display: flex; } }

.statement__left > :first-child,
.statement__right > :first-child {
  margin-top: 0; }

.statement__left > :last-child,
.statement__right > :last-child {
  margin-bottom: 0; }

@media (min-width: 64.0625em) {
  .statement__left {
    width: 45%; } }

.statement__right {
  margin-top: 1.5rem;
  border-top: 0.0625rem solid #2f2f2f;
  padding-top: 1.5rem; }
  @media (min-width: 64.0625em) {
    .statement__right {
      margin-top: 0;
      width: 55%;
      border-top: 0;
      border-left: 0.0625rem solid #2f2f2f;
      padding-left: 2rem;
      padding-right: 4rem;
      padding-top: 0; } }

.statement__right--alt {
  border: 0; }

.stats {
  position: relative;
  margin: 0;
  padding-block: 6rem;
  padding-bottom: 0; }
  @media (min-width: 64.0625em) {
    .stats {
      padding-block: 6rem; } }
  .stats::before {
    position: absolute;
    content: '';
    inset: 0;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 1; }
  .stats::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 30%;
    width: 100%;
    background-color: #fff;
    z-index: 1; }
    @media (min-width: 64.0625em) {
      .stats::after {
        content: none; } }

.stats__image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.stats__header {
  position: relative;
  color: #fff;
  z-index: 1;
  display: grid;
  align-items: center; }
  @media (min-width: 64.0625em) {
    .stats__header {
      grid-template-columns: repeat(2, 50%); } }

.stats__title {
  color: #fff;
  font-family: "merriweather", serif;
  margin-top: 0;
  text-shadow: .1rem .1rem .1rem #000; }

.stats__grid {
  position: relative;
  z-index: 2;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 100%;
  color: #fff; }
  @media (min-width: 64.0625em) {
    .stats__grid {
      margin-top: 4rem;
      grid-template-columns: repeat(3, 1fr); } }

.stats__item {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  color: #fff; }
  @media (min-width: 64.0625em) {
    .stats__item {
      padding: 2rem; }
      .stats__item::before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        height: 0;
        transition: height 300ms ease;
        z-index: -1; } }
  .stats__item:hover {
    text-decoration: none; }
    .stats__item:hover::before {
      height: calc(var(--height, auto) - 16px); }
    .stats__item:hover .button, .stats__item:hover .form .form_action button, .form .form_action .stats__item:hover button, .stats__item:hover .module__container .form_action button, .module__container .form_action .stats__item:hover button, .stats__item:hover .hero__form .form_action button, .hero__form .form_action .stats__item:hover button {
      background-color: transparent; }
      @media (min-width: 64.0625em) {
        .stats__item:hover .button, .stats__item:hover .form .form_action button, .form .form_action .stats__item:hover button, .stats__item:hover .module__container .form_action button, .module__container .form_action .stats__item:hover button, .stats__item:hover .hero__form .form_action button, .hero__form .form_action .stats__item:hover button {
          border: 2px solid #fff; } }
  .stats__item .button, .stats__item .form .form_action button, .form .form_action .stats__item button, .stats__item .module__container .form_action button, .module__container .form_action .stats__item button, .stats__item .hero__form .form_action button, .hero__form .form_action .stats__item button {
    text-decoration: underline;
    color: #fff;
    border: none;
    background: none;
    margin: auto 0 0;
    padding: 0;
    text-shadow: none; }
    @media (min-width: 64.0625em) {
      .stats__item .button, .stats__item .form .form_action button, .form .form_action .stats__item button, .stats__item .module__container .form_action button, .module__container .form_action .stats__item button, .stats__item .hero__form .form_action button, .hero__form .form_action .stats__item button {
        padding: 1rem 1.875rem .75rem;
        border-width: 2px;
        border-style: solid;
        text-decoration: none; } }
    .stats__item .button:hover, .stats__item .form .form_action button:hover, .form .form_action .stats__item button:hover, .stats__item .module__container .form_action button:hover, .module__container .form_action .stats__item button:hover, .stats__item .hero__form .form_action button:hover, .hero__form .form_action .stats__item button:hover {
      margin-bottom: 0;
      background-color: transparent;
      border-color: #fff; }

.stats__item--purple {
  background-color: #8c2287;
  border: 16px solid #8c2287; }
  @media (min-width: 64.0625em) {
    .stats__item--purple {
      background-color: transparent; }
      .stats__item--purple::before {
        background-color: #8c2287; } }
  .stats__item--purple .button, .stats__item--purple .form .form_action button, .form .form_action .stats__item--purple button, .stats__item--purple .module__container .form_action button, .module__container .form_action .stats__item--purple button, .stats__item--purple .hero__form .form_action button, .hero__form .form_action .stats__item--purple button {
    background-color: #8c2287;
    border-color: #8c2287; }

.stats__item--teal {
  background-color: #0b7886;
  border: 16px solid #0b7886; }
  @media (min-width: 64.0625em) {
    .stats__item--teal {
      background-color: transparent; }
      .stats__item--teal::before {
        background-color: #0b7886; } }
  .stats__item--teal .button, .stats__item--teal .form .form_action button, .form .form_action .stats__item--teal button, .stats__item--teal .module__container .form_action button, .module__container .form_action .stats__item--teal button, .stats__item--teal .hero__form .form_action button, .hero__form .form_action .stats__item--teal button {
    background-color: #0b7886;
    border-color: #0b7886; }

.stats__item--blue {
  background-color: #003478;
  border: 16px solid #003478; }
  @media (min-width: 64.0625em) {
    .stats__item--blue {
      background-color: transparent; }
      .stats__item--blue::before {
        background-color: #003478; } }
  .stats__item--blue .button, .stats__item--blue .form .form_action button, .form .form_action .stats__item--blue button, .stats__item--blue .module__container .form_action button, .module__container .form_action .stats__item--blue button, .stats__item--blue .hero__form .form_action button, .hero__form .form_action .stats__item--blue button {
    background-color: #003478;
    border-color: #003478; }

.stats__value {
  display: flex;
  font-size: 3.5rem; }
  @media (min-width: 64.0625em) {
    .stats__value {
      font-size: 4.5rem; } }

.stats__small {
  font-size: 50%; }

.stats__name {
  display: block;
  font-size: 1.25rem;
  margin-bottom: 3rem; }
  @media (min-width: 64.0625em) {
    .stats__name {
      font-size: 1.375rem; } }

.story-slider-container,
.story-shift-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin: -0.375rem;
  padding: 0.375rem;
  overflow: hidden; }
  .story-slider-container:first-child, .story-slider-container:last-child,
  .story-shift-container:first-child,
  .story-shift-container:last-child {
    margin: -0.375rem; }

.story-slider,
.story-shift {
  margin: 0;
  padding: 0;
  list-style: none; }
  .story-slider .flickity-viewport,
  .story-shift .flickity-viewport {
    overflow: visible; }

.story {
  width: 100%;
  margin: 0 0.625rem;
  background: #fff;
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.125); }
  .story::before, .story::after {
    display: table;
    width: 0;
    content: ''; }
  .story::after {
    clear: both; }
  @media (min-width: 48em) {
    .story {
      display: flex; } }

@media (min-width: 48em) {
  .story__media {
    position: relative;
    width: 50%;
    float: left; } }

@media (min-width: 64.0625em) {
  .story__media {
    width: 66.6667%; } }

@media (min-width: 48em) {
  .story__media::before {
    display: block;
    padding-bottom: 66.6667%;
    content: ''; } }

@media (min-width: 48em) {
  .story__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover'; } }

.story__text {
  position: relative;
  padding: 2rem 1rem; }
  .story__text > :first-child {
    margin-top: 0; }
  .story__text > :last-child {
    margin-bottom: 0; }
  @media (min-width: 48em) {
    .story__text {
      width: 50%;
      padding: 8.5rem 2.5rem 5rem;
      float: right; } }
  @media (min-width: 64.0625em) {
    .story__text {
      width: 33.3333%; } }

.story__type {
  position: absolute;
  top: 0;
  left: 1rem;
  padding: 0.25rem 0.5rem;
  background: #003478;
  color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 0.75rem;
  font-weight: 500; }
  @media (min-width: 48em) {
    .story__type {
      left: 2.5rem; } }
  @media (min-width: 48em) {
    .story__type + * {
      margin-top: 0; } }

.story-shift-container:first-child {
  margin-top: 2rem; }

@media (min-width: 64.0625em) {
  .story-shift-container {
    position: relative;
    margin: 0;
    padding: 0;
    overflow: visible; }
    .story-shift-container:first-child, .story-shift-container:last-child {
      margin: 2rem 3rem; } }

.story-shift-container .flickity-page-dots {
  position: static;
  margin-top: 3rem; }

.story-shift-container .flickity-prev-next-button {
  top: auto;
  bottom: 0; }
  .story-shift-container .flickity-prev-next-button .arrow {
    fill: #003478; }

.story-shift-viewport {
  margin: -0.375rem;
  padding: 0.375rem; }

.story-shift-nav {
  display: none; }
  @media (min-width: 64.0625em) {
    .story-shift-nav {
      display: block;
      position: absolute;
      top: 50%;
      width: 4.125rem;
      height: 4.125rem;
      background-color: #003478;
      transform: translateY(-50%);
      color: #fff; } }
  .online .story-shift-nav {
    border-radius: 50%;
    width: 2.563rem;
    height: 2.563rem;
    padding: 0;
    background-color: #8c2287;
    border: 1px solid #fff; }
  .story-shift-nav svg {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%; }
  .story-shift-nav:hover, .story-shift-nav:focus {
    background-color: #0b7886; }

.story-shift-nav--prev {
  right: calc(100% + 1rem); }

.story-shift-nav--next {
  left: calc(100% + 1rem); }

.story-shift-nav--disabled {
  visibility: hidden; }

.story-shift__item {
  width: 100%;
  margin: 0 0.625rem; }
  @media (min-width: 64.0625em) {
    .story-shift__item {
      display: inline-block;
      width: auto;
      margin: 0 1.25rem 0 0;
      font-size: 1.0625rem;
      white-space: normal;
      vertical-align: top;
      word-wrap: break-word; }
      .story-shift__item:hover .story {
        box-shadow: 0 19px 12px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(3, 3, 3, 0.22); } }
  .story-shift__item .story {
    margin: 0; }
    @media (min-width: 64.0625em) {
      .story-shift__item .story {
        height: 100%;
        transition: box-shadow 300ms ease; } }

@media (min-width: 64.0625em) {
  .story-shift {
    transition: transform ease 600ms;
    font-size: 0;
    white-space: nowrap;
    word-wrap: normal;
    display: flex; }
    .story-shift .story__media {
      width: 0;
      min-height: 21.25rem;
      transition: width ease 600ms, opacity ease 600ms;
      opacity: 0;
      overflow: hidden; }
    .story-shift .story__image {
      width: 31.875rem;
      max-width: none; }
    .story-shift .story__text {
      width: 21rem;
      padding: 5rem 2.5rem 5rem; } }

.story-shift::after {
  display: none;
  content: 'flickity'; }
  @media (min-width: 64.0625em) {
    .story-shift::after {
      content: ''; } }

.story-shift.flickity-enabled {
  transform: none !important;
  padding-bottom: 3rem; }

@media (min-width: 64.0625em) {
  .story-shift__item--active .story__media {
    width: 31.875rem;
    opacity: 1; } }

.subnav {
  margin-top: 0;
  margin-bottom: 2.5rem;
  display: none;
  padding: 1rem 0 0;
  border-top: 0.25rem solid #003478;
  font-family: "Hind", sans-serif;
  font-size: 1.1rem;
  line-height: 1.25;
  list-style: none;
  font-weight: 600; }
  @media (min-width: 64.0625em) {
    .subnav {
      display: block; } }

.subnav--sub {
  position: relative;
  margin: 1rem 0 0;
  padding: 0;
  border-top: 0;
  font-weight: 400; }
  .subnav--sub::before {
    position: absolute;
    width: 0.25rem;
    height: 100%;
    background: #f5f5f5;
    content: ''; }

.subnav__item {
  margin: 1rem 0 0; }

.subnav__link {
  display: inline-block;
  position: relative;
  font-weight: 600; }
  .subnav__item--current > .subnav__link {
    color: #000;
    font-weight: 600; }
    .subnav__item--current > .subnav__link:hover {
      text-decoration: none; }

.subnav__link--sub,
.subnav__link--sub-sub {
  padding-left: 1rem;
  font-weight: 400; }
  .subnav__item--current > .subnav__link--sub::before, .subnav__item--current >
  .subnav__link--sub-sub::before {
    position: absolute;
    left: 0;
    width: 0.25rem;
    height: 100%;
    background: #bcbcbc;
    content: ''; }
  .subnav__item--current-alt > .subnav__link--sub::before, .subnav__item--current-alt >
  .subnav__link--sub-sub::before {
    position: absolute;
    left: 0;
    width: 0.25rem;
    height: 100%;
    background: #bcbcbc;
    content: ''; }

.subnav__link--sub-sub {
  padding-left: 1.75rem; }

.subnav__sub-toggle {
  width: 1.125rem;
  float: right; }

.subnav__sub-toggle__icon--inactive {
  display: block; }
  .subnav__item--active > .subnav__sub-toggle > span > .subnav__sub-toggle__icon--inactive {
    display: none; }

.subnav__sub-toggle__icon--active {
  display: none; }
  .subnav__item--active > .subnav__sub-toggle > span > .subnav__sub-toggle__icon--active {
    display: block; }

.table {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  position: relative; }
  .table::after {
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20100%20100%22%3E%3Cpath%20d%3D%22M39.7%2012.1c.6%200%201.2.2%201.7.7L77%2048.4c.9.9.9%202.4%200%203.4L41.4%2087.4c-.9.9-2.4.9-3.4%200s-.9-2.4%200-3.4l33.9-33.9L38%2016.2c-.9-.9-.9-2.4%200-3.4.5-.4%201.1-.7%201.7-.7z%22%2F%3E%3C%2Fsvg%3E);
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    transform: translateY(-50%);
    background-color: #58595b;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2.5rem 2.5rem;
    content: '';
    pointer-events: none; }
    @media (min-width: 48em) {
      .table::after {
        display: none; } }

.table--scrolled::after {
  display: none; }

.table--fixed::after {
  display: none; }

.table__scroll {
  overflow: auto; }

table {
  width: 100%;
  table-layout: fixed; }
  .table__scroll table {
    min-width: 48rem; }
    @media (min-width: 48em) {
      .table__scroll table {
        min-width: 0; } }

tr:nth-child(even) {
  background: #f5f4f0; }

th,
td {
  padding: 0.75rem 1.25rem;
  border-left: 0.125rem solid #fff;
  vertical-align: top; }
  th > :first-child,
  td > :first-child {
    margin-top: 0; }
  th > :last-child,
  td > :last-child {
    margin-bottom: 0; }
  th:first-child,
  td:first-child {
    border-left: 0; }

td a:not([class]) {
  font-weight: 600;
  border-bottom: 1px solid #003478;
  padding-bottom: .125em; }
  td a:not([class]):hover {
    border-color: #0b7886;
    text-decoration: none;
    color: #0b7886; }

th {
  background: #003478;
  color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 1.125rem;
  text-align: left;
  font-weight: 600; }

@media (min-width: 73.5em) {
  .tabs .tabs-nav {
    display: flex;
    flex-wrap: nowrap;
    margin: 2rem 0 0 0; } }

.tabs-nav {
  display: none; }
  @media (min-width: 73.5em) {
    .tabs-nav {
      display: block; } }

.tabs__mobile__toggle {
  position: relative;
  padding: 1.25rem;
  font-family: "Hind", sans-serif;
  background-color: #003478;
  color: #fff;
  font-size: 1.0625rem;
  line-height: 1.7059;
  font-weight: 600;
  text-align: left;
  margin-top: 1.25rem;
  width: 100%; }
  [data-whatinput='keyboard'] .tabs__mobile__toggle:hover, [data-whatinput='keyboard'] .tabs__mobile__toggle:focus, [data-whatinput='keyboard'] .tabs__mobile__toggle:active {
    outline: 0.125rem solid #4d90fe;
    outline-offset: -0.125rem;
    outline-offset: 0.125rem; }

.tabs__mobile__toggle::after {
  content: none !important; }

.tabs__toggle {
  position: relative;
  padding: 1.25rem;
  font-family: "Hind", sans-serif;
  background-color: #f5f4f0;
  color: #000;
  font-size: 1.0625rem;
  line-height: 1.7059;
  font-weight: 600;
  text-align: left;
  margin-top: 1.25rem;
  width: 100%; }
  @media (min-width: 73.5em) {
    .tabs__toggle {
      text-align: center;
      line-height: 1;
      flex-grow: 1;
      order: -1;
      width: auto;
      margin: 0 0 0 0.25rem; } }
  @media (min-width: 73.5em) {
    .tabs__toggle:first-child {
      margin: 0; } }
  @media (min-width: 73.5em) {
    .tabs__toggle:hover, .tabs__toggle:focus, .tabs__toggle:active {
      background-color: #003478;
      color: #fff; } }
  [data-whatinput='keyboard'] .tabs__toggle:hover, [data-whatinput='keyboard'] .tabs__toggle:focus, [data-whatinput='keyboard'] .tabs__toggle:active {
    outline: 0.125rem solid #4d90fe;
    outline-offset: -0.125rem;
    outline-offset: 0.125rem; }

.tabs__icon {
  width: 1.8125rem;
  margin-left: 1.25rem;
  padding: 0.125rem;
  float: right; }

.tabs__icon--inactive {
  display: block; }
  .tabs__toggle--active .tabs__icon--inactive {
    display: none; }

.tabs__icon--active {
  display: none; }
  .tabs__toggle--active .tabs__icon--active {
    display: block; }

@media (min-width: 73.5em) {
  .tabs__icon--active,
  .tabs__icon--inactive,
  .tabs__toggle--active .tabs__icon--active,
  .tabs__mobile__toggle {
    display: none; } }

.tabs__toggle--active {
  background-color: #003478;
  color: #fff; }

.tabs__toggle--active::after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -1.25rem;
  border-top: 1.25rem solid #003478;
  border-right: 1.25rem solid transparent;
  border-left: 1.25rem solid transparent;
  content: ""; }

.tabs__content__item {
  margin-top: 0.125rem;
  visibility: hidden;
  overflow: hidden;
  width: 100%;
  padding: 1.25rem;
  background-color: #f5f4f0; }
  .tabs__content__item > :first-child {
    margin-top: 0; }
  .tabs__content__item > :last-child {
    margin-bottom: 0; }
  @media (min-width: 73.5em) {
    .tabs__content__item {
      display: none;
      visibility: visible; } }
  .tabs__content__item.mobile__tabs__content__item--active {
    visibility: visible; }
  @media (min-width: 73.5em) {
    .tabs__content__item.tabs__content__item--active {
      display: block; } }
  .tabs__content__item a:not([class]) {
    font-weight: 600;
    border-bottom: 1px solid #003478;
    padding-bottom: .125em; }
    .tabs__content__item a:not([class]):hover {
      border-color: #0b7886;
      text-decoration: none;
      color: #0b7886; }
  @media (min-width: 48em) {
    .tabs__content__item {
      padding: 2rem; } }

.tabs__content__item:not(.mobile__tabs__content__item--active) {
  height: 0 !important;
  padding: 0;
  margin: 0; }
  @media (min-width: 73.5em) {
    .tabs__content__item:not(.mobile__tabs__content__item--active) {
      height: auto !important;
      padding: 2rem; } }

.testimonial__slider .module__container {
  width: 100%; }

.testimonial__slider .flickity-page-dots {
  margin-top: 3rem;
  position: static; }
  .testimonial__slider .flickity-page-dots .dot {
    color: #fff; }

.testimonial__slider .flickity-prev-next-button {
  top: auto;
  bottom: -3rem; }
  @media (min-width: 64.0625em) {
    .testimonial__slider .flickity-prev-next-button {
      top: 50%;
      bottom: auto; } }
  @media (min-width: 64.0625em) {
    .testimonial__slider .flickity-prev-next-button.previous {
      left: -3rem; } }
  @media (min-width: 64.0625em) {
    .testimonial__slider .flickity-prev-next-button.next {
      right: -3rem; } }

.testimonial__image {
  margin: 2rem auto;
  width: 6.25rem;
  height: 6.25rem; }

.testimonial__bottom {
  display: flex;
  align-items: center;
  justify-content: center; }
  .testimonial__bottom .testimonial__image {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .testimonial__bottom .p--large {
    margin-bottom: 0; }

@media (min-width: 48em) {
  .timeline-slider {
    margin: 0 3rem; } }

.timeline-slider .flickity-prev-next-button {
  top: 0;
  margin-top: calc(33.3333% + 3.0625rem);
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="0" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
  filter: drop-shadow(0 0.1rem 0.1rem #000);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#000,strength=0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .timeline-slider .flickity-prev-next-button {
      background: rgba(0, 0, 0, 0.54); } }
  @media (min-width: 48em) {
    .timeline-slider .flickity-prev-next-button {
      margin-top: calc(16.6667% - 0.3125rem);
      padding: 0;
      filter: none; } }
  @media screen and (min-width: 48em) and (-ms-high-contrast: active), (min-width: 48em) and (-ms-high-contrast: none) {
    .timeline-slider .flickity-prev-next-button {
      background: none; } }
  @media (min-width: 48em) {
      .timeline-slider .flickity-prev-next-button .arrow {
        fill: #bcbcbc; } }
  @media (min-width: 48em) {
    .timeline-slider .flickity-prev-next-button.previous {
      right: calc(100% + 2rem);
      left: auto; } }
  @media (min-width: 48em) {
    .timeline-slider .flickity-prev-next-button.next {
      right: auto;
      left: calc(100% + 2rem); } }

.timeline {
  width: 100%;
  margin: 0 0.625rem; }
  .timeline::before, .timeline::after {
    display: table;
    width: 0;
    content: ''; }
  .timeline::after {
    clear: both; }

.timeline__nav::before, .timeline__nav::after {
  display: table;
  width: 0;
  content: ''; }

.timeline__nav::after {
  clear: both; }

@media (min-width: 48em) {
  .timeline__nav {
    display: none; } }

.timeline__nav__link {
  width: 50%;
  padding: 0.625rem;
  float: left;
  background: #f5f4f0;
  font-family: "Hind", sans-serif;
  text-align: center; }
  .timeline__nav__link:hover {
    text-decoration: none; }

.timeline__nav__link--active {
  background: #003478;
  color: #fff; }

.timeline__pair {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none; }
  @media (min-width: 48em) {
    .timeline__pair {
      display: flex; } }
  .timeline__pair::before, .timeline__pair::after {
    position: absolute;
    bottom: 0;
    width: 6rem;
    height: 4rem;
    content: '';
    z-index: 1; }
  .timeline__pair::before {
    left: 0;
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.001)); }
  .timeline__pair::after {
    right: 0;
    background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0.001)); }

.timeline__item {
  position: absolute;
  left: -999rem;
  text-align: center;
  visibility: hidden; }
  @media (min-width: 48em) {
    .timeline__item {
      position: static;
      left: auto;
      display: flex;
      flex-direction: column;
      width: calc(50% - 0.625rem);
      float: left;
      visibility: visible; }
      .timeline__item:last-child {
        margin-left: auto;
        float: right; } }

.timeline__item--active {
  position: static;
  left: auto;
  visibility: visible; }

.timeline__text {
  width: 100%;
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
  padding: 0 1.25rem; }
  .timeline__text > :first-child {
    margin-top: 0; }
  .timeline__text > :last-child {
    margin-bottom: 0; }

.timeline__date {
  width: 100%; }
  @media (min-width: 48em) {
    .timeline__date {
      margin-top: auto;
      padding-bottom: 4.25rem; } }

.timeline__year {
  display: inline-block;
  position: relative;
  padding: 0.5rem 1rem;
  background: #58595b;
  color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 0.75rem; }
  @media (min-width: 48em) {
    .timeline__year {
      transition: background-color 300ms ease 900ms;
      background: #fff; } }
  .is-selected .timeline__year {
    background: #58595b; }
  @media (min-width: 48em) {
    .timeline__year::before {
      position: absolute;
      bottom: -2.5rem;
      left: 50%;
      width: 0.125rem;
      height: 0;
      transform: translateX(-50%);
      transition: height 300ms ease 600ms;
      background: #58595b;
      content: ''; }
      .is-selected .timeline__year::before {
        height: 2.5rem; }
    .timeline__year::after {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 1rem;
      height: 1rem;
      margin-top: 2rem;
      transform: translateX(-50%) scale(0.25);
      transition: transform 300ms ease 300ms, opacity 300ms ease 300ms;
      border-radius: 50%;
      background: #58595b;
      box-shadow: 0 0 0 1.125rem rgba(0, 0, 0, 0.125);
      content: '';
      opacity: 0; }
      .is-selected .timeline__year::after {
        transform: translateX(-50%) scale(1);
        opacity: 1; } }

@media (min-width: 48em) {
  .timeline__hashes {
    position: absolute;
    bottom: 1.6875rem;
    left: 0;
    width: 100%;
    height: 1rem;
    border-bottom: 0.125rem solid #f5f5f5;
    background-image: repeating-linear-gradient(to right, #f5f5f5 0, #f5f5f5 0.125rem, transparent 0.125rem, transparent 1.375rem);
    z-index: -1; } }

.title {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  text-align: center; }
  @media (min-width: 48em) {
    .title {
      display: flex;
      align-items: center;
      justify-content: center; }
      .title::before, .title::after {
        display: table;
        width: 0;
        content: ''; }
      .title::after {
        clear: both; } }
  @media (min-width: 64.0625em) {
    .title {
      margin-top: 5rem;
      margin-bottom: 5rem; } }

@media (min-width: 64.0625em) {
  .title + .story-slider-container,
  .title + .image-slider-container {
    margin-top: -2.5rem !important; } }

.title__logo {
  width: 3rem;
  margin: 0 auto 1.25rem; }
  @media (min-width: 48em) {
    .title__logo {
      width: 4rem;
      margin: 0 1.25rem 0 0;
      float: left; } }

.title__text {
  margin: 0; }
  @media (min-width: 48em) {
    .title__text {
      max-width: calc(100% - 5.25rem);
      margin: 0;
      float: left; } }
  .title__text a {
    color: inherit; }

.title__text--alt {
  text-align: center; }
  .title__text--alt h1 {
    margin: 0 0 .25rem 0; }

.topbar {
  display: none;
  padding: 0.625rem 0 0.5625rem;
  background: #003478;
  color: #fff; }
  @media (min-width: 64.0625em) {
    .topbar {
      display: block; } }

.topbar__list {
  margin: 0;
  padding: 0;
  float: right;
  list-style: none; }

.topbar__list__item {
  margin: 0 0 0 1.5rem;
  float: left; }

.topbar__list__link {
  display: block;
  position: relative;
  padding: 0.25rem 1.5rem;
  color: inherit;
  font-family: "Hind", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  border: 0.0625rem solid #fff;
  border-radius: 0.1875rem;
  margin-top: 0.35rem; }
  .topbar__list__link::before {
    display: none;
    position: absolute;
    bottom: 0.125rem;
    left: 0;
    width: 100%;
    height: 0.25rem;
    background: #fff;
    content: ''; }
  .topbar__list__link:hover, .topbar__list__link:focus {
    text-decoration: none;
    background-color: #fff;
    color: #003478; }
    [data-whatinput='keyboard'] .topbar__list__link:hover, [data-whatinput='keyboard'] .topbar__list__link:focus {
      outline-offset: 0.125rem; }

.topbar__list__link--search {
  display: block;
  width: 1.25rem;
  padding: 0.8125rem 0;
  overflow: hidden; }
  .topbar__list__link--search::before {
    background: #003478; }

.topbar__list__link__inactive {
  display: block; }
  .search-active .topbar__list__link__inactive {
    display: none; }

.topbar__list__link__active {
  display: none; }
  .search-active .topbar__list__link__active {
    display: block; }

.topbar__dropdown {
  position: relative;
  float: left;
  color: #fff;
  font-family: "Hind", sans-serif;
  z-index: 5; }

.topbar__dropdown__toggle {
  padding: 0.5625rem 0;
  float: left;
  color: inherit;
  font-size: 0.875rem;
  font-weight: 600; }
  .topbar__dropdown__toggle:hover {
    text-decoration: none; }
  .topbar__dropdown--active .topbar__dropdown__toggle {
    color: #003478; }

.topbar__dropdown__icon {
  width: 1.25rem;
  margin-left: 0.5rem;
  margin-top: 0.15rem;
  float: right; }
  .topbar__dropdown--active .topbar__dropdown__icon {
    transform: rotate(180deg); }

.topbar__dropdown__list {
  position: absolute;
  left: -999rem;
  position: absolute;
  top: -0.625rem;
  margin: 0;
  padding: 3.75rem 1rem 1.25rem;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
  background: #f5f5f5;
  color: #003478;
  list-style: none;
  box-shadow: 0 0.375rem 0.375rem rgba(0, 0, 0, 0.125);
  visibility: hidden;
  z-index: -1; }
  .topbar__dropdown--active .topbar__dropdown__list {
    right: -1rem;
    left: -1rem;
    visibility: visible; }

.topbar__dropdown__item {
  margin: 0; }

.topbar__dropdown__link {
  display: block;
  padding: 0.65rem 0;
  font-weight: 600;
  color: inherit;
  text-transform: none;
  text-align: left; }
  .topbar__dropdown__link:hover {
    background-color: transparent;
    color: inherit; }

.widget {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  .widget > :first-child {
    margin-top: 0; }
  .widget > :last-child {
    margin-bottom: 0; }
  .widget p:not([class]) {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.714; }
  .widget a:not([class]) {
    font-weight: 600;
    border-bottom: 1px solid #003478;
    padding-bottom: .125em; }
    .widget a:not([class]):hover {
      border-color: #0b7886;
      text-decoration: none;
      color: #0b7886; }

.widget--small {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.widget__heading {
  margin-bottom: 0.75rem;
  padding-top: 0.75rem;
  border-top: 0.25rem solid #8c2287;
  font-family: "Hind", sans-serif;
  font-size: 0.9375rem;
  font-weight: 600; }

.widget__heading--small {
  padding-top: 0;
  border-top: 0; }

.widget__social {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 0;
  list-style: none; }
  .widget__social::before, .widget__social::after {
    display: table;
    width: 0;
    content: ''; }
  .widget__social::after {
    clear: both; }

.widget__social__item {
  margin: 0 1.25rem 0 0;
  float: left; }

.widget__social__link {
  display: block;
  width: 2rem;
  color: #bcbcbc; }

.widget--teal {
  padding: 3rem 1.25rem;
  background: #f5f4f0;
  border-bottom: 17px solid #0b7886; }
  .widget--teal .h1, .widget--teal .hero__title, .widget--teal .hero__title--sized, .widget--teal .hero__title--form {
    color: #0b7886; }

.widget--purple {
  padding: 3rem 1.25rem;
  background: #f5f4f0;
  border-bottom: 17px solid #8c2287; }
  .widget--purple .h1, .widget--purple .hero__title, .widget--purple .hero__title--sized, .widget--purple .hero__title--form {
    color: #8c2287; }

.widget--purple-alt {
  padding: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem; }
