.breadcrumb-hide {
  display: none;
  @include mq($lg) {
    display: block;
  }
}

.breadcrumbs-title {
  margin-bottom: 0.25rem;

  a {
    color: inherit;
  }
}

.breadcrumbs {
  display: none;

  @include mq(64.0625em) {
    display: block;
    font-family: $sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    margin: 2.5rem 0;
    .block & {
      margin-top: 0.5rem;
    }
  }
}

.breadcrumbs__item {
  color: $blue !important;
  font-weight: 400;
  font-size: .82rem;
}

.breadcrumbs__item--active {
  font-weight: 600;
  color: $black !important;
}
