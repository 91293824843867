.widget {
  @include zero;
  @include spacing(4);

  p:not([class]) {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.714;
  }

  a:not([class]) {
    font-weight: 600;
    border-bottom: 1px solid $blue;
    padding-bottom: .125em;

    &:hover {
      border-color: $teal;
      text-decoration: none;
      color: $teal;
    }
  }
}

.widget--small {
  @include spacing(2);
}

.widget__heading {
  margin-bottom: 0.75rem;
  padding-top: 0.75rem;
  border-top: 0.25rem solid $purple;
  font-family: $sans-serif;
  font-size: 0.9375rem;
  font-weight: $semibold;
}

.widget__heading--small {
  padding-top: 0;
  border-top: 0;
}

.widget__social {
  @include clear;
  @include spacing;
  padding: 0;
  list-style: none;
}

.widget__social__item {
  margin: 0 1.25rem 0 0;
  float: left;
}

.widget__social__link {
  display: block;
  width: 2rem;
  color: $gray;
}


.widget--teal {
  padding: 3rem 1.25rem;
  background: $beige;
  border-bottom: 17px solid $teal;
  .h1 {
    color: $teal;
  }
}

.widget--purple {
  padding: 3rem 1.25rem;
  background: $beige;
  border-bottom: 17px solid $purple;
  .h1 {
    color: $purple;
  }
}

.widget--purple-alt {
  padding: 3rem;
  margin-top:3rem;
  margin-bottom:3rem;
}
