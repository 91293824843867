.number-panel {}

.number-panel__top {
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  .h1 {
    margin-top: 0;
  }
}


.number-panel__grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-top: -1rem;
}

.number-panel__item {
  @include zero;
  margin-left: 1rem;
  margin-top: 1rem;
  min-height: 18.25rem;
  width: calc(100% - 1rem);
  
  @include mq(48em) {
    width: calc(50% - 1rem);
    
  }
  @include mq($lg) {
    width: calc(25% - 1rem);
    .number-panel__grid--2 & {
      width: calc(50% - 1rem);
    }
  }

  @include mq(1280px) {
    .number-panel__grid--2 & {
      width: calc(33% - 1rem);
    }
  }
}

.number-panel__image {
  display: none;
  @include mq(48em) {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover';
  }
}

.number-panel__content {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $beige;
  border-bottom: 17px solid;
  padding: 2rem;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: height $transition;
  }
  
  .h1,
  .h6 {
    position: relative;
    transition: color $transition;
  }
  .h1 {
    margin-bottom: 1rem;
  }
  .h6 {
    margin-top: 1rem;
  }
  
  &:hover {
    &::before {
      height: 100%;
    }
    .h1, .h6 {
      color: $white;
    }
  }
}

.number-panel__purple {
  border-color: $purple;
  &::before {
    background-color: $purple;
  }
  .h1 {
    color: $purple;
  }
}

.number-panel__blue {
  border-color: $blue;
  &::before {
    background-color: $blue;
  }
  .h1 {
    color: $blue;
  }
}

.number-panel__teal {
  border-color: $teal;
  &::before {
    background-color: $teal;
  }
  .h1 {
    color: $teal;
  }
}

.number-panel__link {
  background-color: $blue;
  border-bottom: 0;
  color: $white;
  text-align: center;
  text-decoration: underline;
  &:focus {
    outline-offset: 0.125rem;
  }
}

.number-panel__icon {
  width: 50px;
  margin-left: auto;
  margin-right: auto;
  transition: transform $transition;
  a:hover & {
    transform: translateX(1rem);
  }
}
.number-panel__text {
  @extend .h5;
  color: $white;
  
  
}


.number-panel__no-animate {
  .number-panel__content {
    &::before {
      content: none;
    }
    .h6 {
      color: $black;
    }
  }
  .number-panel__purple {
    &:hover {
      .h1 {
        color: $purple;
      }
    }
  }
  .number-panel__teal {
    &:hover {
      .h1 {
        color: $teal;
      }
    }
  }
}

.number-panel__image--tablet {
  @include mq(1280px) {
    display: none;
  }
}
