.statement {
  
  @include mq($lg) {
    display: flex;
  }
}

.statement__left,
.statement__right {
  @include zero;
}

.statement__left {
  @include mq($lg) {
    width: 45%;
  }
}


.statement__right {
  margin-top: 1.5rem;
  border-top: 0.0625rem solid $black-light;
  padding-top: 1.5rem;
  @include mq($lg) {
    margin-top: 0;
    width: 55%;
    border-top: 0;
    border-left: 0.0625rem solid $black-light;
    padding-left: 2rem;
    padding-right: 4rem;
    padding-top: 0;
  }
}


.statement__right--alt {
  border: 0;
}
