body {
  font-family: $serif;
  font-size: 1.0625rem;
  line-height: 1.7059;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $black-light;
  font-family: $sans-serif;
  .online & {
    font-family: $serif;
  }
  a {
    display: inline-block;
  }
  .module--blue & {
    color: $white;
  }
}

h1,
.h1 {
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.0952;
  @include mq(32em) {
    font-size: 2.625rem;
  }
}

h2,
.h2 {
  margin-top: 5rem;
  margin-bottom: 1.25rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
  .online & {
    margin-top: 0;
  }
}

h3,
.h3 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.2857;
}

h4,
.h4 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.2308;
}

h5,
.h5 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.3636;
}

h6,
.h6 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3333;
}

.heading--light {
  font-weight: 500;
  a {
    font-weight: 500;
  }
}

strong {
  font-weight: 600;
}


p {
  @include spacing;
  .main & {
    a:not([class]) {
      border-bottom: 1px solid $blue;
      padding-bottom: .125em;
      &:hover {
        border-color: $teal;
        text-decoration: none;
        color: $teal;
      }
    }
  }

}

.p--small {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: .995rem;
  line-height: 1.714;
}

.p--small *:last-child{
  margin-bottom:0;
}

.p--large {
  font-family: $sans-serif;
  font-size: 1.23rem;
  line-height: 1.8333;
  font-weight: 500;
}

.p--micro {
  font-size: .88rem !important;
}

.p--sans-serif {
  font-family: $sans-serif;
}

.p--xlarge {
  font-size: 1.75rem;
  font-weight: 300;
}

a {
  color: $blue;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}

blockquote {
  clear: both;
  @include zero;
  @include spacing;
  padding: 1.25rem;
  background: $beige;
}

hr {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-top: 0.0625rem solid $gray-dark;
  & + .block {
    @include spacing(3);
  }
}

ul,
ol {
  @include zero;
  @include spacing;
  padding-left: 1.25rem;
}

li {
  margin-bottom: 0.75rem;
}

li:not([class]) {
  a:not([class]) {
    font-weight: 600;
    border-bottom: 1px solid $blue;
    padding-bottom: .125em;
    &:hover {
      border-color: $teal;
      text-decoration: none;
      color: $teal;
    }
  }
}

.caption {
  @extend .p--small;
  @extend .p--sans-serif;
}

.video-caption {
  @extend .p--small;
  @extend .p--sans-serif;
  position: relative;
  @include mq(64.0625em) {
    position: absolute;

  }
}

.caption--alt {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  line-height: 1.8;
  @extend .caption;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .main & {
    a:not([class]) {
      border-bottom: 1px solid $blue;
      padding-bottom: .125em;
      &:hover {
        border-color: $teal;
        text-decoration: none;
        color: $teal;
      }
    }
  }
}

.caption--border-purple {
  border-bottom: 5px solid $purple;
  padding-bottom: 1rem;
}

.inline-image {
  img {
    display: inline-block;
  }
  a {
    padding: 0.25rem;
    display: inline-block;
  }
}

.large--serif {
  font-size: 1.375rem;
  font-weight: lighter;
  line-height: 1.7333;
  font-family: $serif;

}

.two__column__list {
  ul,
  ol {
    @include mq(64em) {
      column-count: 2;
      column-gap: 3rem;
    }
  }
}

.image__bulleted__list {

  li {
    list-style-type: none;
    margin-bottom: .5rem;
    background: url("data:image/svg+xml,%3Csvg width='21px' height='21px' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Enoun_Check_2422614%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='BrandeisAdmissions-Tertiary-mobile' transform='translate(-20.000000, -3772.000000)'%3E%3Cg id='Group-21' transform='translate(20.000000, 3713.000000)'%3E%3Cg id='noun_Check_2422614' transform='translate(0.000000, 59.000000)'%3E%3Crect id='Rectangle' fill='%230B7886' x='0' y='0' width='21' height='21'%3E%3C/rect%3E%3Cpolygon id='Path' fill='%23FFFFFF' fill-rule='nonzero' points='8.8993001 17 3 10.5481497 5.21837799 8.38440253 8.79151616 12.2921894 15.6852801 4 18 6.05295084'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
    margin-left: -1rem;
    padding: 0 0 .25rem 2rem;
    background-position: left 2px;
  }
}

.callout {
  @include zero;
  @include spacing;
  padding: 1.25rem;
  background: $beige;
  border-bottom: 5px solid #8c2287;
}

.routing__link {
  display: inline-block;
  position: relative;
  img {
    min-height: 10rem;
    width: 100%;
  }
  &:focus {
    outline-offset: 0;
  }
}

.routing__link__icon {
  position: absolute;
  bottom: 3rem;
  left: 1rem;
  display: block;
  width: 3.4375rem;
  height: 3.4375rem;
  background-color: $white;
  border-radius: 50%;
  color: $black;
}
.routing__text {
  position: absolute;
  bottom: -1rem;
  margin: 0 1rem 0 0;
  display: inline-block;
  font-family: $sans-serif !important;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3333;
  background-color: $blue;
  color: $white;
  padding: 0.5rem 1rem;

  @include mq(48em) {
    padding: 0.5rem 1rem 0.5rem 0;
    color: $blue;
    background-color: $white;
    .module--gray-light & {
      background-color: $gray-light;
    }
  }


  a:hover & {
    padding: 0.5rem 1rem;
    background-color: $blue;
    color: $white;
  }
}
.two__column__list {
  @include mq(64em) {
    column-count: 2;
    column-gap: 3rem;
  }
}
