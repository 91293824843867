.module {
  @include zero;
  padding-top: 5rem;
  padding-bottom: 5rem;
  
  &.triangle-tab {
    padding-top: 6.25rem;
  }
}

.module--margin {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.module--tight {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  
  @include mq(64.0625em) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  
  &.triangle-tab {
    padding-top: 3.75rem;
    
    @include mq(64.0625em) {
      padding-top: 6.25rem;
    }
  }
}

.module--tight-alt {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  
  @include mq(64.0625em) {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem;
  }
}

.module--beige {
  background: $beige;
}

.module--gray-light {
  background: $gray-light;
}

.module--gray-dark {
  background: $gray-dark;
  color: $white;
  
  .module__title {
    color: $white;
  }
}

.module--blue {
  background: $blue;
  color: $white;
  
  .module__title {
    color: $white;
  }
}

.module--teal {
  background: $teal;
  color: $white;
  
  .module__title {
    color: $white;
  }
}

.module--shadow {
  position: relative;
  box-shadow: 0 0 0.375rem $black-shadow;
  z-index: 1;
}

.module--relative {
  position: relative;
}

.module__container {
  @include zero;
  
  p:nth-of-type(3) {
    &.p--sans-serif {
      margin-top: -1rem;
    }
    
  }
}

.module__header {
  @include zero;
  @include mq(48em) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .button {
    @include mq(48em) {
      margin-top: -1rem;
    }
  }
}
.module__split {
  margin: 1rem 0;
  @include mq(48em) {
    column-count: 2;
    column-gap: 2rem;
  }
  > :first-child {
    margin-top: 0;
  }
}

.module__container__link {
  display: block;
  &:focus {
    [data-whatinput='keyboard'] & {
      outline-offset: 0;
    }
  }
}

.module__title {
  margin-bottom: 2.5rem;
  text-align: center;
}

.module__title--spacing {
  margin-bottom: 2.5rem;
}

.module__title--alt {
  font-weight: 500;
}

.module__subtitle {
  margin-top: -2rem;
  margin-bottom: 2.5rem;
  font-size: 1.25rem;
  text-align: center;
}

.module--overflow-hide {
  overflow: hidden;
}
