.mega {
  position: absolute;
  height: var(--height, auto);
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background: $beige;
  color: $black;
  box-shadow: 0 0 0.375rem $black-shadow;
  visibility: hidden;
  transform: translateY(-.25rem);
  opacity: 0;
  z-index: -1;
  transition: transform $transition, visibility $transition, opacity $transition;

  .nav__item--active & {
    transform: none;
    visibility: visible;
    opacity: 1;
  }
}

.mega__menu {
  width: calc(55% - 0.625rem);
  float: left;
  margin-left: 0;
  margin-right: auto;
}

.mega__content {
  @include zero;
  width: calc(45% - 0.625rem);
  float: right;
}

.mega__heading {
  margin-bottom: 1.25rem;
  border-bottom: 0.25rem solid $blue;
  color: $blue;
  font-family: $sans-serif;
  font-size: 2rem;
  font-weight: 400;
}

.mega__heading--small {
  padding-top: 0.875rem;
  padding-bottom: 0.375rem;
  font-size: 1.25rem;
}

.mega__nav {
  width: 33.333%;
  margin: 0;
  padding: 0 1.25rem 0 0;
  float: left;
  font-family: $sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  list-style: none;
}

.mega__nav__item {
  margin-bottom: 1.25rem;
}

.mega__nav__link {
  font-weight: 600;
  font-size: 1.1rem;
  .mega__nav__item--current > & {
    color: $black;
    text-decoration: none;
  }
}

.mega__close {
  position: absolute;
  cursor: pointer;
  top: 2rem;
  right: 2rem;
}
.mega__close--icon {
  position: absolute;
  right: 0;
  width: 1.5rem;
}
