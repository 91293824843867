.feature-box {
  margin: 2rem 0;

  border: 0.0625rem solid $black-light;
  @include mq(48em) {
    display: flex;
    align-items: center;
  }
}

.feature-box__media {
  width: 100%;
  @include mq(48em) {
    width: 49%;
    align-self: stretch;
    .feature-box--swap & {
      order: 1;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover';
  }
}

.feature-box__content {
  @include zero;
  padding: 3rem;
  @include mq(48em) {
    width: 52%;
  }

}
