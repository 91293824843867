.timeline-slider {
  @include mq(48em) {
    margin: 0 3rem;
  }

  .flickity-prev-next-button {
    top: 0;
    margin-top: calc(33.3333% + 3.0625rem);
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="0" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
    filter: drop-shadow(0 .1rem .1rem $black);
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#000,strength=0);
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      background: rgba($black, .54);
    }
    @include mq(48em) {
      margin-top: calc(16.6667% - 0.3125rem);
      padding: 0;
      filter: none;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background: none;
      }
      .arrow {
        fill: $gray;
      }
    }

    &.previous {
      @include mq(48em) {
        right: calc(100% + 2rem);
        left: auto;
      }
    }

    &.next {
      @include mq(48em) {
        right: auto;
        left: calc(100% + 2rem);
      }
    }
  }
}

.timeline {
  @include clear;
  width: 100%;
  margin: 0 0.625rem;
}

.timeline__nav {
  @include clear;

  @include mq(48em) {
    display: none;
  }
}

.timeline__nav__link {
  width: 50%;
  padding: 0.625rem;
  float: left;
  background: $beige;
  font-family: $sans-serif;
  text-align: center;

  &:hover {
    text-decoration: none;
  }
}

.timeline__nav__link--active {
  background: $blue;
  color: $white;
}

.timeline__pair {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;

  @include mq(48em) {
    display: flex;
  }

  &::before,
  &::after {
    position: absolute;
    bottom: 0;
    width: 6rem;
    height: 4rem;
    content: '';
    z-index: 1;
  }

  &::before {
    left: 0;
    background-image: linear-gradient(to right, $white, $transparent);
  }

  &::after {
    right: 0;
    background-image: linear-gradient(to left, $white, $transparent);
  }
}

.timeline__item {
  @include hide;
  text-align: center;
  visibility: hidden;

  @include mq(48em) {
    @include show;
    display: flex;
    flex-direction: column;
    width: calc(50% - 0.625rem);
    float: left;
    visibility: visible;

    &:last-child {
      margin-left: auto;
      float: right;
    }
  }
}

.timeline__item--active {
  @include show;
  visibility: visible;
}

.timeline__text {
  @include zero;
  width: 100%;
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
  padding: 0 1.25rem;
}

.timeline__date {
  width: 100%;
  @include mq(48em) {
    margin-top: auto;
    padding-bottom: 4.25rem;
  }
}

.timeline__year {
  display: inline-block;
  position: relative;
  padding: 0.5rem 1rem;
  background: $gray-dark;
  color: $white;
  font-family: $sans-serif;
  font-size: 0.75rem;

  @include mq(48em) {
    transition: background-color $transition $transition-speed * 3;
    background: $white;
  }

  .is-selected & {
    background: $gray-dark;
  }

  @include mq(48em) {
    &::before {
      position: absolute;
      bottom: -2.5rem;
      left: 50%;
      width: 0.125rem;
      height: 0;
      transform: translateX(-50%);
      transition: height $transition $transition-speed * 2;
      background: $gray-dark;
      content: '';

      .is-selected & {
        height: 2.5rem;
      }
    }

    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 1rem;
      height: 1rem;
      margin-top: 2rem;
      transform: translateX(-50%) scale(0.25);
      transition: transform $transition $transition-speed, opacity $transition $transition-speed;
      border-radius: 50%;
      background: $gray-dark;
      box-shadow: 0 0 0 1.125rem $black-shadow;
      content: '';
      opacity: 0;

      .is-selected & {
        transform: translateX(-50%) scale(1);
        opacity: 1;
      }
    }
  }
}

.timeline__hashes {
  @include mq(48em) {
    position: absolute;
    bottom: 1.6875rem;
    left: 0;
    width: 100%;
    height: 1rem;
    border-bottom: 0.125rem solid $gray-light;
    background-image: repeating-linear-gradient(to right, $gray-light 0, $gray-light 0.125rem, transparent 0.125rem, transparent 1.375rem);
    z-index: -1;
  }
}
