.feature {
  @include clear; // ie9
  @include spacing(2);

  @include mq(48em) {
    display: flex;
  }
}

.feature__media,
.feature__text {
  @include mq(48em) {
    .feature--full & {
      width: calc(50% - 2.5rem);
    }
  }
}

.feature__media {
  @include zero;
  margin-bottom: 1.25rem;

  @include mq(48em) {
    width: calc(40% - 1.25rem);
    margin-bottom: 0;
    float: left; // ie9
  }
}

.feature__text {
  @include zero;

  @include mq(48em) {
    align-self: center;
    width: calc(60% - 1.25rem);
    margin-left: auto;
  }
}

.feature__text--alt {
  p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8;
  }
}
