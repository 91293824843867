.media {
  @include zero;
  @include spacing(2);
  img {
    width: 100%;
  }
}

.media--narrow {
  @include mq(48em) {
    max-width: 66.6667%;
  }
}

.media--left,
.media--right {
  @include mq(48em) {
    width: calc(50% - 1rem);
  }
}

.media--left {
  @include mq(48em) {
    margin-right: 2rem;
    float: left;
  }
}

.media--right {
  @include mq(48em) {
    margin-left: 2rem;
    float: right;
  }
}

.media--tight {
  @include spacing(-2);
  @include mq(64.0625em) {
    @include spacing(2);
  }
}

.media__text {
  @include zero;
  @include spacing;
}

.media__item {
  display: inline-block;
  position: relative;
  &:focus{
    [data-whatinput='keyboard'] & {
      outline: 0.125rem solid $focus;
      outline-offset: 0;
    }
  }
  .media--full & {
    display: block;
  }
}

.media__overlay {
  position: relative;
}

.media__overlay--alt {
  @include zero;
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
}

.media__overlay__text {
  color: $white;
  font-family: $sans-serif;
  font-weight: 600;
  text-align: center;
  text-shadow: .1rem .1rem .1rem $black;
}

.media__icon {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
  width: 3.4375rem;

  color: $black;
  .media--full & {
    display: block;
    position: static;
    margin: auto;
    color: $black;
  }
  .image-slider--full & {
    position: absolute;
  }
  .feature__media & {
  }
  &.video__captioned{
    bottom: 3.5rem;
    @include mq(48em) {
      bottom: 4.2rem;
    }
    @include mq(64.0625em) {
      bottom: 1.2rem;
    }
  }
}

.media__credit {
  margin-top: -0.75rem;
  font-size: .88rem;
}

.media__embed {
  position: relative;
  padding-bottom: 56.25%;
  > * {
    position: absolute;
    width: 100%;
    height: 100%;

  }

}
.jw-skin-bekle.jwplayer:not(.jw-flag-touch):not(.jw-error):not(.jw-state-error):not(.jw-state-buffering) .jw-display-icon-container:focus {
  background-color: #139ed5 !important;
}
.jw-skin-bekle:not(.jw-flag-touch) .jw-button-color:hover, .jw-skin-bekle .jw-button-color:focus {
  background-color: #139ed5 !important;
}
