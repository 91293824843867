.topbar {
  display: none;
  padding: 0.625rem 0 0.5625rem;
  background: $blue;
  color: $white;

  @include mq(64.0625em) {
    display: block;
  }
}

.topbar__list {
  margin: 0;
  padding: 0;
  float: right;
  list-style: none;
}

.topbar__list__item {
  margin: 0 0 0 1.5rem;
  float: left;
}

.topbar__list__link {
  display: block;
  position: relative;
  padding: 0.25rem 1.5rem;
  color: inherit;
  font-family: $sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  border: 0.0625rem solid $white;
  border-radius: 0.1875rem;
  margin-top: 0.35rem;
  &::before {
    display: none;
    position: absolute;
    bottom: 0.125rem;
    left: 0;
    width: 100%;
    height: 0.25rem;
    background: $white;
    content: '';
  }

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $white;
    color: $blue;
    [data-whatinput='keyboard'] & {
      outline-offset: 0.125rem;
    }
  }
}

.topbar__list__link--search {
  display: block;
  width: 1.25rem;
  padding: 0.8125rem 0;
  overflow: hidden;

  &::before {
    background: $blue;
  }
}

.topbar__list__link__inactive {
  display: block;

  .search-active & {
    display: none;
  }
}

.topbar__list__link__active {
  display: none;

  .search-active & {
    display: block;
  }
}

.topbar__dropdown {
  position: relative;
  float: left;
  color: $white;
  font-family: $sans-serif;
  z-index: 5;
}

.topbar__dropdown__toggle {
  padding: 0.5625rem 0;
  float: left;
  color: inherit;
  font-size: 0.875rem;
  font-weight: 600;
  &:hover {
    text-decoration: none;
  }

  .topbar__dropdown--active & {
    color: $blue;
  }
}

.topbar__dropdown__icon {
  width: 1.25rem;
  margin-left: 0.5rem;
  margin-top: 0.15rem;
  float: right;

  .topbar__dropdown--active & {
    transform: rotate(180deg);
  }
}

.topbar__dropdown__list {
  @include hide;
  position: absolute;
  top: -0.625rem;
  margin: 0;
  padding: 3.75rem 1rem 1.25rem;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
  background: $gray-light;
  color: $blue;
  list-style: none;
  box-shadow: 0 0.375rem 0.375rem $black-shadow;
  visibility: hidden;
  z-index: -1;

  .topbar__dropdown--active & {
    right: -1rem;
    left: -1rem;
    visibility: visible;
  }
}

.topbar__dropdown__item {
  margin: 0;
}

.topbar__dropdown__link {
  display: block;
  padding: 0.65rem 0;
  font-weight: 600;
  color: inherit;
  text-transform: none;
  text-align: left;
  &:hover {
    background-color: transparent;
    color: inherit;
  }
}
