.footer {
  background: $gray-dark;
  color: $white;
  font-family: $sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;

  p {
    @include spacing(0.5);
  }

  a:not([class]) {
    color: inherit;
    font-weight: 400;
  }

}

.footer__top {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.footer__bottom {
}

.footer__container {
  @include zero;
  .footer__bottom & {
    border-top: 0.125rem solid $gray-medium;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.footer__heading {
  margin-top: 2.5rem !important;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.125;
  @include mq($lg) {
    margin-top: 0 !important;
  }
}

.footer__hr {
  @include spacing(0.5);
  border-top: 0.25rem solid $gray-medium;

  .footer__heading + & {
    margin-top: -0.75rem;
  }
}

.footer__nav {
  @include zero;
  margin: 0 0 2rem 0;
  padding: 0;
  list-style: none;
}

.footer__nav__item {
  padding: 1rem 0;
  border-bottom: 0.0625rem solid $gray-medium;
  margin: 0;
}

.footer__nav__link {
  color: $white;
  font-weight: 400;
  font-size: .995rem;
}

.footer__social {
  @include clear;
  margin: 2rem 0;
  padding: 0;
  list-style: none;

  .footer__hr + & {
    margin-top: 0;
  }
}

.footer__social__item {
  margin: 0 1.25rem 0 0;
  float: left;
}

.footer__social__link {
  color: $white;
  display: block;
  width: 2.1875rem;
  &:focus {
    outline-offset: .125rem;
  }
}

.footer__logo {
  color: $white;
  display: block;
  max-width: 10rem;
  margin-bottom: 2rem;
}


.grid__item.footer__grid__first {
  width: 100%;
  @include mq($lg) {
    width: calc(50% - 6rem);
    border-right: 0.0625rem solid $gray-medium;
    margin-right: 4.5rem;
  }
}
