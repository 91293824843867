.testimonial__slider {
  .module__container {
    width: 100%;
  }

  .flickity-page-dots {
    margin-top: 3rem;
    position: static;

    .dot {
      color: $white;
    }
  }

  .flickity-prev-next-button {
    top: auto;
    bottom: -3rem;

    @include mq($lg) {
      top: 50%;
      bottom: auto;
    }
    &.previous {
      @include mq($lg) {
        left: -3rem;
      }
    }

    &.next {
      @include mq($lg) {
        right: -3rem;
      }
    }
  }
}


.testimonial__image {
  margin: 2rem auto;
  width: 6.25rem;
  height: 6.25rem;
}

.testimonial__bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  .testimonial__image {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .p--large {
    margin-bottom: 0;
  }
}
