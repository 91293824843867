body {
  .juicer-feed {
    font-family: $sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    text-align: center;
    overflow: visible;

    a {
      transition: none;

    }

    h1.referral {
      display: none;
    }

    &.modern li.feed-item {
      border-width: 1px;
    }

    .j-poster h3 {
      color: $blue;
    }

    .j-date {
      font-size: inherit;
      font-weight: 600;
      color: #767676
    }

    .j-message {
      a {
        color: $blue;
        font-weight: 500 !important;

        &:hover {
          color: $blue;
          text-decoration: underline !important;
        }
      }
    }

    .j-meta {
      a {
        color: #767676;
        &:hover {
          color: $blue;
        }
      }
    }

    .j-paginate {
      &.juicer-button {
        display: inline-block;
        width: auto;
        margin-bottom: 0;
        padding: 1rem 1.875rem .75rem;
        border: 0.0625rem solid;
        border-radius: 0.1875rem;
        color: $black;
        font-family: $sans-serif;
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: normal;
        line-height: 1.1667;
        text-transform: uppercase;

        &:hover {
          background: none;
          color: $black;
          text-decoration: none;
        }
      }
    }
  }
}
