.overlay {
  position: relative;
}

.overlay--alt {
  padding-top: 10rem;
  color: $white;
  @include mq(48em) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .68);
  }
  .h1, .h2, p {
    color: $white;
    text-shadow: .1rem .1rem .1rem $black;
    &.overlay__text--alt {
      margin-top: 10.5%;
    }
  }

  .button--white {
    text-shadow: .1rem .1rem .1rem $black;
    &:hover,
    &:focus {
      text-shadow: none;
    }
  }
}

.overlay__button {
  display: inline-block;
  padding: 1rem 1.875rem .75rem;;
  border: 0.0625rem solid $white;
  border-radius: 0.1875rem;
  color: $black;
  font-family: $sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.1667;
  text-transform: uppercase;
  background-color: $white;
  text-align: center;
  &:hover,
  &:focus {
    background-color: $white;
    color: $black;
    text-decoration: none;
    outline-offset: 0.125rem;
  }
}

.overlay-tall {
  @include mq(48em) {
    height: 800px;
    .module, .wrap {
      height: inherit;
    }
    .module {
      padding: 0;
    }
  }
}

.overlay-push {
  @include mq(48em) {
    padding-top: 10rem;
  }
}

.overlay--dark {
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .68);

  }

  .overlay__image {
    z-index: -1;
  }
}

.overlay--video {
  width: 100%;
  height: auto;
  overflow: hidden;
  @include mq(48em) {
    height: 600px;
  }
}

.overlay__image, .overlay__video, .overlay__video__media {
  width: 100%;
}

.overlay__image {
  .overlay--alt & {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover';
  }
}

.overlay__video {
  width: 100%;
  max-height: 768px;
}

.overlay__wrap {
  @include zero;
}

.overlay__container {
  @include zero;
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: $beige;
  text-align: center;

  @include mq(48em) {
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: calc(100% - 4rem);
    margin: 0;
    padding: 0;
    transform: translateY(-50%);
    background: transparent;
    color: $white;
  }

  @include mq(90em) {
    width: 84rem;
    max-width: calc(100% - 6rem);
  }
}

.overlay__title {
  @include mq(48em) {
    color: $white;
    text-shadow: .1rem .1rem .1rem $black;

    .overlay--dark & {
      text-shadow: none;
    }
  }
}

.overlay__text {
  font-family: $sans-serif;
  font-size: 1.25rem;

  @include mq(48em) {
    font-size: 1.75rem;
    text-shadow: .1rem .1rem .1rem $black;
    .overlay--dark & {
      text-shadow: none;
    }
  }
}





.overlay__num {
  font-family: $sans-serif;
  font-weight: 600;
  font-size: 4.5rem;
  white-space: nowrap;
  border-bottom: .125rem solid $white;
  display: inline-block;
  text-shadow: .1rem .1rem .1rem $black;
  box-shadow: 0 0.1rem 0 0 $black;
  @include mq(48em) {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0;
    border-right: .125rem solid $white;
    box-shadow: 0.1rem 0 0 $black;
  }
  @include mq(64em) {
    font-size: 7rem;
  }
  @include mq(80em) {
    font-size: 11rem;
  }
  span {
    display: block;
  }
}

.overlay__heading {
  position: absolute;
  top: -5rem;
  @include mq(48em) {
    position: static;
  }
}

.overlay__link {
  display: block;
  width: 3.125rem;
  height: 3.125rem;

  background-color: $white;
  border-radius: 50%;
  color: $black;
  margin-bottom: 2rem;
  @include mq(48em) {
    width: 4.6875rem;
    height: 4.6875rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 0;
  }
}

.overlay__content {
  @include zero;
  @include mq(48em) {
    position: absolute;
    bottom: 8rem;


    max-width: 40%;
  }
}
