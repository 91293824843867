.block {
  @include clear;
  @include spacing(4);
}

.block--small {
  margin-bottom: 2rem;
}

.block__text {
  @include zero;
  overflow: hidden;
}

.block__text--alt {
  p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8;
  }
}
