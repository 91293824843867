.feed-group {
  @include zero;
  @include spacing(4);
}

.feed-group-no-heading {
  margin-top: 3rem;
}

.feed-title {
  margin-bottom: 2.5rem;
  padding-top: 1.25rem;
  border-top: 0.25rem solid $gray-dark;
}

.feed {
  @include clear;
  @include spacing(2);
  padding-top: 2.5rem;
  border-top: 0.0625rem solid $gray-dark;

  &:first-child,
  .feed-title + & {
    padding-top: 0;
    border-top: 0;
  }
}

.feed__text {
  @include zero;
  overflow: hidden;
  font-size: 0.9375rem;
}

.feed__text--alt {
  p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8;
  }
}

.feed__image {
  position: relative;
  a {
    display: block;
    &:focus {
      [data-whatinput='keyboard'] & {
        outline-offset: 0;
      }
    }
  }
}
