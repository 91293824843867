.secnav {
  background-color: $beige;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  
  @include mq($lg) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.secnav__container {
  @include clear;
  max-width: 90rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  
  @include mq(48em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  @include mq(64.0625em) {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.secnav__list {
  display: none;
  margin-top: -0.0625rem;
  padding: 0 1.5rem 1rem 1.5rem;
  border: 0.0625rem solid $black;
  border-radius: 0.1875rem;
  @include mq($lg) {
    display: block;
    border: 0;
    padding: 0;
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    
    @include mq($lg) {
      margin: -1rem 0 0;
      display: flex;
      flex-wrap: wrap;
      margin-left: -1.5rem;
    }
    
    &:last-child {
      margin-bottom: inherit;
    }
  }
  
  li {
    @include mq($lg) {
      margin-bottom: 0;
      padding: 1rem 0;
      border-bottom: .0625rem solid;
      width: calc(25% - 1.5rem);
      margin-left: 1.5rem;
    }
  }
  
  a {
    border-bottom: 0 !important;
    font-weight: 600;
    font-family: $sans-serif;
    font-size: 0.9375rem;
    color: $black;
    
    &:hover {
      color: $black !important;
      text-decoration: underline !important;
    }
  }
  
  .secnav--active & {
    display: block;
  }
}

.secnav__toggle {
  padding: 1rem 1.5rem;
  width: 100%;
  text-align: left;
  font-family: $sans-serif;
  font-weight: 600;
  font-size: 0.9375rem;

  &:hover,
  &:focus {
    border-color: inherit;
    background-color: transparent;
    color: $black;
  }
  @include mq($lg) {
    display: none;
  }
  
  .secnav--active & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: transparent;
    background: $beige;
  }
}

.secnav__text {
  display: inline-block;
  width: calc(100% - 2rem);
}

.secnav__icon {
  display: inline-block;
  width: 1rem;
  margin-left: 0.5rem;
  color: $black;
  
  @include mq($lg) {
    display: none;
  }
  
  .secnav--active & {
    transform: rotate(-180deg);
  }
}
