.splash {
  @include mq($lg) {
    display: flex;
  }
}

.splash__item {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  min-height: 300px;
  border-bottom: 17px solid;
  overflow: hidden;
  &::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.2) 100%);
    z-index: 0;
  }
  @include mq($lg) {
    width: 33.333%;
    height: 700px;
  }
  &:nth-child(1) {
    border-color: $teal;
  }
  &:nth-child(2) {
    border-color: $blue;
  }

  &:nth-child(3) {
    border-color: $purple;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.splash__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform $transition;
  z-index: -1;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}


.splash__content {
  position: relative;
  padding: 2.8125rem;

  .button {
    margin-top: 2rem;
    text-shadow: 0.1rem 0.1rem 0.1rem $black;
    box-shadow:0.375rem 0.375rem 0.375rem $black-shadow;
  }

  .button:focus{
    text-shadow: none;
  }

  .button:hover {
    margin-top: 2rem;
    text-shadow: none;
    box-shadow:0.375rem 0.375rem 0.375rem $black-shadow;
  }
}

.splash__headline {
  margin: 0;
  color: $white;
  text-shadow: 0.1rem 0.1rem 0.1rem $black;
}
