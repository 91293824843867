.button-group {
  @include zero;
  @include spacing(2);
}

.button-group--alt {
  margin-left: -1rem;
  > * {
    margin-top: 1rem;
    margin-left: 1rem;
  }
  padding-left:0;
  padding-top:.96em;
  list-style-type:none;

  @include mq(64em) {
  display: inline-flex;
  margin-top:0;
  li {
    a{
    margin-bottom:auto !important;
    }
   }
  }
  li{
    display:inline;
    a{
      margin-bottom:1rem;
    }
  }
  li:first-child{
    margin-top:1rem;
  }
}

.button-stack {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top:2rem;
}

.button-stack__item {
  display: flex;
  flex-grow: 1;
  margin-bottom: 2rem !important;
  margin-left: 1rem;
  margin-right: 1rem;
  width:100%;
  .button-stack--3 & {
    @include mq(64em) {
      flex-grow: 0;
      width: calc(33% - 2rem);
    }
  }

  .button-stack--2 & {
    width:100%;
    @include mq(64em) {
      flex-grow: 0;
      width: calc(50% - 2rem);
    }
  }

  .button {
    flex-grow: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.button {
  display: inline-block;
  padding: 1rem 1.875rem .75rem;
  border: 0.0625rem solid;
  border-radius: 0.1875rem;
  color: $black;
  font-family: $sans-serif;
  font-size: .9rem;
  font-weight: 600;
  line-height: 1.1667;
  text-transform: uppercase;
  text-align:center;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $blue;
    color: $white;
    border-color: $blue;
  }

  &:focus {
    outline-offset: 0.125rem;
  }
}

.button--white {
  color: $white;

  &:hover,
  &:focus {
    background-color: $white;
    color: $black;
    border-color: $white;
  }
}

.button--solid-white {
  border-color: $white;
  background-color: $white;
  &:hover,
  &:focus {
    border-color: $white;
  }

  .online & {
    color: $white;
    background-color: $purple;
    border: none;
    &:hover,
    &:focus {
      background-color: $white;
      color: $black;
    }
  }
}

.button--purple {
  color: $white;
  border: none;
  background-color: $purple;
  &:hover,
  &:focus {
    background-color: $white;
    color: $black;
  }
}

.button--blue {
  background: $blue;
  border-color: $blue;
  color: $white;
  &:hover,
  &:focus {
    background-color: $white;
    color: $black;
    border-color: $black;
  }
}

.button--teal {
  border-color: transparent;
  background: $teal;
  color: $white;
  &:hover,
  &:focus {
    border-color: transparent;
    background: $teal;
  }
}

.button--teal-white {
  border-color: transparent;
  background: $teal;
  color: $white;
  &:hover,
  &:focus {
    border-color: transparent;
    background: $white;
    color: $black;
  }
}

.button--overlay {
  // border-color: transparent;
  background: $white;
}

.button--text {
  padding: 0;
  border: 0;
  color: $blue;

  &:hover,
  &:focus {
    color: $blue;
    text-decoration: underline;
    background-color: transparent;
  }

  .module--gray-dark & {
    color: $white;
  }
}

.button--full {
  @include spacing;
  display: block;
  width: 100%;
  text-align: center;
}
