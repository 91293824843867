.feature-panel {
  margin-top: 5rem;
  color: $white;

  .wrap {
    @include mq($lg) {
      display: flex;
    }
  }
}


.feature-panel--blue,
.feature-panel-bg--blue {
  background-color: $blue;
}

.feature-panel-bg-mobile--blue {
  background-color: $blue;
  @include mq($lg) {
    background-color: transparent;
  }
}

.feature-panel__media {
  @include mq($lg) {
    width: 60%;
    flex-shrink: 0;
    order: 1;
  }

  img {
    width: 100%;
    .feature-panel--caption-abs & {
      // height: 100%;
      // object-fit: cover;
      // font-family: 'object-fit: cover';
    }
  }

  .feature-panel-bg-mobile--blue & {
    background-color: $blue;
  }
}

.feature-panel__media__inner {
  position: relative;
  .feature-panel--caption-abs & {
    // height: 100%;
  }
}

.feature-panel__title {
  @extend .h2;
  color: $white;
}

.feature-panel__media {
  position: relative;
  transform: translateY(-2rem);
  @include mq($lg) {
    transform: none;
    margin-top: -2rem;
    margin-bottom: 2rem;
  }
}

.feature-panel__caption {
  margin: 0;
  font-size: 0.98rem;
  .feature-panel--caption-abs & {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $black;
    color: $white;
    padding: 0.75rem 1.25rem;
    p {
      margin: 0;
    }
  }
}

.feature-panel__content {

  padding: 0 2rem 2rem 0;

  @include mq($lg) {
    display: flex;
    align-items: center;
    padding: 0 12rem 2rem 0;
    margin-right: -8rem;
    padding-top: 2rem;
    padding-left: 2rem;
    .feature-panel-bg--blue & {
      padding-left: 0;
    }
  }
}

.feature-panel__inner {
  @include zero;

  hr {
    border-color: $white;
    margin: 0;
  }
}

.feature-panel__link {
  display: block;
  width: 4.6875rem;
  height: 4.6875rem;
  background-color: $white;
  border-radius: 50%;
  color: $black;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  z-index: 1;
  &:focus {
    outline-offset: 0.5rem;
    z-index: 1;
  }
}

.feature-panel__list {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
  .svgstore {
    width: 1rem;
    display: inline-block;
    padding-bottom: 4%;
    margin-right: 0.5rem;
  }
}
