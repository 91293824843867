.tabs .tabs-nav {
  @include mq($xl) {
    display: flex;
    flex-wrap: nowrap;
    margin: 2rem 0 0 0;
  }
}

.tabs-nav {
  display: none;
  @include mq($xl) {
    display: block;
  }
}

.tabs__mobile__toggle {
  position: relative;
  padding: 1.25rem;
  font-family: $sans-serif;
  background-color: $blue;
  color: $white;
  font-size: 1.0625rem;
  line-height: 1.7059;
  font-weight: 600;
  text-align: left;
  margin-top: 1.25rem;
  width: 100%;


  &:hover, &:focus, &:active {
    [data-whatinput='keyboard'] & {
      @include focus;
      outline-offset: 0.125rem;
    }
  }
}

.tabs__mobile__toggle::after{
   content: none !important;
}

.tabs__toggle {
  position: relative;
  padding: 1.25rem;
  font-family: $sans-serif;
  background-color: $beige;
  color: $black;
  font-size: 1.0625rem;
  line-height: 1.7059;
  font-weight: 600;
  text-align: left;
  margin-top: 1.25rem;
  width: 100%;

  @include mq($xl) {
    text-align: center;
    line-height: 1;
    flex-grow: 1;
    order: -1;
    width: auto;
    margin: 0 0 0 0.25rem;
  }

  &:first-child {
    @include mq($xl) {
      margin: 0;
    }
  }

  &:hover, &:focus, &:active {
    @include mq($xl) {
      background-color: $blue;
      color: $white;
    }
    [data-whatinput='keyboard'] & {
      @include focus;
      outline-offset: 0.125rem;
    }
  }
}

.tabs__icon {
  width: 1.8125rem;
  margin-left: 1.25rem;
  padding: 0.125rem;
  float: right;
}

.tabs__icon--inactive {
  display: block;

  .tabs__toggle--active & {
    display: none;
  }
}

.tabs__icon--active {
  display: none;

  .tabs__toggle--active & {
    display: block;
  }
}

.tabs__icon--active,
.tabs__icon--inactive,
.tabs__toggle--active .tabs__icon--active,
.tabs__mobile__toggle {
  @include mq($xl) {
    display: none;
  }
}

.tabs__toggle--active {
  background-color: $blue;
  color: $white;
}


.tabs__toggle--active::after{
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -1.25rem;
    border-top: 1.25rem solid #003478;
    border-right: 1.25rem solid transparent;
    border-left: 1.25rem solid transparent;
    content: "";
}

.tabs__content__item {
  @include zero;
  margin-top: 0.125rem;
  visibility: hidden;
  overflow: hidden;
  width: 100%;
  padding: 1.25rem;
  @extend .p--small;
  background-color: $beige;
  @include mq($xl) {
    display: none;
    visibility: visible;
  }
  &.mobile__tabs__content__item--active {
    visibility: visible;
  }
  &.tabs__content__item--active {
    @include mq($xl) {
      display: block;
    }
  }
  a:not([class]) {
    font-weight: 600;
    border-bottom: 1px solid $blue;
    padding-bottom: .125em;

    &:hover {
      border-color: $teal;
      text-decoration: none;
      color: $teal;
    }
  }
  @include mq(48em) {
    padding:2rem;
  }
}

.tabs__content__item:not(.mobile__tabs__content__item--active) {
  height: 0 !important;
  padding: 0;
  margin: 0;
  @include mq($xl) {
    height: auto !important;
    padding:2rem;
  }
}
