.router-grid {
  margin: 0;
}

.router-grid__title {
  color: $black !important;
  font-family: $serif;
  margin-bottom: 3rem;
  margin-top: 0;
  @include mq($lg) {
    margin-left: 4rem;
    max-width: 690px;
  }
}

.router-grid__items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.router-grid__item {
  position: relative;
  display: flex;
  margin-bottom: 0;
  width: 100%;
  @include mq(48em) {
    width: 50%;
  }
  @include mq($xl) {
    width: 25%;
  }
}

.router-grid__link {
  position: relative;
  width: 100%;
  @include mq($lg) {
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.20) 45.39%);
      opacity: 1;
      z-index: 1;
      transition: opacity $transition;
    }

    &:hover,
    &:focus {
      &::before {
        opacity: 0;
      }
      .button {
        background-color: transparent;
      }
      .router-grid__content::before {
        height: calc(var(--height) - 17px);
      }
    }
    &:focus {
      outline-offset: 0.5rem;
      z-index: 1;
    }
  }
  img {
    width: 100%;
    object-fit: cover;
  }
}

.router-grid__content {
  display: flex;
  padding: 28px 44px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  &::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    transition: height $transition;
    z-index: -1;
  }
  p {
    margin: 0;
    color: $white;
  }
  .button {
    text-decoration: underline;
    border: none;
    background: none;
    padding: 0;
    @include mq($lg) {
      border: 2px solid;
      text-decoration: none;
      padding: 1rem 1.875rem .75rem;
    }
  }
}

.router-grid__headline {
  color: $white;
  margin: 0;
}

.router-grid__content--teal {
  background-color: $teal;
  border-bottom: 17px solid $teal;
  @include mq($lg) {
    background-color: transparent;
    &::before {
      background-color: $teal;
    }
  }
}

.router-grid__content--blue {
  background-color: $blue;
  border-bottom: 17px solid $blue;
  @include mq($lg) {
    background-color: transparent;
    &::before {
      background-color: $blue;
    }
  }
}

.router-grid__content--purple {
  background-color: $purple;
  border-bottom: 17px solid $purple;
  @include mq($lg) {
    background-color: transparent;
    &::before {
      background-color: $purple;
    }
  }
}

.router-grid__content--gray {
  background-color: #58595b;
  border-bottom: 17px solid #58595b;
  @include mq($lg) {
      background-color: transparent;

      &::before {
        background-color: #58595b;
      }
    }
}
