.dropdown {
  @include spacing;
  position: relative;
  width: 20rem;
  max-width: 100%;
}

.dropdown__toggle {
  display: block;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-transform: none;
  text-align:left;
  &:hover,
  &:focus {
    background-color: $white;
    color: $black;
    border-color: $black;
  }

  .dropdown--active & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: transparent;
    background: $beige;
    color: $black;
  }
}

.dropdown__icon {
  width: 1.25rem;
  margin-top: -0.1875rem;
  margin-left: 0.75rem;
  float: right;
}

.dropdown__icon--inactive {
  display: block;

  .dropdown--active & {
    display: none;
  }
}

.dropdown__icon--active {
  display: none;

  .dropdown--active & {
    display: block;
  }
}

.dropdown__list {
  @include hide;
  width: 100%;
  margin-top: -0.0625rem;
  margin-bottom: 0;
  padding: 0 0.75rem 0.75rem;
  border: 0.0625rem solid $black;
  border-top: 0;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
  background: $beige;
  list-style: none;
  box-shadow: 0 0.375rem 0.375rem $black-shadow;
  visibility: hidden;
  z-index: 1;

  .dropdown--active & {
    @include show;
    position: absolute;
    visibility: visible;
  }
}

.dropdown__item {
  margin-bottom: 0.625rem;
}

.dropdown__link {
  text-transform: none;
}
