.flickity-enabled:focus {
  [data-whatinput='keyboard'] & {
    @include focus;
  }
}

.flickity-page-dots {
  top: 1rem;
  right: 1rem;
  bottom: auto;
  width: auto;
  font-size: 0;

  @include mq(48em) {
    top: 2.5rem;
    right: 2.5rem;
  }

  .dot {
    width: 1rem;
    height: 1rem;
    margin: 0 0.25rem;
    border: 0.0625rem solid;
    background: $transparent;
    color: $blue;
    opacity: 1;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.is-selected {
      background: currentColor;
    }
  }
}

.flickity-prev-next-button {
  width: 3.75rem;
  height: 3.75rem;
  padding: 0.5rem;
  border-radius: 0;
  background: $transparent;

  svg {
    position: static;
    width: auto;
    height: auto;
  }

  &:focus {
    @include focus;
    box-shadow: none;
  }

  &:active {
    opacity: 1;
  }

  &:hover,
  &:focus {
    background: $transparent;
  }

  &:disabled {
    opacity: 0;
  }

  &.previous {
    left: 0;
  }

  &.next {
    right: 0;
  }

  .arrow {
    fill: $white-opacity;
  }
}
