// transitions
$transition-speed: 300ms;
$transition-easing: ease;
$transition: $transition-speed $transition-easing;

// fonts
$serif: 'merriweather', serif;
$sans-serif: 'Hind', sans-serif;

// colors
$blue: #003478;
$blue-opacity: rgba($blue, 0.5);
$teal: #0b7886;
$purple: #8c2287;
$yellow: #fffb00;
$beige: #f5f4f0;
$white: #fff;
$white-opacity: rgba($white, 0.75);
$gray: #bcbcbc;
$gray-light: #f5f5f5;
$gray-medium: #797a7c;
$gray-dark: #58595b;
$black: #000;
$black-light: #2f2f2f;
$black-shadow: rgba($black, 0.125);
$black-fade: rgba($black, 0.75);
$transparent: rgba($white, 0.001);
$focus: rgba(77, 144, 254, 1);


$lg: 64.0625em;
$xl: 73.500em;

$semibold: 600;


// animations
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes slideLeft {

  from,
  to {
    left: 0;
    width: 0;
  }

  to {
    left: 0;
    width: 100%;
  }
}
