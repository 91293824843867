.fact {
  display: flex;
  flex-direction: column;
  background: $blue;
  font-family: $sans-serif;
  text-align: center;
}

.fact__title {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-content: center;
  padding: 2.5rem 1rem;
  background: $blue;
  color: $white;

  @include mq(90em) {
    padding: 1rem;
  }
}

.fact__number {
  width: 100%;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1;

  @include mq(90em) {
    font-size: 7.5rem;
  }
}

.fact__unit {
  width: 100%;
  line-height: 1;
}

.fact__description {
  @include zero;
  padding: 2.5rem 1rem 1rem;
  background: $white;
  p {
    font-family: $sans-serif;
    font-weight: 500;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8;
  }
}
