.carousel {
  @include mq(48em) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -1rem;
  }
  &::after {
    display: none;
    content: 'flickity';
    
    @include mq(48em) {
      content: '';
    }
  }
}
