.nav-permanent {
  @include mq(64.0625em) {
    margin-top: 3.75rem;
  }
}

.nav-container {
  display: none;

  @include mq(64.0625em) {
    display: block;
    float: right;
  }
}

.nav-placeholder {
  display: none;
  height: 2.5625rem;

  .nav-fixed & {
    display: block;
  }

  .nav-permanent & {
    display: none;
  }
}

.nav {
  .nav-fixed & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $blue;
    color: $white;
  }
}

.nav__wrap {
  .nav-fixed & {
    @include clear;
    max-width: 90rem;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0.625rem;
    padding-right: 2rem;
    padding-bottom: 0.5625rem;
    padding-left: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq(90em) {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }
}

[id] {
  @include mq(64.0625em) {
    //scroll-margin-top: 90px;

  }
}

.nav__logo {
  display: none;
  width: 9rem;
  margin: 0.6875rem auto 0.6875rem 0;
  float: left;
  color: $white;
  @include mq($xl) {
    width: 10rem;
  }
  .nav-fixed & {
    display: block;
  }
}

.nav__group {
  float: right;
  margin-top: 0.75rem;
  .nav-fixed & {
    margin-top: 0.25rem;
    @include mq(80em) {
      margin-left: auto;
    }
  }
}

.nav__search-toggle {
  display: none;
  width: 0.9375rem;
  margin-left: 1rem;
  padding: 0.8125rem 0;
  float: right;
  color: inherit;
  &:focus {
    background-color: transparent;
  }

  .nav-fixed & {
    display: block;
  }
}

.nav__search-toggle__inactive {
  display: block;

  .nav-search-active & {
    display: none;
  }
}

.nav__search-toggle__active {
  display: none;

  .nav-search-active & {
    display: block;
  }
}

.nav__list {
  @include clear;
  margin: 0;
  padding: 0;
  float: right;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
}

.nav__item {
  margin: 0 0 0 1rem;
  float: left;
  @include mq($xl) {
    margin: 0 0 0 2rem;
  }
}

.nav__item--active {
  &::before {
    height: 3rem;
    background: $transparent;
    content: '';
    @include mq(72em) {
      height: 4rem;
    }
    @include mq(76em) {
      height: 5rem;
    }

    .nav-fixed & {
      height: 0.5rem;
    }
  }
}

.nav__link {
  display: block;
  position: relative;
  padding: 0.5625rem 0;
  color: inherit;
  font-family: $sans-serif;
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 1.95;
  text-transform: uppercase;

  @include mq($lg) {
    white-space: nowrap;
  }
  @include mq(78em) {
    font-size: 0.975rem;
    line-height: 1.7;
  }


  &::before {
    display: none;
    position: absolute;
    bottom: 0.125rem;
    left: 0;
    width: 0;
    height: 0.25rem;
    background: $blue;
    content: '';
    transition: all ease 350ms;
    @include mq($lg) {
      display: block;
    }

    .nav-fixed & {
      background: $white;
    }
  }

  &:hover {
    text-decoration: none;

    &::before {
      width: 100%;
      animation: slideLeft ease 350ms;
    }
  }

  .nav-permanent & {
    text-transform: none;
    font-size: 0.8625rem;
    font-weight: 500;

  @include mq(72em) {
    font-size: 0.925rem;

  }
  @include mq(76em) {
    font-size: 0.9875rem;

  }
    @include mq(90em) {
      font-size: 1.05rem;
      line-height: 1.4375;
    }
  }

  .nav__item--current > &::before {
    display: block;
  }
}

.nav__link--arrow {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  > span {
    position: absolute;
    color: $black;
    background-color: $white;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    width: 1.5rem;
    outline: 0.125rem solid $focus;
    outline-offset: -0.125rem;
    span {
      width: 1.15rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.nav__link--arrow:focus {
  outline: 0;
  display: block;
  clip:auto;
  height:auto;
  margin:0;
  overflow:visible;
  padding:0;
  width:auto;
  height:20px;
  line-height:20px;
  margin-top:-20px;
  position: relative;
}
