.header-container {
  position: relative;
  z-index: 4;
}

.header {
  padding: 1rem 0;
  background: $white;
  box-shadow: 0 0 0.375rem $black-shadow;

  @include mq(48em) {
    padding: 1.5rem 0;
  }

  .nav-permanent & {
    @include mq(64.0625em) {
      padding: 0;
    }
  }
}

.header--nav {
  @include mq(64.0625em) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  @include mq(72em) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @include mq(76em) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    @include mq($lg) {
      // display: block;
    }
  }
}

.header__logo {
  width: 20rem;
  max-width: calc(100% - 6.5rem);

  float: left;
  color: inherit;
  overflow: hidden;
  color: $blue;

  .header--nav & {
    @include mq(64.0625em) {
      margin-left: 0;
      margin-right: auto;
      // width: 18rem;
      // position: absolute;
      // top: 50%;
      // max-width: calc(100% - 49rem);
      // width: 25rem;
      // margin-top: 0;
      // transform: translateY(-50%);
    }
  }

  .nav-permanent & {
    @include mq(64.0625em) {
      display: none;
    }
  }
  img {

  }
}

.header__toggle {
  width: 2.5rem;
  margin-left: 0.5rem;
  padding: 0.625rem;
  float: right;
  border-radius: 0.1875rem;
  background: $blue;
  color: $white;
  &:focus {
    background: $blue;
  }
  .header--nav & {
    @include mq(64.0625em) {
      display: none;
    }
  }
}

.header__toggle--inactive {
  display: block;
}

.header__toggle--active {
  display: none;
}

.header__toggle--search {
  margin-left: auto;
  .search-active & {
    .header__toggle--inactive {
      display: none;
    }

    .header__toggle--active {
      display: block;
    }
  }
}

.header__toggle--menu {
  .menu-active & {
    .header__toggle--inactive {
      display: none;
    }

    .header__toggle--active {
      display: block;
    }
  }
}
