.callout-alt {
  position: relative;
  margin: 0;
  padding-block: 6rem;
}

.callout__image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.callout__content {
  position: relative;
  @include mq($lg) {
    width: 50%;
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    @include mq($lg) {
      flex-wrap: nowrap;
    }
  }
  .button {
    width: 100%;
    gap: 1.5em;
    @include mq($lg) {
      width: auto;
    }

  }
}

.callout__title {
  color: $white;
  font-family: $serif;
}

.callout__para {
  color: $white;
  font-family: $sans-serif;
  font-size: 19.7px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
