.notification {
  background: $black-light;
  .wrap {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    background: $black-light;
    color: $white;
  }
}

.notification__container {
  position: relative;
  padding: 0 2.5rem;
}

.notification__text {
  font-family: $sans-serif;
  font-size: 1.375rem;
  font-weight: 500;
  a {
    color: $white;
    text-decoration: underline;
  }
}

.notification__label {
  font-family: $sans-serif !important;
  font-size: 2.25rem !important;
  font-weight: 500;
  line-height: 1.812;
  margin: 0;
  color: $yellow;
  text-transform: uppercase;

}

.notification__close {
  position: absolute;
  top: 0.5rem;
  right: 0;
  width: 1.25rem;
  color: $white;
}
