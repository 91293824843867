.stats {
  position: relative;
  margin: 0;
  padding-block: 6rem;
  padding-bottom: 0;
  @include mq($lg) {
    padding-block: 6rem;
  }
  &::before {
    position: absolute;
    content: '';
    inset: 0;
    background-color: rgba(0, 0, 0, .45);
    z-index: 1;
  }
  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 30%;
    width: 100%;
    background-color: $white;
    z-index: 1;
    @include mq($lg) {
      content: none;
    }
  }
}

.stats__image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stats__header {
  position: relative;
  color: $white;
  z-index: 1;
  display: grid;
  align-items: center;
  @include mq($lg) {
    grid-template-columns: repeat(2, 50%);
  }
}
.stats__title {
  color: $white;
  font-family: $serif;
  margin-top: 0;
  text-shadow: .1rem .1rem .1rem #000;
}

.stats__grid {
  position: relative;
  z-index: 2;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 100%;

  color: $white;

  @include mq($lg) {
    margin-top: 4rem;
    grid-template-columns: repeat(3, 1fr);
  }
}

.stats__item {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  color: $white;

  @include mq($lg) {
    padding: 2rem;
    &::before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;
      transition: height $transition;
      z-index: -1;
    }
  }
  &:hover {
    text-decoration: none;
    &::before {
      height: calc(var(--height, auto) - 16px);
    }
    .button {
      background-color: transparent;

      @include mq($lg) {
        border: 2px solid $white;
      }
    }
  }

  .button {
    text-decoration: underline;
    color: $white;
    border: none;
    background: none;
    margin: auto 0 0;
    padding: 0;
    text-shadow: none;

    @include mq($lg) {
      padding: 1rem 1.875rem .75rem;
      border-width: 2px;
      border-style: solid;
      text-decoration: none;
    }
    &:hover {
      margin-bottom: 0;
      background-color: transparent;
      border-color: $white;

    }
  }
}

.stats__item--purple {
  background-color: $purple;
  border: 16px solid $purple;
  @include mq($lg) {
    background-color: transparent;
    &::before {
      background-color: $purple;
    }
  }
  .button {
    background-color: $purple;
    border-color: $purple;
  }
}

.stats__item--teal {
  background-color: $teal;
  border: 16px solid $teal;
  @include mq($lg) {
    background-color: transparent;
    &::before {
      background-color: $teal;
    }
  }

  .button {
    background-color: $teal;
    border-color: $teal;
  }
}

.stats__item--blue {
  background-color: $blue;
  border: 16px solid $blue;
  @include mq($lg) {
    background-color: transparent;
    &::before{
      background-color: $blue;
    }
  }

  .button {
    background-color: $blue;
    border-color: $blue;
  }
}

.stats__value {
  display: flex;
  font-size: 3.5rem;
  @include mq($lg) {
    font-size: 4.5rem;
  }
}

.Stats__num {}

.stats__small {
  font-size: 50%;
}

.stats__name {
  display: block;
  font-size: 1.25rem;
  margin-bottom: 3rem;
  @include mq($lg) {
    font-size: 1.375rem;
  }
}
